<template>
  <div>
    <div @click="handleClose" class="add-overlay"></div>
    <div class="add-overlay-box" :class="{ 'big-form': config.bigForm }">
      <div class="modal-header" :class="{ 'big-form-header': config.bigForm }">
        <div class="modal-header-text">
          New {{ type.charAt(0).toUpperCase() + type.slice(1) }}
        </div>

        <div
          @click="handleClose"
          class="modal-header-close"
          v-html="closeIcon()"
        ></div>
      </div>

      <form @submit.prevent="handleSubmit">
        <FormFields
          :stepCount="config.formStepCount"
          :currentStep="currentStep"
          :scanner="scanner"
          form-type="new"
          @errors="errors = $event"
          v-model:input="input"
          :initInput="input"
          :postLoading="postLoading"
          :form="form"
        />

        <div class="button-wrap">
          <button
            v-if="currentStep == 1"
            :disabled="postLoading"
            @click.prevent="handleClose"
            class="cancel-button"
          >
            Cancel
          </button>

          <button
            v-if="currentStep > 1"
            :disabled="postLoading"
            @click.prevent="currentStep -= 1"
            class="cancel-button"
          >
            Back
          </button>

          <button
            :disabled="errors.length > 0"
            v-if="currentStep !== stepCount"
            @click.prevent="currentStep += 1"
            class="next-button"
          >
            Next
          </button>

          <button
            v-if="currentStep == stepCount"
            :disabled="postLoading || errors.length > 0"
            type="submit"
            class="submit-button"
          >
            Submit {{ type.charAt(0).toUpperCase() + type.slice(1) }}
          </button>
        </div>
      </form>

      <div class="scanner-info" v-if="config.scanner">
        <img class="scanner-info-icon" src="../assets/images/scanner.png" />
        <!--<div class="scanner-info-icon" v-html="scannerIcon()">
                </div>-->
        <div class="scanner-info-text">
          Scanner Auto-Detects + Auto-Populates Fields
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormFields from "@/components/FormFields"
import { closeIconDark, scannerIcon } from "@/utils/svg"
import { EventBus } from "@/event-bus"

export default {
  name: "NewForm",
  props: ["type", "model", "postLoading", "config", "scanner"],
  components: { FormFields },
  data() {
    return {
      closeIcon: closeIconDark,
      scannerIcon: scannerIcon,
      errors: [],
      input: {},
      form: {},
      loading: false,
      stepCount: this.config.formStepCount || 1,
      currentStep: 1,
    }
  },

  mounted() {
    this.initInput()
  },

  methods: {
    handleSubmit: function () {
      this.$emit("on-submit", this.model.formatPayload(this.input))

      EventBus.$emit("submit-new", this.model.formatPayload(this.input))
    },

    handleClose: function (e) {
      this.$emit("toggle-click", e)
    },

    initInput: async function () {
      this.loading = true
      this.form = await this.model.getNewForm()
      this.loading = false

      for (var key in this.form) {
        let field = key

        if (this.form[key].value) {
          this.input[field] = this.form[key].value
        } else if (field) {
          this.input[field] = ""
        }
      }

      console.log(this.input)
      console.log(this.form)
    },
  },

  watch: {},
}
</script>

<style scoped>
@media (max-width: 640px) {
  .add-overlay {
    height: 100%;
  }

  .add-overlay-box {
    height: fit-content;
  }

  .big-form {
    height: 100%;
  }

  .modal-header {
    padding: 0px 12px 0px !important;
  }

  .big-form-header {
    padding: 16px 12px 0px !important;
  }

  .scanner-info {
    padding: 0px 0px 16px !important;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.modal-header-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1f1f1f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-header-icon {
  position: static;
  width: 32px;
  height: 32px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}

option {
  height: 56px;
  background: white;
}

.form-field-wrap {
  margin: 8px 0px;
}

.form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;

  position: static;
  width: 100%;
  height: 56px;
  box-sizing: border-box;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  background: #ffffff;
  border-radius: 4px;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.submit-button {
  width: 50%;
  height: 56px;
  background: #25a3d9;
  border: 1px solid #25a3d9;
  border-radius: 4px;
  margin: 8px 0px 0px 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.scanner-info {
  /* Auto Layout */
  /*align-items: center;
    justify-content: center;
    display: flex;*/
  width: 100%;
  height: 16px;
  padding: 16px 0px;
}

.scanner-info-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  color: #8c8c8c;
  padding-left: 4px;
  display: inline;
}

.scanner-info-icon {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline;
  top: 3px;
}

button:disabled {
  background: grey;
  border: grey;
}

.button-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  position: static;
  width: 100%;
  height: 72px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}
</style>
