<template>
  <div class="spinner" :class="{ transparent: transparent }">
    <!--<img :src="loadingImg" alt="Loading..." /> -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; background: none; display: block"
      width="197px"
      height="197px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="84" cy="50" r="10" fill="#25a3d9">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.3888888888888886s"
          calcMode="spline"
          keyTimes="0;1"
          values="6;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values="#25a3d9;#f1f2f1;#8c8c8c;#54575a;#25a3d9"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#25a3d9">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;6;6;6"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="50" cy="50" r="10" fill="#54575a">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;6;6;6"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.3888888888888886s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.3888888888888886s"
        ></animate>
      </circle>
      <circle cx="84" cy="50" r="10" fill="#8c8c8c">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;6;6;6"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-2.7777777777777772s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-2.7777777777777772s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#f1f2f1">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;6;6;6"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-4.166666666666666s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="5.5555555555555545s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-4.166666666666666s"
        ></animate>
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    transparent: false,
  },

  data() {
    return {
      loadingImg:
        "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg",
    }
  },
}
</script>

<style>
.transparent {
  background: none !important;
}

.spinner img {
  width: 150px;
}
</style>
