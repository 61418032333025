<template>
  <div class="profile">
    <!--<div class="row align-items-center profile-header">
            <div class="col-md-2 mb-3">
                <img
                    :src="$auth.user.picture"
                    alt="User's profile picture"
                    class="rounded-circle img-fluid profile-picture"
                    />
            </div>
            <div class="col-md text-center text-md-left">
                <h2>{{ $auth.user.nickname }}</h2>
                <p class="lead text-muted">{{ $auth.user.email }}</p>
            </div>
        </div>

        <div class="row">
            <p>Role: {{$auth.appMetadata.role}}</p>
        </div>
        <div class="row">
            <p>Updated: {{$auth.user.updated_at}}</p>
        </div>
        <div class="row">
            <p>Email Verified: {{$auth.user.email_verified}}</p>
        </div> -->

    <form @submit.prevent="handleSubmit">
      <FormFields
        :stepCount="1"
        :currentStep="1"
        :multi="false"
        form-type="update"
        v-model="input"
        :initInput="input"
        @errors="errors = $event"
        :form="form"
        :postLoading="false"
      />

      <div v-if="errors" class="err-msg">{{ errors[0] }}</div>
      <div class="button-wrap">
        <button
          :disabled="postLoading || errors.length > 0"
          type="submit"
          class="save-button"
        >
          Save Changes
        </button>

        <button @click="logout" class="logout-button">Logout</button>
      </div>
    </form>
  </div>
</template>

<script>
import FormFields from "@/components/FormFields.vue"
import User from "@/models/users.js"

const user = new User({})

export default {
  name: "profile",
  components: { FormFields },
  data() {
    return {
      postLoading: false,
      errors: [],
      model: user,
      input: {},
      form: {},
    }
  },

  async mounted() {
    this.form = await this.model.getProfileForm()

    for (var key in this.form) {
      if (this.form[key].value) {
        this.input[key] = this.form[key].value
      }
    }

    this.input = { ...this.input, ...this.$auth.user }

    console.log(this.$auth.user)
  },

  methods: {
    handleSubmit: function (e) {
      console.log(e)
    },

    logout() {
      this.$auth.logout()
      this.$router.push({ path: "/login" })
      this.emit("onToggle")
    },
  },
}
</script>

<style>
.profile {
  margin-top: 86px;
}

.profile form {
  width: 350px;
  margin: auto;
}

.button-wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.save-button {
  height: 56px;
  width: 350px;

  text-align: center;
  background: #25d97f;
  border-radius: 4px;
  border: 1px solid #25d97f;

  color: white;
  font-family: Inter;
  font-weight: bold;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.save-button:disabled {
  border: grey;
  background: grey;
}

.logout-button {
  height: 56px;
  width: 350px;

  text-align: center;
  background: none;
  border-radius: 4px;
  border: 1px solid grey;
  margin: 8px 0px;

  color: grey;
  font-family: Inter;
  font-weight: bold;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.err-msg {
  color: red;
}
</style>
