<template>
  <div>
    <div @click="handleClose" class="add-overlay"></div>

    <div class="add-overlay-box">
      <div class="modal-header">
        <div class="modal-header-text">
          Update {{ type.charAt(0).toUpperCase() + type.slice(1)
          }}{{ selected.length > 1 ? "s" : "" }}
        </div>

        <div
          v-if="selected.length != 1"
          @click="handleClose"
          class="modal-header-close"
          v-html="closeIcon()"
        ></div>

        <div
          v-if="selected.length == 1"
          @click="handleDeleteClick"
          class="modal-header-delete"
          :class="{ 'modal-header-delete-confirm': showDeleteConfirm }"
        >
          <div class="modal-header-icon" v-html="deleteIcon()"></div>
          <div
            @click="handleConfirmClick"
            v-if="showDeleteConfirm"
            class="delete-confirm"
          >
            Confirm Delete
          </div>
        </div>
      </div>

      <div v-if="identifier && selected.length > 1" class="update-body">
        You are about to update the following {{ type }}(s):

        <ul class="list">
          <li :key="JSON.stringify(item)" class="item" v-for="item in selected">
            {{ item[identifier] }}
          </li>
        </ul>
      </div>

      <form @submit.prevent="handleSubmit">
        <FormFields
          :stepCount="config.formStepCount"
          :currentStep="currentStep"
          :multi="selected.length > 1"
          form-type="update"
          @errors="errors = $event"
          :input="input"
          :initInput="input"
          :postLoading="postLoading"
          :form="form"
        />

        <div class="button-wrap">
          <button
            v-if="currentStep == 1"
            :disabled="postLoading"
            @click.prevent="handleClose"
            class="cancel-button"
          >
            Cancel
          </button>

          <button
            v-if="currentStep > 1"
            :disabled="postLoading"
            @click.prevent="currentStep -= 1"
            class="cancel-button"
          >
            Back
          </button>

          <button
            :disabled="errors.length > 0"
            v-if="currentStep !== stepCount"
            @click.prevent="currentStep += 1"
            class="next-button"
          >
            Next
          </button>

          <button
            v-if="currentStep == stepCount"
            :disabled="postLoading || errors.length > 0"
            type="submit"
            class="submit-button"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FormFields from "@/components/FormFields"
import { deleteIcon, closeIconDark } from "@/utils/svg"
import { EventBus } from "@/event-bus"

export default {
  name: "UpdateForm",
  components: { FormFields },
  props: {
    type: String,
    config: Object,
    model: Object,
    identifier: String,
    selected: {
      type: Array,
      default: () => {
        return []
      },
    },
    postLoading: Boolean,
  },

  data() {
    return {
      closeIcon: closeIconDark,
      deleteIcon: deleteIcon,
      errors: [],
      input: {},
      form: {},
      showDeleteConfirm: false,
      currentStep: 1,
      enableScanner: this.config.formStepCount || false,
      stepCount: this.config.formStepCount || 1,
    }
  },

  async mounted() {
    await this.initInput()
    let inputWithVals = {}

    for (var key in this.input) {
      if (Object.keys(this.selected[0]).includes(key)) {
        if (this.selected[0][key]) {
          inputWithVals[key] = this.selected[0][key]
        }
      }
    }

    this.input = { ...this.input, ...inputWithVals }
  },

  methods: {
    handleSubmit: function () {
      this.$emit("on-submit", this.model.formatPayload(this.input, "update"))

      EventBus.$emit(
        "submit-update",
        this.model.formatPayload(this.input, "update")
      )
    },

    handleClose: function (e) {
      this.$emit("toggle-click", e)
    },

    handleDeleteClick: function (e) {
      this.showDeleteConfirm = !this.showDeleteConfirm
    },

    handleConfirmClick: function () {
      this.$emit("on-delete", this.selected)
      EventBus.$emit("submit-delete", this.selected)
    },

    initInput: async function () {
      this.form = await this.model.getUpdateForm()

      for (var key in this.form) {
        let field = key

        if (this.form[key].value) {
          this.input[field] = this.form[key].value
        } else if (field) {
          this.input[field] = ""
        }
      }
    },
  },
}
</script>

<style scoped>
@media (max-width: 640px) {
  .update-body {
    margin-left: 16px;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.modal-header-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1f1f1f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-header-icon {
  height: 20px;
  width: 16px;
}

.modal-header-delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: static;
  width: 32px;
  height: 32px;

  background: #d55252;
  border-radius: 4px;
  cursor: pointer;
}

.modal-header-delete-confirm {
  display: flex;
  align-items: center;

  width: 155px;
  height: 32px;

  background: #d55252;
  border-radius: 4px;
  cursor: pointer;
}

.delete-confirm {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  padding-left: 8px;
  color: #ffffff;
}

.button-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  position: static;
  width: 100%;
  height: 72px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

button:disabled {
  border: grey;
  background: grey;
}

.update-body {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.item {
  font-weight: bold;
  text-align: left;
}

.list {
  list-style-position: inside;
  padding-top: 16px;
}
</style>
