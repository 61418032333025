<template>
  <div class="appointments">
    <Loading :transparent="true" v-if="loading" />
    <div class="input-container" v-if="providers.data">
      <label>
        View:
        <select
          class="input select"
          :value="input.provider"
          @input="handleProviderChange"
        >
          <option value="">-- All Providers --</option>

          <option
            :key="p.externalProviderId"
            :value="`practitioner/${p.externalProviderId}`"
            v-for="p in providers.data"
          >
            {{ p.name }}
          </option>
        </select>
      </label>

      <label>
        Visit Reason:
        <select
          class="input select"
          :value="input.reason"
          @input="handleReasonChange"
        >
          <option value="">-- All Reasons --</option>

          <option
            :key="p.name"
            :value="`appointment-type/${p.appointmentType.typeId}`"
            v-for="p in providers.visitReasonsData"
          >
            {{ p.name }}
          </option>
        </select>
      </label>

      <label>
        Date:
        <input
          class="input date"
          type="date"
          @input="handleDateChange"
          :value="formatDate(input.date)"
        />
      </label>

      <label>
        Slot Length:
        <input
          class="input number"
          type="number"
          @blur="handleChangeSlot"
          :value="input.slotLength"
        />
      </label>
    </div>

    <AppointmentTable
      v-if="!loading && slots"
      :model="slots"
      :loading="loading"
    />
  </div>
</template>

<script>
import AppointmentTable from "@/components/AppointmentTable"
import Loading from "@/components/Loading"
import Provider from "@/models/providers.js"
import Slot from "@/models/slots.js"

var startDate = new Date()
var slot = new Slot({})
var provider = new Provider({
  remotePath: "/api/practice-settings/providers",
  practiceRemotePath: "/api/practice-settings/practice",
})

export default {
  name: "schedule",
  components: { Loading, AppointmentTable },
  data() {
    return {
      msg: "",
      err: false,
      selected: [],
      loading: true,
      input: {
        provider: null,
        reason: null,
        date: startDate,
        slotLength: 5,
      },
      providers: provider,
      slots: slot,
    }
  },

  async mounted() {
    this.setSlotsQuery()
    await this.getProviders()
    this.slots.providers = this.providers.data
    await this.getSlots()

    this.loading = false
  },

  methods: {
    formatDate(d) {
      return d.toISOString().substr(0, 10)
    },

    getProviders: async function () {
      const accessToken = await this.$auth.getTokenSilently()
      let auth = {
        accessToken: accessToken,
        user: this.$auth.user,
        role: this.$auth.appMetadata.role,
      }
      this.providers.setAuth(auth)

      await this.providers.fetchData()
    },

    getSlots: async function () {
      const accessToken = await this.$auth.getTokenSilently()
      let auth = {
        accessToken: accessToken,
        user: this.$auth.user,
        role: this.$auth.appMetadata.role,
      }
      this.slots.setAuth(auth)

      await this.slots.fetchData()
    },

    handleProviderChange: async function (e) {
      this.input.provider = e.target.value
      this.loading = true

      this.setSlotsQuery()
      await this.getSlots()
      this.loading = false
    },

    handleReasonChange: async function (e) {
      this.input.reason = e.target.value
      this.loading = true

      this.setSlotsQuery()
      await this.getSlots()
      this.loading = false
    },

    handleDateChange: async function (e) {
      this.input.date = e.target.valueAsDate
      this.loading = true

      this.setSlotsQuery()
      await this.getSlots()
      this.loading = false
    },

    handleChangeSlot: async function (e) {
      this.input.slotLength = e.target.value
      this.loading = true

      this.setSlotsQuery()
      await this.getSlots()
      this.loading = false
    },

    setSlotsQuery() {
      let start = this.input.date
      let end = new Date(start)

      end.setDate(end.getDate() + 14)

      this.slots.query =
        `?start=ge${this.formatDate(start)}` +
        `&start=lt${this.formatDate(end)}` +
        `&schedule.actor=location/1`

      this.slots.query += `&slot-length=${this.input.slotLength}`

      if (this.input.reason) {
        this.slots.query += `&appointment-type=${this.input.reason}`
      }

      if (this.input.provider) {
        this.slots.query += `&schedule.actor=${this.input.provider}`
      }

      this.slots.query += `&_count=200`
    },
  },

  watch: {
    "providers.query": async function () {
      this.loading = true
      await this.getProviders()
      this.loading = false
    },

    $route() {
      this.getProviders()
    },
  },
}
</script>

<style scoped>
label {
  margin-right: 8px;
}

.data {
  margin: auto;
  width: 600px;
}

.data code {
  display: table-cell;
  width: 100%;
}

.row {
  background: white;
}

.input-container {
  display: inline-block;
  margin: 16px auto auto auto;
  width: 100%;
}

.input {
  width: 160px;
  height: 30px;
  padding-left: 8px;
  border-radius: 0px;
  border: 1px solid #8c8c8c;
  display: inline-block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.date-picker {
  display: inline-block;
}

input[type="date"] {
  height: 30px;
  background-image: url("~@/assets/images/cal.png");
  background-position: left 94% bottom 50%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-color: white;

  padding-left: 8px;
  color: #8c8c8c;
  border-radius: 0px;
  border: 1px solid #8c8c8c;
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

select.form-field {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.form-field {
  background-image: url("~@/assets/images/chevron.png");
  background-position: left 94% bottom 50%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-color: white;
}

.add-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

.add-overlay-box {
  position: fixed;
  overflow-y: hidden;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  background: #f1f2f1;
  border-radius: 4px;

  width: 380px;

  z-index: 5500;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;
}

form {
  width: 100%;
}

@media (max-width: 640px) {
  .add-overlay {
    width: 100vw;
    height: 100%;
    overflow-y: auto;
  }

  .add-overlay-box {
    overflow-y: scroll;
    top: 50%;
    left: 50%;

    position: fixed;

    width: 100%;
    height: 100%;
  }

  .modal-header {
    padding: 12px 12px 0px !important;
  }

  form {
    width: 93% !important;
    padding: 12px !important;
    margin: 0 auto;
  }
}

.cancel-button,
.next-button,
.submit-button {
  width: 50%;
  height: 56px;
  border-radius: 4px;
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.cancel-button,
.cancel-button:focus,
.cancel-button:active {
  outline: none;
  background: none;
  margin: 8px 8px 0px 0px;
  color: #8c8c8c;
  outline: unset;
  border: 1px solid #8c8c8c;
}

.submit-button {
  background: #25d97f;
  margin: 8px 0px 8px 0px;
  border: 1px solid #25d97f;
  color: #ffffff;
}

.next-button,
.next-button:focus,
.next-button:active {
  outline: none;
  background: #25a3d9;
  margin: 8px 0px 8px 0px;
  border: 1px solid #25a3d9;
  color: #ffffff;
}

.pagin {
  color: #8c8c8c;
  height: 32px;
  width: 32px;
  border-radius: 0px;
  border: 1px solid #8c8c8c;
  background: white;
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  padding: 4px;
}

.pagin:disabled {
  background: white;
  border: 1px solid #8c8c8c;
}

.pagins {
  display: inline-block;
  margin-top: 16px;
}

.datebox {
  color: black;
  display: inline-block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  margin: 0 6px 0 6px;
}
</style>
