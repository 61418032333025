<template>
  <article class="payment-form">
    <section>
      <span>Due Today</span>
      <p class="price">{{ this.formatCurrency(this.cost) }}</p>
      <h1>Fees are fully-refundable.</h1>
      <p>All deposits go toward your procedure.</p>
    </section>
    <section>
      <form @submit.prevent="submitPayment">
        <label for="account">
          Credit Card Number
          <div ref="cardNumber" class="input"></div>
          <!-- <input
            v-focus
            v-if="active"
            type="text"
            maxlength="19"
            placeholder="1234 5678 9012 3456"
            class="account"
            id="account"
            required
            v-model="account"
            @keyup="formatCC"
          /> -->
        </label>
        <div class="expire-cvv">
          <label for="expiry">
            Expiration
            <div ref="cardExpiry" class="input"></div>
            <!-- <input
              class="expiry"
              id="expiry"
              type="text"
              maxlength="7"
              placeholder="MM/YYYY"
              required
              v-model="expiry"
              @keyup="formatExpiry"
            /> -->
          </label>
          <label for="cvv">
            Security
            <div ref="cardCvc" class="input expire-cvv"></div>
            <!-- <input
              class="cvv"
              id="cvv"
              type="text"
              placeholder="123"
              maxlength="3"
              required
              v-model="cvv"
            />-->
          </label>
        </div>
        <p class="error">{{ message }}</p>
        <button
          type="submit"
          :class="[
            account && expiry && cvv ? 'footer-btn active' : 'footer-btn',
          ]"
        >
          Pay Now
        </button>
      </form>
    </section>
  </article>
</template>

<script>
import axios from "axios"
import { EventBus } from "@/event-bus"
let stripe = Stripe(`pk_test_51KdH0XHhUgiOW2pHyGj6kXFEA8cyupcitn9eI7z8sccctdcwrSXbJyXJnuBJLg3YU5NOqAbXIpBo9jVMkyMH3SY300idsAvzBZ`)
const elements = stripe.elements()

const elementStyles = {
  base: {
    border: 'none',
    borderRadius: '8px',
    boxSizing: 'border-box',
    fontSize: '18px',
    height: '26px',
    lineHeight: '26px',
    margin: '4px 0px',
    padding: '16px',
    color: 'rgb(117,117,117)',
    fontWeight: 600,
    fontSmoothing: 'antialiased',
    width: '100%',

    ':focus': {
      color: 'rgb(117,117,117)',
    },

    '::placeholder': {
      color: '#8C8C8C',
    },

    ':focus::placeholder': {
      color: '#8C8C8C',
    },
  },
  invalid: {
    color: '#fff',
    ':focus': {
      color: '#FA755A',
    },
    '::placeholder': {
      color: '#FFCCA5',
    },
  },
};

export default {
  name: "PaymentForm",
  props: ["data", "active"],
  data() {
    return {
      account: "",
      expiry: "",
      cvv: "",
      token: "",
      cost: "1.0",
      expDate: "",
      message: "",
      cardNumber: "",
      cardExpiry: "",
      cardCvc: "",
    }
  },
  mounted() {
    EventBus.$on("show-error", error => {
      this.message = error
    })

    this.cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      placeholder: '1234 5678 9012 3456'
    });
    this.cardNumber.mount(this.$refs.cardNumber);

    this.cardExpiry = elements.create('cardExpiry', {
      style: elementStyles
    });
    this.cardExpiry.mount(this.$refs.cardExpiry);

    this.cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      placeholder: '123'
    });
    this.cardCvc.mount(this.$refs.cardCvc);
  },

  directives: {
    focus: {
      inserted: function(el) {
        console.log("focus")
        el.focus()
      },
    },
  },

  methods: {
    purchase() {
      stripe.createToken(card).then((result) => {
        // Access the token with result.token
      });
    },
    formatCurrency(p) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      })

      return formatter.format(p)
    },
    submitPayment() {
      let expiryYear = this.expiry.split("/")
      expiryYear[1] = expiryYear[1].replace("20", "")
      this.expDate = expiryYear[0] + expiryYear[1]
      this.tokenizeAccount()
    },
    formatCC() {
      if (this.account.length < 19) {
        this.account = this.account
          .replace(/\W/gi, "")
          .replace(/(.{4})/g, "$1 ")
        return true
      } else {
        return false
      }
    },
    formatExpiry() {
      this.expiry = this.expiry
        .replace(
          /^([1-9]\/|[2-9])$/g,
          "0$1/" // 3 > 03/
        )
        .replace(
          /^(0[1-9]|1[0-2])$/g,
          "$1/" // 11 > 11/
        )
        .replace(
          /^([0-1])([3-9])$/g,
          "0$1/$2" // 13 > 01/3
        )
        .replace(
          /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
          "$1/$2" // 141 > 01/41
        )
        .replace(
          /^([0]+)\/|[0]+$/g,
          "0" // 0/ > 0 and 00 > 0
        )
        .replace(
          /[^\d\/]|^[\/]*$/g,
          "" // To allow only digits and `/`
        )
        .replace(
          /\/\//g,
          "/" // Prevent entering more than 1 `/`
        )
    },
    tokenizeAccount() {
      axios
        .post(
          "https://fts-uat.cardconnect.com/cardsecure/api/v1/ccn/tokenize",
          {
            account: this.account,
            expiry: this.expDate,
            cvv: this.cvv,
          }
        )
        .then(resp => {
          this.token = resp.data.token
          this.createPayment()
        })
        .catch(error => {
          console.log(error)
        })
    },
    createPayment() {
      EventBus.$emit("createPayment", {
        expiry: this.expiry,
        token: this.token,
        cost: `${this.cost}`,
        firstName: this.data.firstName,
        lastName: this.data.lastName,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-form {
  section {
    span {
      background: #fff;
      border-radius: 8px;
      color: #4d4d4d;
      font-size: 9px;
      letter-spacing: 0.08em;
      line-height: 13px;
      padding: 2px 6px;
    }
    h1 {
      color: #1a1a1a;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.02em;
      margin: 4px 0px;
    }

    p {
      color: #4d4d4d;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.04em;
      margin: 4px 0px;
    }

    p.price {
      color: #25a3d9;
      font-size: 32px;
      line-height: 140%;
      letter-spacing: 0.02em;
      margin: 4px 0px;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 24px auto 0;
      max-width: 342px;
    }

    label {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      line-height: 14px;
      width: 100%;
      margin: 0 auto;
      max-width: 342px;
      align-items: flex-start;
      text-transform: uppercase;
      text-indent: 10px;
    }

    .input {
      background: #fff;
      border: none;
      border-radius: 8px;
      box-sizing: border-box;
      font-family: 'brandon-grotesque';
      font-size: 18px;
      line-height: 26px;
      margin: 4px 0px;
      padding: 16px;
      width: 100%;
    }

    .error {
      color: red;
    }

    .expire-cvv {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      margin: 4px 0;
    }

    .footer-btn {
      display: flex;
      box-sizing: border-box;
      font-style: normal;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      border: 1px solid #8c8c8c;
      padding: 16px 32px;
      border-radius: 32px;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      background: #8c8c8c;
      color: #fff;
      // pointer-events: none;
      margin-top: 24px;
      width: 50%;
      display: flex;
      align-self: center;
      justify-content: center;

      &.active {
        border: 1px solid #25a3d9;
        background: #25a3d9;
        // pointer-events: all;
      }
    }
  }
}
</style>
