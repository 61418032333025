<template>
  <div>
    <div @click="handleClose" class="add-overlay"></div>

    <div class="add-overlay-box">
      <div class="modal-header">
        <div class="modal-header-text">Process</div>
        <div>
          <div class="status" :class="{ 'on-status': step <= currentStep }"
            v-for="step in Array.from({ length: stepCount }, (x, i) => i + 1)" :key="step"></div>
        </div>
        <div @click="handleClose" class="modal-header-close" v-html="closeIcon()"></div>
      </div>

      <form @submit.prevent="handleSubmit">
        <FormFields v-if="currentStep == Object.keys(form).indexOf(k) + 1" v-for="(v, k) in form" :key="k"
          :form-label="k" :stepCount="1" :currentStep="1" form-type="process" @errors="errors = $event"
          v-model:input="input[k]" :initInput="input[k]" :postLoading="postLoading" :form="form[k]"
          @input.native="handleKeyup" @patient-clicked="handlePatientClicked($event)" />

        <div class="button-wrap">
          <button v-if="currentStep == 1" :disabled="postLoading" @click.prevent="handleClose" class="cancel-button">
            Cancel
          </button>

          <button v-if="currentStep > 1" :disabled="postLoading" @click.prevent="currentStep -= 1"
            class="cancel-button">
            Back
          </button>

          <button :disabled="errors.length > 0" v-if="currentStep !== stepCount" @click.prevent="currentStep += 1"
            class="next-button">
            Next
          </button>

          <button v-if="currentStep == stepCount" :disabled="postLoading || errors.length > 0" type="submit"
            class="submit-button">
            Process
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import FormFields from "@/components/FormFields"
import TypeAhead from "@/components/TypeAhead"
import { EventBus } from "@/event-bus"
import { deleteIcon, closeIconDark } from "@/utils/svg"
import loader from "@/assets/images/loader.gif"

export default {
  name: "ProcessForm",
  components: { FormFields, TypeAhead },
  //props: ['type','model','identifier','selected','postLoading','enableScanner'],
  props: {
    type: String,
    model: Object,
    identifier: String,
    selected: Array,
    postLoading: Boolean,
    enableScanner: Boolean,
    stepCount: {
      type: Number,
      default: function () {
        return 1
      },
    },
  },

  data() {
    return {
      closeIcon: closeIconDark,
      deleteIcon: deleteIcon,
      errors: [],
      input: this.model.getProcessForm(),
      form: {},
      loading: false,
      apiLoading: false,
      showDeleteConfirm: false,
      currentStep: 1,
    }
  },

  async mounted() {
    await this.initInput()
    EventBus.$on("patient-clicked", this.handlePatientClicked)
  },

  methods: {
    handleSubmit: function () {
      delete this.input.patient.name
      this.$emit("on-submit", this.model.formatPayload(this.input, "update"))
    },

    handleClose: function (e) {
      this.$emit("toggle-click", e)
    },

    handleDeleteClick: function (e) {
      this.showDeleteConfirm = !this.showDeleteConfirm
    },

    handleConfirmClick: function () {
      this.$emit("on-delete", this.selected)
    },

    handleKeyup: async function (e) {
      let query
      let target = e.target
      let name = target.value.split(" ")

      if (target.name === "name") {
        target.style = `background: #fff url(${loader}) 95% center no-repeat`
        const containers = document.querySelectorAll(".results-container")
        containers.forEach((c) => c.remove())

        if (target.value.length > 0) {
          const parentElement = e.target.parentElement
          const resultsContainer = document.createElement("div")
          resultsContainer.classList.add("results-container")
          parentElement.appendChild(resultsContainer)

          if (name.length === 1) query = `?name=${name[0]}`
          if (name.length === 2 && name[1] === "") query = `?given=${name[0]}`
          if (name.length === 2 && name[1] !== "")
            query = `?family=${name[1]}&given=${name[0]}`

          const data = await this.model
            .fetchData(query)
            .then((res) => res)
            .catch((e) => console.log(e))
            .finally(() => (target.style = ``))

          if (data) {
            let TypeAheadClass = Vue.extend(TypeAhead)
            let typeAhead = new TypeAheadClass({
              propsData: { results: data },
            })
            typeAhead.$mount() // pass nothing
            resultsContainer.appendChild(typeAhead.$el)
          } else {
            this.input.patient.firstName = name[0]
            this.input.patient.lastName = name[1]
            target.style = ``
          }
        } else {
          target.style = ``
        }
      }
    },

    handlePatientClicked: function (patient) {
      this.input.patient = patient
      this.errors = []
    },

    initInput: function () {
      this.loading = true
      this.form = this.model.getProcessForm(this.selected)
      this.loading = false

      for (var key in this.form) {
        let field = key

        for (var k in this.form[field]) {
          if (this.form[field][k].value) {
            this.input[field][k] = this.form[field][k].value
          } else {
            this.input[field][k] = null
          }
        }
      }
    },
  },
}
</script>

<style scoped>
.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.modal-header-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1f1f1f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-header-icon {
  height: 20px;
  width: 16px;
}

.modal-header-delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: static;
  width: 32px;
  height: 32px;

  background: #d55252;
  border-radius: 4px;
  cursor: pointer;
}

.modal-header-delete-confirm {
  display: flex;
  align-items: center;

  width: 155px;
  height: 32px;

  background: #d55252;
  border-radius: 4px;
  cursor: pointer;
}

.delete-confirm {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  padding-left: 8px;
  color: #ffffff;
}

.button-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  position: static;
  width: 100%;
  height: 72px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

button:disabled {
  border: grey;
  background: grey;
}

.update-body {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
}

.item {
  font-weight: bold;
  text-align: left;
}

.list {
  list-style-position: inside;
  padding-left: 8px;
}

.status {
  display: inline-block;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  background: #cbcccb;
  margin: 0px 10px 0 0;
}

.on-status {
  display: inline-block;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  background: #25a3d9;
  margin: 0px 10px 0 0;
}
</style>
