<template>
  <div>
    <HomeContent />
  </div>
</template>

<script>
import HomeContent from "@/components/HomeContent"

export default {
  name: "home",
  components: {
    HomeContent,
  },
}
</script>
