<template>
  <div class="float">
    <div class="count">
      {{ selected.length }}
    </div>
    <div class="actions">
      <div v-for="(v, k) in actions">
        <div
          @click="$emit('on-action', k)"
          v-html="v.icon"
          v-if="v.klass == 'icon'"
          :class="v.klass"
        ></div>

        <button
          :disabled="selected.length < 1 || selected.length > 2"
          @click="$emit('on-action', k)"
          v-if="v.klass == 'btn' && process"
          :class="v.klass"
        >
          {{ v.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatTool",
  props: ["selected", "actions", "process"],

  mounted() {},
}
</script>

<style scoped>
.float {
  width: 600px;
  position: fixed;
  background: #1f1f1f;
  border: 1px solid white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
  color: white;
  height: 47px;
  line-height: 47px;
  border-radius: 4px;

  margin: 0 auto;
  bottom: 16px;
  left: 0;
  right: 0;

  padding: 8px 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 20px;
  height: 20px;

  background: #ffffff;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 24px 16px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #1f1f1f;
}

.actions {
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  text-align: left;
  height: 39px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}

@media (max-width: 640px) {
  .float {
    width: 100%;
    bottom: 0;
    border-radius: 0;
    padding: 16px 0px 16px 0px;
  }

  .actions {
    padding: 0px 12px;
  }
}

.icon {
  width: 16px;
  height: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;

  margin-right: 16px;
}

.btn {
  /* Process Button */

  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: static;
  width: 78px;
  height: 39px;
  left: 64px;
  top: 0px;

  /* Primary/Blue */

  background: #25a3d9;
  border: #25a3d9;
  color: white;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  cursor: pointer;

  font-family: Inter;
  font-style: bold;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.btn:disabled {
  background: #8c8c8c;
}
</style>
