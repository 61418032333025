<template>
  <div class="info">
    <div class="info-header">
      <transition name="fade">
        <img
          class="loading-img"
          v-if="input.state == 'fetching'"
          :src="require('../assets/images/small-blue-loading.svg')"
        />

        <img
          v-if="input.state == 'success'"
          :src="require('../assets/images/small-green-checkmark.svg')"
        />

        <img
          v-if="input.state == 'failed'"
          :src="require('../assets/images/small-red-alert.svg')"
        />
      </transition>

      <transition name="fade">
        <div v-if="input.state" class="text" :class="input.state">
          {{ input.msg }}
        </div>
      </transition>
    </div>

    <transition name="fade">
      <div v-if="input.state" class="text sub" v-html="input.subMsg"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "VerifyLoading",
  props: ["input"],
}
</script>

<style>
.loading-img {
  height: 18px;
  width: 18px;
}

.text.fetching {
  color: #25a3d9;
}

.text.success {
  color: #3ec065;
}

.text.failed {
  color: #d94325;
}

.info {
  background: none;
  margin-top: 16px;
}

.info-header {
  display: inline-flex;
}

.text {
  margin: auto;
  margin-left: 8px;
  display: inline-block;
  line-height: 18px;
  font-weight: bold;

  font-family: "brandon-grotesque";
  font-style: bold;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.08em;
}

.text.sub {
  font-style: normal;
  font-weight: normal;
}

.text.sub a {
  color: black;
}
</style>
