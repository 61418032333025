<template>
  <div>
    <div class="form-field-label" v-if="formLabel">{{ formLabel }} Details</div>
    <div v-if="input && form">
      <div
        @change="checkForm"
        :style="v.formStyle ? v.formStyle() : ''"
        v-for="(v, k) in formSteps"
        class="form-field-wrap"
        :key="k"
      >
        <div
          class="form-step"
          :class="{
            'form-field-hidden': step !== currentStep,
            'step-select': v.type == 'select' || v.type == 'date',
          }"
          v-for="step in Array.from({ length: stepCount }, (x, i) => i + 1)"
          :key="step"
        >
          <div
            :class="{ 'form-field-hidden': step !== currentStep }"
            class="form-field-label"
            v-if="v.step == step && v.label"
          >
            {{ v.label }}
          </div>

          <input
            :class="{
              'form-field-hidden': step !== currentStep,
              'invalid': invalids[k],
            }"
            :disabled="
              postLoading || v.disabled || (scanner && scanner.scanning)
            "
            :name="k"
            :ref="k"
            v-if="
              v.step == step &&
              ['array', 'text', 'number', 'email', 'password'].includes(v.type)
            "
            @input="(value) => handleChange(value, k)"
            @blur="handleBlur(k)"
            :value="input[k]"
            class="form-field"
            :required="v.required"
            :type="v.type"
            :placeholder="v.placeholder"
          />

          <span
            v-html="v.type == 'text' ? pwShown() : pwHidden()"
            @click="v.type = v.type == 'password' ? 'text' : 'password'"
            class="pw-icon"
            :class="{ 'form-field-hidden': step !== currentStep }"
            v-if="v.step == step && k.toLowerCase().match('password')"
          >
          </span>

          <input
            :class="{
              'form-field-hidden': step !== currentStep,
              'invalid': invalids[k],
            }"
            :disabled="postLoading"
            :name="k"
            :ref="k"
            v-if="v.step == step && ['date'].includes(v.type)"
            @input="(value) => handleChange(value, k)"
            @blur="handleBlur(k)"
            :value="input[k]"
            class="form-field"
            :required="v.required"
            :type="v.type"
            :placeholder="v.placeholder"
          />

          <div v-if="v.type == 'file'">
            <div v-if="input[k].src" class="image-preview">
              <img class="file-preview" :src="input[k].src" />
            </div>
            <div v-else-if="input[k]" class="image-preview">
              <img class="file-preview" :src="input[k]" />
            </div>
            <input
              :class="{
                'form-field-hidden': step !== currentStep,
                'invalid': invalids[k],
              }"
              :disabled="postLoading"
              :name="k"
              :ref="k"
              :accept="acceptFileList()"
              v-if="v.step == step && ['file'].includes(v.type)"
              @input="(value) => handleChange(value, k)"
              class="form-field"
              :required="v.required"
              :type="v.type"
              :placeholder="v.placeholder"
            />
          </div>

          <textarea
            :class="{
              'form-field-hidden': step !== currentStep,
              'invalid': invalids[k],
            }"
            :disabled="postLoading"
            :name="k"
            :ref="k"
            v-if="v.step == step && v.type == 'textarea'"
            @input="(value) => handleChange(value, k)"
            :value="input[k]"
            class="form-field"
            :required="v.required"
            type="text"
            :placeholder="v.placeholder"
          >
          </textarea>

          <select
            :class="{
              'form-field-hidden': step !== currentStep,
              'invalid': invalids[k],
            }"
            :disabled="postLoading"
            :name="k"
            :ref="k"
            v-if="v.step == step && v.type == 'select'"
            @input="(value) => handleChange(value, k)"
            @blur="handleBlur(k)"
            :value="input[k]"
            class="form-field"
            :required="v.required"
          >
            <option v-if="v.placeholder" value="" disabled>
              {{ v.placeholder }}
            </option>

            <option :key="o" :value="o" v-for="(o, p) in v.options">
              {{
                p
                  .split(" ")
                  .map((x) => (x[0] ? x[0].toUpperCase() + x.substr(1) : x))
                  .join(" ")
              }}
            </option>
          </select>

          <div
            class="multi-check"
            v-if="v.step == step && v.type == 'multi-checkbox'"
          >
            <div
              class="form-option-box"
              :class="{ 'form-option-selected': input[k].includes(option) }"
              :key="JSON.stringify(option)"
              v-for="(option, name) in v.options"
            >
              <label>
                <input v-model="input[k]" :value="option" type="checkbox" />
                <div class="form-option-text">
                  {{ name }}
                </div>

                <input
                  class="form-option-nested"
                  v-if="v.nested && input[v.nested]"
                  :placeholder="input[v.nested].placeholder"
                  step="5"
                  v-model="input[v.nested][option]"
                  type="number"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { passwordToggleHidden, passwordToggleShown } from "@/utils/svg"

export default {
  name: "FormFields",
  props: [
    "scanner",
    "form",
    "formType",
    "formLabel",
    "multi",
    "postLoading",
    "initInput",
    "stepCount",
    "currentStep",
    "results",
  ],
  data() {
    return {
      pwHidden: passwordToggleHidden,
      pwShown: passwordToggleShown,
      input: this.initInput,
      file: {},
      invalids: {},
    }
  },

  mounted() {
    this.checkForm()
  },

  methods: {
    previewFile(e) {
      let file = e.target.files[0]

      let fileObject = {
        fileObject: e.target.files[0],
        name: file.name,
        type: file.type,
        size: file.size,
        uploadProgress: 0,
        src: this.getImgURL(file),
      }

      return fileObject
    },

    getImgURL(file) {
      return window.URL.createObjectURL(file)
    },

    acceptFileList() {
      let ua = navigator.userAgent || navigator.vendor
      //let ua = navigator.userAgent || navigator.vendor || window.opera
      let isFacebookApp =
        ua.indexOf("FBAN") > -1 ||
        ua.indexOf("FBAV") > -1 ||
        ua.indexOf("Instagram") > -1

      if (isFacebookApp) {
        return ""
      } else {
        return ".png,.jpg,.jpeg,.tiff,.gif,.bmp"
      }
    },

    handleChange(e, key) {
      if (this.formSteps[key].onChange) {
        this.input[key] = this.formSteps[key].onChange(e.target.value, key)
      } else if (this.formSteps[key].toggleAlt) {
        let alt = this.formSteps[key].toggleAlt()

        let values = Object.values(this.formSteps[key].options)

        values.splice(values.indexOf(e.target.value), 1)

        this.input[alt] = values[0]
        this.input[key] = e.target.value
      } else if (this.formSteps[key].type == "array") {
        this.input[key] = e.target.value.replace(/\s+/g, "").split(",")
      } else if (this.formSteps[key].type == "file") {
        Vue.set(this.input, key, this.previewFile(e))
      } else {
        this.input[key] = e.target.value
      }
    },

    handleBlur(key) {
      if (this.formSteps[key].pattern && this.formSteps[key].required) {
        this.$set(
          this.invalids,
          key,
          !this.formSteps[key].pattern.test(this.input[key])
        )
      } else if (this.formSteps[key].pattern && this.input[key] != "") {
        this.$set(
          this.invalids,
          key,
          !this.formSteps[key].pattern.test(this.input[key])
        )
      } else if (
        this.formSteps[key].required &&
        (this.input[key] == null || this.input[key] == "")
      ) {
        this.$set(this.invalids, key, true)
      } else {
        this.$set(this.invalids, key, false)
      }
    },

    checkForm() {
      var errors = []

      for (var f in this.formSteps) {
        if (
          this.input[f] &&
          this.formSteps[f].pattern &&
          !this.formSteps[f].pattern.test(this.input[f])
        ) {
          errors.push(f + ": invalid")
        } else if (
          this.formSteps[f].required &&
          (this.input[f] === null || this.input[f] === "")
        ) {
          errors.push(f + ": required")
        }
      }

      this.$emit("errors", errors)
    },
  },

  computed: {
    formSteps: function () {
      let form = this.form

      for (var i in form) {
        form[i].step = form[i].step ? form[i].step : 1

        if (this.formType == "update") {
          if (!form[i].updateForm) {
            delete form[i]
          }
        } else if (this.formType == "new") {
          if (!form[i].newForm) {
            delete form[i]
          }
        } else if (this.formType == "process") {
          if (!form[i].processForm) {
            delete form[i]
          }
        }

        if (this.multi && !form[i].multiUpdate) {
          delete form[i]
        }
      }

      return form
    },
  },

  watch: {
    initInput: function () {
      this.input = this.initInput
    },

    form: function () {
      this.checkForm()
    },

    input: {
      handler() {
        this.checkForm()

        this.$emit("input", this.input)
      },
      immediate: true,
      deep: true,
    },

    "scanner.buffer": function () {
      if (this.scanner.buffer.match(/^.*\]$/)) {
        this.scanner.parseData()
        for (var key in this.formSteps) {
          if (this.scanner.type === key) {
            this.input[key] = this.scanner.buffer

            this.$refs[key][0].focus()
          }
        }
      }
    },
  },
}
</script>

<style>
option {
  height: 56px;
  background: white;
}

.form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0px;
  padding: 0px 16px;

  position: static;
  width: 100%;
  height: 56px;
  box-sizing: border-box;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  background: #ffffff;
  border-radius: 4px;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.form-field-label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #1f1f1f;
  opacity: 0.5;
  margin: 8px 0px;
  text-align: left;
}

.form-step {
  position: relative;
}

.form-field-hidden {
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 0;
  margin: 0px;
  padding: 0px;
}

.pw-icon {
  position: absolute;
  top: 42px;
  right: 16px;
  cursor: pointer;
}

textarea.form-field {
  padding: 16px 16px 0px !important;
  height: 116px !important;
}

.invalid {
  border: 2px solid #d55252;
  background: rgba(213, 82, 82, 0.24);
}

.invalid::placeholder {
  color: #1f1f1f;
}

.invalid-label {
  color: #d55252;
  font-weight: bold;
}

.step-select {
  width: 100%;
}

input[type="date"].form-field {
  font-size: 14px !important;
}

.file-preview {
  display: inline;
  height: 50px;
  max-width: 50px;
  border-radius: 5px;
}

.image-preview {
  text-align: left;
}

.form-option-box {
  align-items: center;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  margin: 6px;
}

.form-option-selected {
  border: 1px dotted #25a3d9;
}

.form-option-selected .form-option-text {
  color: #25a3d9;
}

.form-option-text {
  text-align: left;
  width: 100%;
  font-family: "brandon-grotesque";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

.form-option-nested {
  width: 36px;
  font-family: "brandon-grotesque";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

input[type="checkbox"] {
  border: none;
  outline: none;
  cursor: pointer;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
}

input[type="checkbox"]:checked {
  border: none;
  outline: none;
  -webkit-appearance: none;
}

input[type="checkbox"]:after {
  content: "";
  border: none;
  outline: none;
  display: block;
  height: 24px;
  width: 24px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("~@/assets/images/plus.svg");
}

input[type="checkbox"]:checked:after {
  border: none;
  outline: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 24px;
  width: 24px;
  background-image: url("~@/assets/images/blue-check.svg");
}

.form-option-box label {
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.multi-check {
  border-radius: 6px;
  background: #fff;
  padding: 2px;
  max-height: 250px;
  overflow-y: scroll;
  margin-bottom: 16px;
}
</style>
