<template>
  <div class="login">
    <div class="login-container">
      <div class="logo">
        <img src="../assets/images/amelia-logo.png" />
      </div>
    </div>
    <Loading v-if="$auth.isLoading" :transparent="true" />
  </div>
</template>

<script>
import Loading from "@/components/Loading"

export default {
  name: "login",
  components: {
    Loading,
  },
  mounted() {
    if (!this.$auth.isAuthenticated) {
      this.$auth.loginWithRedirect()
    }
  },
  methods: {},
}
</script>

<style>
.btn-text {
  display: block;
  float: left;
  font-weight: normal;
}

.btn-arrow {
  display: block;
  float: right;
}

.login-btn {
  cursor: pointer;
  background: #25a3d9;
  border: none;
  padding: 0 16px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 300px;
  height: 56px;
  color: #ffffff;
  position: relative;
}

.logo {
  margin-bottom: 42px;
}

.login {
  height: 100vh;
  width: 100vw;
  background: #1f1f1f;
}

.login-container {
  margin: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;

  width: 300px;
  height: 400px;
  transform: translate(-50%, -50%);
}

.logo img {
  width: 195px;
}
</style>
