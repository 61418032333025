<template>
  <div class="booking">
    <Header
      v-if="!showScreener"
      :screener="showScreener"
      :step="steps[currentStep]"
    />
    <Header
      v-if="showScreener"
      :screener="showScreener"
      :step="screenerSteps[currentScreenStep]"
    />

    <transition name="fade">
      <Loading :transparent="true" v-if="loading" />
    </transition>
    <div v-show="!showScreener">
      <!--<transition-group name="slide">-->
      <transition-group name="fade">
        <BookingStep
          :key="JSON.stringify(step)"
          :data="input"
          :percent="
            steps.length - currentStep == 1
              ? 100
              : Math.floor((currentStep / steps.length) * 100)
          "
          :active="step == steps[currentStep]"
          v-for="step in steps"
          v-show="step == steps[currentStep]"
          :step="step"
        />
      </transition-group>
    </div>

    <transition name="fade">
      <div v-if="showScreener">
        <transition-group name="fade">
          <ScreeningStep
            :key="JSON.stringify(step)"
            :data="screenerInput"
            :active="step == screenerSteps[currentScreenStep]"
            :percent="Math.floor((currentStep / steps.length) * 100)"
            v-for="step in screenerSteps"
            v-show="step == screenerSteps[currentScreenStep]"
            :step="step"
          />
        </transition-group>
      </div>
    </transition>

    <div class="error" v-if="error">
      {{ error }}
    </div>

    <div v-if="false">
      <button class="db-button" @click="showDebug = !showDebug">>_</button>
      <transition name="fade">
        <div v-if="showDebug" class="debug">
          <pre>
          {{ input.selectedReason }}
          {{ input.selectedProvider }}
          {{ input.slots }}
          {{ input.slot }}
        <!--{{ input }}-->
        </pre>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from "axios"
import { EventBus } from "@/event-bus"
import TextField from "@/components/booking/TextField"
import EmailField from "@/components/booking/EmailField"
import Header from "@/components/booking/Header"
import PhoneField from "@/components/booking/PhoneField"
import PostalCodeField from "@/components/booking/PostalCodeField"
import BirthdayField from "@/components/booking/BirthdayField"
import Loading from "@/components/Loading"
import PregnancySplash from "@/components/booking/PregnancySplash"
import MeetPcc from "@/components/booking/MeetPcc"
import BookingStep from "@/components/booking/BookingStep"
import ScreeningStep from "@/components/booking/ScreeningStep"
import AnswerSelector from "@/components/booking/AnswerSelector"
//import AreasSelector from "@/components/booking/AreasSelector"
import DateSelector from "@/components/booking/DateSelector"
import OnboardingDateSelector from "@/components/booking/OnboardingDateSelector"
//import HelpSelector from "@/components/booking/HelpSelector"
import TypeSelector from "@/components/booking/TypeSelector"
import LocationSelector from "@/components/booking/LocationSelector"
import ReasonSelector from "@/components/booking/ReasonSelector"
import ProviderSelector from "@/components/booking/ProviderSelector"
import PaymentForm from "@/components/booking/PaymentForm"

import config from "@/../config.json"

const backendUrl = config.backend_url[process.env.ENV]

export default {
  name: "Booking",
  components: {
    Header,
    BookingStep,
    ScreeningStep,
    Loading,
  },

  data() {
    return {
      currentStep: 0,
      currentScreenStep: 0,
      loading: false,
      verifying: true,
      showScreener: false,
      showDebug: false,
      error: null,
      hold: null,

      screenerInput: {
        pregnant: null,
        overweight: null,
        nextSixMonths: null,
      },

      input: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        postalCode: null,
        email: null,
        birthday: null,
        postalCode: null,
        location: null,
        help: null,
        serviceType: null,
        //areas: null,
        visitReasons: null,
        selectedReason: null,
        selectedProvider: null,
        pccProvider: null,
        slots: null,
        slot: null,
        onboardingReason: null,
        onboardingSlots: null,
        onboardingSlot: null,
      },

      screenerSteps: [
        // Screener Intro
        {
          name: "intro",
          nav: { back: true, logo: true, close: false, lite: true },
          content: {
            middleAlign: true,
            boldHeader: true,
            header:
              "Let's make sure you're a great<br>candidate for a consultation!",
          },

          footer: {
            show: () => true,
            button: {
              text: "Next",
              handler: () => {
                this.gotoScreenStep("pregnant")
              },
            },
          },

          backgroundImg: require("../assets/images/backgrounds/5.png"),
        },

        // Pregnant
        {
          name: "pregnant",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: "Are you currently pregnant or<br>trying to get pregnant?",
          },

          form: {
            target: "pregnant",
            component: AnswerSelector,
            handler: () => {
              if (this.screenerInput.pregnant) {
                this.gotoScreenStep("pregnant-next")
              } else {
                this.gotoScreenStep("overweight")
              }
            },
          },

          backgroundImg: false,
        },

        // Pregnant-Next
        {
          name: "pregnant-next",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            margin: "102px auto 64px",
            header:
              "Pregnancy changes things.<br>It's best to wait until you deliver.",
            text: "Try these resources or keep booking.",
          },

          form: {
            target: "pregnant",
            component: PregnancySplash,
            video: "https://player.vimeo.com/video/432842802",
            options: [
              {
                target: "_blank",
                href: "https://askamelia.com/orientations",
                text: "watch a free orientation",
              },
              {
                href: "https://bustmob.app.link/app",
                text: "download bustmob academy",
              },
              {
                text: "Continue Booking",
                klass: "continue",
                handler: () => {
                  this.screenerInput.pregnant = false
                  this.gotoScreenStep("overweight")
                },
              },
            ],
          },

          backgroundImg: false,
        },

        // overweight
        {
          name: "overweight",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: `Is it possible that a medical<br>organization may consider you<br>"extremely overweight or obese?"`,
          },

          form: {
            target: "overweight",
            component: AnswerSelector,
            handler: () => {
              if (this.screenerInput.overweight) {
                this.gotoScreenStep("overweight-next")
              } else {
                this.gotoScreenStep("sixmonth")
              }
            },
          },

          backgroundImg: false,
        },

        // overweight-Next
        {
          name: "overweight-next",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header:
              "Unfortunately, we are unable<br>to operate on any patient<br>" +
              "who has a Body Mass Index<br>(BMI) higher than 34.",
            textHTML: `
              Click <a href="https://askamelia.com/bmi-calculator"
                target="_blank" class="step-link"
                rel="noopener">here</a> to calculate your BMI.`,
          },

          form: {
            target: "pregnant",
            component: PregnancySplash,
            options: [
              {
                text: "My BMi is currently below 34",
                handler: () => {
                  this.screenerInput.overweight = false
                  this.gotoScreenStep("sixmonth")
                },
              },
              {
                text: "it will be below 34 before surgery",
                handler: () => {
                  this.screenerInput.overweight = false
                  this.gotoScreenStep("sixmonth")
                },
              },
            ],
          },

          backgroundImg: false,
        },

        // next 6 months
        {
          name: "sixmonth",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: "Do you hope to have surgery<br>within the next 6 months?",
          },

          form: {
            target: "nextSixMonths",
            component: AnswerSelector,
            handler: () => {
              if (this.screenerInput.nextSixMonths) {
                this.gotoScreenStep("ready")
              } else {
                this.gotoScreenStep("sixmonth-next")
              }
            },
          },

          backgroundImg: false,
        },

        // sixmonth-Next
        {
          name: "sixmonth-next",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            margin: "102px auto 64px",
            header:
              "Here's Jenny and Gretta<br>explaining why you probably<br>shouldn't " +
              "book a consult just yet.",
            text: "They’re experts on this.",
          },

          form: {
            target: "pregnant",
            component: PregnancySplash,
            video: "https://player.vimeo.com/video/436739607",
            options: [
              {
                target: "_blank",
                href: "https://askamelia.com/orientations",
                text: "watch a free orientation",
              },
              {
                href: "https://bustmob.app.link/app",
                text: "download bustmob academy",
              },
              {
                text: "Continue Booking",
                klass: "continue",
                handler: () => {
                  this.screenerInput.nextSixMonths = true
                  this.gotoScreenStep("ready")
                },
              },
            ],
          },

          backgroundImg: false,
        },

        // ready
        {
          name: "ready",
          nav: { back: true, logo: true, close: false, lite: true },
          content: {
            middleAlign: true,
            img: require("../assets/images/white-checkmark.svg"),
            boldHeader: true,
            header: "You’re ready for a consult!",
            text: "Next, you’ll choose your surgeon.",
          },

          footer: {
            show: () => true,
            button: {
              text: "Continue",
              handler: () => {
                this.gotoStep("provider")
                setTimeout(() => {
                  this.showScreener = false
                }, 800)
              },
            },
          },

          backgroundImg: require("../assets/images/backgrounds/ready-for-consult.jpg"),
        },
      ],

      steps: [
        // Intro
        {
          name: "intro",
          nav: { back: true, logo: true, close: false, lite: true },
          content: {
            middleAlign: true,
            header: "Let’s get you scheduled.",
            text: `This usually takes about 2 minutes,<br>and we'll be with you \
               every step of the way.`,
          },

          footer: {
            show: () => true,
            button: {
              text: "Get Started",
              handler: () => {
                this.gotoStep("first-name")
              },
            },
          },

          backgroundImg: require("../assets/images/backgrounds/01.png"),
        },

        // Get First Name
        {
          name: "first-name",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: "First things first,<br>what’s your first name?",
            text: "We’ll use this to personalize your experience.",
          },

          form: {
            target: "firstName",
            component: TextField,
            handler: () => {
              document.querySelector(".step-active").scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              })

              this.gotoStep("location")
            },
          },

          backgroundImg: false,
        },

        // Select Location
        {
          name: "location",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            header: "Please select an Amelia location.",
            text: "Next, you’ll choose your procedure.",
          },

          form: {
            component: LocationSelector,
          },

          footer: {
            show: () => this.input.location !== null,
            button: {
              text: "Continue",
              handler: () => {
                document.querySelector(".step-active").scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })

                //this.gotoStep("help")
                this.gotoStep("serviceType")
              },
            },
          },

          backgroundImg: false,
        },

        // Type Selector
        {
          name: "serviceType",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: "What type of service would you<br>like to book?",
          },

          form: {
            target: "serviceType",
            component: TypeSelector,
            handler: () => {
              setTimeout(() => {
                this.gotoStep("reasons")
              }, 500)
            },
          },

          backgroundImg: false,
        },

        //// Need Help?
        //{
        //  name: "help",
        //  onLoad: () => {
        //    this.input.areas = null
        //  },
        //  nav: { back: true, logo: true, close: false, lite: false },
        //  content: {
        //    middleAlign: true,
        //    header: "Would you like us to help you<br>choose your procedure?",
        //    text:
        //      "We’ve created an intuitive guide to help you<br>discover the " +
        //      "procedure right for you.",
        //  },

        //  form: {
        //    component: HelpSelector,
        //  },

        //  footer: {
        //    show: () => this.input.help !== null,
        //    button: {
        //      text: "Continue",
        //      handler: () => {
        //        window.scroll({
        //          top: 0,
        //          left: 0,
        //          behavior: "smooth",
        //        })

        //        if (this.input.help) {
        //          this.gotoStep("areas")
        //        } else {
        //          this.gotoStep("reasons")
        //        }
        //      },
        //    },
        //  },

        //  backgroundImg: false,
        //},

        //// Select Areas
        //{
        //  name: "areas",
        //  nav: { back: true, logo: true, close: false, lite: false },
        //  backTo: 3,
        //  content: {
        //    scrollable: true,
        //    middleAlign: false,
        //    header: "Please select each area<br>you'd like to address.",
        //    text: "You may select as many areas as you would like.",
        //  },

        //  form: {
        //    component: AreasSelector,
        //    handler: () => {
        //      window.scroll({
        //        top: 0,
        //        left: 0,
        //        behavior: "smooth",
        //      })

        //      this.input.areas = null
        //      this.gotoStep("reasons")
        //    },
        //  },

        //  footer: {
        //    show: () => this.input.areas && this.input.areas.length > 0,
        //    button: {
        //      text: "Continue",
        //      handler: () => {
        //        window.scroll({
        //          top: 0,
        //          left: 0,
        //          behavior: "smooth",
        //        })

        //        this.gotoStep("reasons")
        //      },
        //    },
        //  },

        //  backgroundImg: false,
        //},

        // Select Reason
        {
          name: "reasons",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: false,
            header: "Please select the procedure<br>you are interested in.",
          },

          form: {
            component: ReasonSelector,
          },

          footer: {
            show: () => this.input.selectedReason !== null && !this.loading,
            button: {
              text: "Continue",
              handler: () => {
                document.querySelector(".step-active").scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })

                if (this.input.selectedReason.requiresScreening) {
                  this.showScreener = true
                } else {
                  this.gotoStep("provider")
                }
              },
            },
          },

          backgroundImg: false,
        },

        // Select Provider
        {
          name: "provider",
          onLoad: () => {
            this.input.slots = null
          },

          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: false,
            margin: "100px auto",
            header: "Please select a provider.",
            text: "You may choose a specific provider,<br>or the first available.",
          },

          form: {
            component: ProviderSelector,
            handler: async () => {
              document.querySelector(".step-active").scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              })

              await this.getSlots()
              this.loading = false
              this.gotoStep("reason-date")
            },
          },

          backgroundImg: false,
        },

        // Select Date

        {
          name: "reason-date",
          onLoad: () => {
            this.input.slot = null
          },
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: false,
            margin: "100px auto",
            header: () => {
              if (this.input.selectedReason && this.input.selectedProvider) {
                return (
                  `Please select a date and time<br>for your ` +
                  `${this.input.selectedReason.name}` +
                  `<br>with ${this.input.selectedProvider.name}.`
                )
              }
            },
            text: " ",
          },

          form: {
            component: DateSelector,
            handler: async () => {
              EventBus.$emit("verifying", null)
              this.verifying = true
              var found = await this.verifySlot(this.input.slot)

              if (!found) {
                this.input.slot = null
              }
            },
          },

          footer: {
            show: () => this.input.slot !== null && !this.verifying,
            button: {
              text: "Continue",
              handler: async () => {
                EventBus.$emit("verifyingReset", null)

                this.loading = true
                var hold = await this.holdSlot(this.input.slot)

                if (hold) {
                  this.loading = false
                  if (
                    this.input.selectedProvider.pccProviderUid !=
                      "00000000-0000-0000-0000-000000000000" &&
                    this.input.selectedReason.requiresOnboarding
                  ) {
                    try {
                      this.input.pccProvider =
                        this.input.location.providers.filter(
                          (x) =>
                            x.uid == this.input.selectedProvider.pccProviderUid
                        )[0]

                      this.input.onboardingReason =
                        this.input.visitReasons.filter(
                          (x) => x.name.toLowerCase() == "onboarding call"
                        )[0]

                      this.loading = true
                      await this.getOnboardSlots()
                      this.loading = false
                    } catch {
                      this.loading = false
                    }

                    if (
                      this.input.onboardingSlots &&
                      this.input.onboardingSlots.length > 0
                    ) {
                      this.gotoStep("meet-pcc-intro")
                    } else {
                      this.gotoStep("final-questions-intro")
                    }
                  } else {
                    this.gotoStep("final-questions-intro")
                  }
                } else {
                  EventBus.$emit("verifyingFailed", null)
                }

                this.loading = false
              },
            },
          },

          backgroundImg: false,
        },

        // Pcc Intro
        {
          name: "meet-pcc-intro",
          nav: { back: true, logo: true, close: false, lite: true },
          content: {
            middleAlign: true,
            header: "Next, we will book<br>your Onboarding Call.",
            text: "Your Amelia Onboarding Call is one of the<br>most important steps of your journey with us<br>that happens prior to your consultation.",
          },

          footer: {
            show: () => true,
            button: {
              text: "Continue",
              handler: () => {
                this.gotoStep("meet-pcc")
              },
            },
          },

          backgroundImg: require("../assets/images/backgrounds/book-onboarding-call.jpg"),
        },

        {
          name: "meet-pcc",
          nav: { back: true, logo: true, close: false, lite: false },
          component: MeetPcc,
          content: {
            middleAlign: true,
          },

          footer: {
            show: () => true,
            button: {
              text: "schedule",
              handler: () => {
                this.gotoStep("onboarding-date")
              },
            },
          },

          backgroundImg: false,
        },

        // Select Date

        {
          name: "onboarding-date",
          onLoad: () => {
            document.querySelector(".step-active").scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            })

            this.input.onboardingSlot = null
          },
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: false,
            margin: "100px auto",
            header: `Please select a date and time<br>for your onboarding call.`,
            text: " ",
          },

          form: {
            component: OnboardingDateSelector,
            handler: async () => {
              EventBus.$emit("verifying", null)
              this.verifying = true
              var found = await this.verifySlot(this.input.onboardingSlot)

              if (!found) {
                this.input.onboardingSlot = null
              }
            },
          },

          footer: {
            show: () => this.input.onboardingSlot !== null && !this.verifying,
            button: {
              text: "Continue",
              handler: () => {
                this.gotoStep("final-questions-intro")
              },
            },
          },

          backgroundImg: false,
        },

        // Intro
        {
          name: "final-questions-intro",
          backTo: () => {
            this.input.onboardingSlot
              ? this.gotoStep("onboarding-date")
              : this.gotoStep("reason-date")
          },
          nav: { back: true, logo: true, close: false, lite: true },
          content: {
            middleAlign: true,
            header: "Almost done,<br>just a few more questions.",
          },

          footer: {
            show: () => true,
            button: {
              text: "Continue",
              handler: () => {
                this.gotoStep("confirm-first-name")
              },
            },
          },

          backgroundImg: require("../assets/images/backgrounds/almost-done.jpg"),
        },

        // Confirm First Name
        {
          name: "confirm-first-name",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: () => {
              return `Is ${this.input.firstName} your legal first name?<br>If not, please enter it here.`
            },
            text: "We just have to make sure.",
          },

          form: {
            target: "firstName",
            component: TextField,
            handler: () => {
              this.gotoStep("last-name")
            },
          },

          backgroundImg: false,
        },

        // Last Name
        {
          name: "last-name",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: () => {
              return `${this.input.firstName}, what's your last name?`
            },
            text: "For some paper work.",
          },

          form: {
            target: "lastName",
            component: TextField,
            handler: () => {
              this.gotoStep("phone-number")
            },
          },

          backgroundImg: false,
        },

        // Phone Number
        {
          name: "phone-number",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: "What's your phone number?",
            text: "We’ll text or call to confirm appointments.",
          },

          form: {
            target: "phoneNumber",
            component: PhoneField,
            handler: () => {
              this.gotoStep("postal-code")
            },
          },

          backgroundImg: false,
        },

        // Postal Code
        {
          name: "postal-code",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: "What's your postal code?",
            text: "This is used for setting you up as a patient.",
          },

          form: {
            target: "postalCode",
            placeholder: "XXXXX",
            component: PostalCodeField,
            handler: () => {
              this.gotoStep("email")
            },
          },

          backgroundImg: false,
        },

        // Email
        {
          name: "email",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: "What's your e-mail address?",
            text: "We’ll send all the boring paperwork here.",
          },

          form: {
            target: "email",
            placeholder: "email@address.com",
            component: EmailField,
            handler: () => {
              this.gotoStep("birthday")
            },
          },

          backgroundImg: false,
        },

        // Confirm Birthday
        {
          name: "birthday",
          nav: { back: true, logo: true, close: false, lite: false },
          content: {
            middleAlign: true,
            header: "What’s your birthday?",
            text: "You’re not getting any younger.",
          },

          form: {
            target: "birthday",
            placeholder: "MM/DD/YYYY",
            component: BirthdayField,
            handler: async () => {
              var found = await this.verifySlot(this.input.slot)

              if (!found) {
                this.gotoStep("reason-date")
              } else {
                await this.postAppointments()
              }
            },
          },

          backgroundImg: false,
        },

        // Checkout
        {
          name: "payment-form",
          nav: { back: false, logo: true, close: false, lite: false },
          component: PaymentForm,
          content: {
            middleAlign: true,
          },

          footer: {
            show: () => false,
          },
          backgroundImg: false,
        },

        // booked
        {
          name: "booked",
          nav: { back: false, logo: true, close: false, lite: true },
          content: {
            middleAlign: true,
            img: require("../assets/images/white-checkmark.svg"),
            header: "You're all booked!",
            text: "Thank you! We look forward to serving you.<br>We’ll be with you every step of your journey.",
          },

          footer: {
            show: () => true,
            button: {
              text: "askamelia.com",
              handler: () => {
                window.location.href = "https://askamelia.com"
                this.showScreener = false
              },
            },
          },

          backgroundImg: require("../assets/images/backgrounds/all-booked.jpg"),
        },
      ],
    }
  },

  methods: {
    gotoStep(name) {
      let step = this.steps.filter((x) => x.name == name)[0]
      this.currentStep = this.steps.indexOf(step)
    },

    gotoScreenStep(name) {
      let step = this.screenerSteps.filter((x) => x.name == name)[0]
      this.currentScreenStep = this.screenerSteps.indexOf(step)
    },

    getAppointmentPayload() {
      let bd = this.input.birthday.split("/")
      let m = bd[0]
      let d = bd[1]
      let y = bd[2]

      let payload = {
        "practiceUID": this.input.location.uid,
        "selectedReason": this.input.selectedReason,
        "slot": this.input.slot,
        "onboardingReason": this.input.onboardingReason,
        "onboardingSlot": this.input.onboardingSlot,
        "patient": {
          "firstName": this.input.firstName,
          "lastName": this.input.lastName,
          "birthday": `${y}-${m}-${d}`,
          "email": this.input.email,
          "phoneNumber": this.input.phoneNumber,
          "postalCode": this.input.postalCode,
        },
      }

      return payload
    },

    async postAppointments() {
      this.loading = true
      this.error = null
      let path = `${backendUrl}/api/appointment`

      await axios
        .post(path, this.getAppointmentPayload())
        .then((resp) => {
          if (resp.status == 202) {
            this.loading = false
            this.gotoStep("payment-form")
          }
        })
        .catch((e) => {
          this.loading = false
          this.error = "something went wrong."
          setTimeout(() => {
            this.error = null
          }, 3000)
          console.log(e)
        })
    },

    async getSlots() {
      this.loading = true
      let path = `${backendUrl}/api/schedule/${this.input.selectedReason.uid}`

      if (this.input.selectedProvider) {
        path += `/${this.input.selectedProvider.uid}`
      }

      await axios
        .get(path)
        .then((resp) => {
          let slots = resp.data.data

          this.input.slots = slots
        })
        .catch((e) => {
          console.log(e)
        })
    },

    async verifySlot(slot) {
      let path =
        `${backendUrl}/api/schedule/${slot.visitReasonUid}/` +
        `${slot.providerUid}/${slot.start}?check=true`

      if (this.hold) {
        path += "&include_exp=true"
      }

      let found = false

      await axios
        .get(path)
        .then((resp) => {
          found = resp.data.found

          if (found) {
            if (this.hold) {
              if (this.hold == resp.data.exp) {
                EventBus.$emit("verifyingSuccess", null)
              } else {
                EventBus.$emit("verifyingFailed", null)
              }
            } else {
              EventBus.$emit("verifyingSuccess", null)
            }
          } else {
            EventBus.$emit("verifyingFailed", null)
          }
        })
        .catch((e) => {
          console.log(e)
          EventBus.$emit("verifyingFailed", null)
        })

      this.verifying = false

      return found
    },

    async holdSlot(slot) {
      let path =
        `${backendUrl}/api/schedule/${slot.visitReasonUid}/` +
        `${slot.providerUid}/${slot.start}`

      let hold = false

      await axios
        .post(path)
        .then((resp) => {
          hold = resp.status == 200

          if (hold) {
            this.hold = resp.data.exp
          }
        })
        .catch((e) => {
          hold = false
          console.log(e)
        })

      return hold
    },

    async createPayment(e) {
      this.loading = true
      let path = `${backendUrl}/api/payments`

      axios
        .post(path, {
          amount: `${e.cost}`,
          currency: "USD",
          account: e.token,
          expiry: e.expiry,
          description: `${this.input.selectedReason.name} at ${this.input.location.name}`,
          practiceUID: this.input.location.uid,
          name: `${this.input.firstName} ${this.input.lastName}`,
        })
        .then((resp) => {
          console.log(resp)
          this.loading = false
          this.currentStep += 1
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          EventBus.$emit(
            "show-error",
            "There was an error processing your payment."
          )
        })
    },

    async getOnboardSlots() {
      this.loading = true
      let path = `${backendUrl}/api/schedule/${this.input.onboardingReason.uid}`

      if (this.input.pccProvider) {
        path += `/${this.input.selectedProvider.pccProviderUid}`
      }

      path += `/${this.input.slot.end}`

      await axios
        .get(path)
        .then((resp) => {
          let slots = resp.data.data
          this.input.onboardingSlots = slots
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },

  mounted() {
    EventBus.$on("set-first-available", (provider, value) => {
      let index = this.input.location["providers"].indexOf(provider)

      this.$set(
        this.input.location["providers"][index],
        "firstAvailable",
        value
      )

      console.log(this.input.location)
    })

    EventBus.$on("input", (k, v) => {
      this.input[k] = v
    })

    EventBus.$on("screenerInput", (k, v) => {
      this.screenerInput[k] = v
    })

    EventBus.$on("getSlots", async (e) => {
      this.input.selectedReason = e

      await this.getSlots()
      this.loading = false
    })

    EventBus.$on("next", () => {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep += 1
      } else {
        this.currentStep = 0
      }

      if (typeof this.steps[this.currentStep].onLoad == "function") {
        this.steps[this.currentStep].onLoad()
      }
    })

    EventBus.$on("back", () => {
      if (this.steps[this.currentStep].backTo) {
        this.steps[this.currentStep].backTo()
      } else if (this.currentStep > 0) {
        this.currentStep -= 1
      } else if (this.currentStep == 0) {
        window.location.href = "https://askamelia.com"
      } else {
        this.currentStep = this.steps.length - 1
      }

      if (typeof this.steps[this.currentStep].onLoad == "function") {
        this.steps[this.currentStep].onLoad()
      }
    })

    EventBus.$on("back-screen", () => {
      console.log("back")
      console.log("currentStep: " + this.currentScreenStep)
      if ([3, 5, 7].includes(this.currentScreenStep)) {
        this.currentScreenStep -= 2
      } else if (this.currentScreenStep == 0) {
        this.currentStep = 4
        this.gotoStep("reasons")
        this.showScreener = false
      } else {
        this.currentScreenStep -= 1
      }

      console.log("currentStep: " + this.currentScreenStep)
    })

    EventBus.$on("createPayment", async (e) => {
      await this.createPayment(e)
    })
  },

  destroyed() {
    EventBus.$off("input")
    EventBus.$off("next")
    EventBus.$off("back")
    EventBus.$off("back-screen")
  },
}
</script>

<style scoped>
input[type="text"] {
  font-family: "brandon-grotesque";
}

.container {
  margin-top: 0;
}

.slide-enter-active {
  transition: all 0.3s ease-out;
}
.slide-leave-active {
  transition: all 0.3s ease-in;
}
.slide-enter {
  z-index: 10000;
  transform: translateX(100%);
}
.slide-leave {
  z-index: -10000;
  transform: translateX(-100%);
}

.slide-leave-to {
  z-index: -10000;
  transform: translateX(-100%);
}

.slide-enter-to {
  z-index: 10000;
  transform: translateX(0%);
}

.booking {
  font-family: "brandon-grotesque";
  overflow: hidden;
  width: 100%;
}

.debug {
  display: block;
  position: fixed;
  text-align: left;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 243px;
  overflow: scroll;
  background: #000000a3;
  color: lime;
}
.db-button {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 6px;
  right: 20px;
  line-height: 18px;
  font-size: 18px;
  padding: 0;
  background: #000;
  border: 1px solid #000;
  color: #0fff0f;
  border-radius: 4px;
  font-weight: bolder;
  z-index: 9999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
</style>
