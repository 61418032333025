import axios from "axios"
import Model from "./model.js"

export default class Slot extends Model {
  constructor(props) {
    super(props)

    this.data = []
    this.providers = null
    this.day = new Date()
    this.query = ""

    this.remotePath = "/api/proxy/nt/Slot"

    this.toolConfig = props.toolConfig || {
      export: false,
      search: true,
      newForm: false,
      multiUpdate: false,
      updateForm: false,
    }
  }

  async fetchData() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.remotePath}${this.query}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then((resp) => {
          if (resp.data.entry) {
            this.data = resp.data.entry
          } else {
            this.data = []
          }
        })
        .catch((e) => {
          console.log(e)
        })

      console.log(this.data)
    }
  }

  schema() {
    return {
      "resource.start": {
        header: "Time",
        klass: "timebox",
        formatter: (row) => {
          return new Date(row.resource.start).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        },

        display: true,
      },

      "start": {
        header: "start",
        formatter: (row) => {
          return new Date(row.resource.start)
        },

        display: true,
      },

      "resource.status": {
        header: "Status",
        klass: "status",
        styler: (row) => {
          switch (row.resource.status) {
            case "arrived":
              return { "background": "#25d97f", "color": "white" }
            case "cancelled":
              return { "background": "#da0000", "color": "white" }
            case "fulfilled":
              return { "background": "#54575a", "color": "white" }
            case "pending":
              return { "background": "#8c8c8c", "color": "white" }
            case "booked":
              return { "background": "#25a3d9", "color": "white" }
            case "noshow":
              return { "background": "#1a1a1a", "color": "white" }
            case "proposed":
              return { "background": "orange", "color": "white" }
            default:
              return {}
          }
        },
        display: true,
      },

      //'patientName': {
      //  header: 'Patient',
      //  display: true,
      //  klass: 'no-transform',
      //  styler: () => { return {
      //    'text-align': 'left',
      //    'font-weight': '600',
      //  } },
      //},

      "resource.contained": {
        header: "Provider",
        styler: () => {
          return {
            "text-transform": "none",
            "text-align": "left",
            "font-weight": "600",
          }
        },
        formatter: (row) => {
          if (this.providers) {
            let providers = this.providers.filter(
              (x) => x.externalProviderId == row.resource.contained[1].id
            )

            let provider = providers[0]

            if (provider) {
              return provider.name
            } else {
              let first = row.resource.contained[1].name[0].given[0]
              let last = row.resource.contained[1].name[0].family
              return `${first} ${last}`
            }
          }
        },
        display: true,
      },

      ////'type': {
      ////    header: 'Type',
      ////    extractor: row => {
      ////        try {
      ////            return row.resource.extension
      ////                .filter(x => x.url.includes("appointment-type"))[0]
      ////                .valueReference
      ////                .display

      ////        } catch(e) {
      ////            return 'no data'
      ////        }
      ////    },
      ////    display: true,
      ////},

      ////'comment': {
      ////    header: 'Comment',
      ////    display: true,
      ////    extractor: row => {
      ////        return row.resource.comment
      ////    },
      ////    klass: 'tooltip',
      ////},
    }
  }
}
