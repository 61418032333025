<template>
  <form class="option">
    <div
      class="option-box"
      @click="updateOption(option.value)"
      :class="{ 'option-selected': input == option.value }"
      :key="JSON.stringify(option)"
      v-for="option in options"
    >
      {{ option.text }}
      <!--<label>
        <input @input="updateOption" v-model="input" :value="i" type="radio" />
        <div class="option-text">
          {{ option.text }}
        </div>
      </label>-->
    </div>
  </form>
</template>

<script>
import { EventBus } from "@/event-bus"

export default {
  name: "AnswerSelector",
  props: {
    handler: {
      type: Function,
      default: () => {},
    },

    target: {
      type: String,
      default: () => {
        return ""
      },
    },
  },

  data() {
    return {
      input: null,
      options: [
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],
    }
  },

  methods: {
    updateOption(val) {
      this.input = val
      EventBus.$emit("screenerInput", this.target, val)
      this.handler()
    },
  },
}
</script>

<style scoped>
form.option {
  flex-direction: column;
  max-width: 375px;
  margin: 24px auto 0 auto;
  padding: 2px !important;
}

.option-box {
  display: inline-block;
  align-items: center;

  margin: 0 4px;
  width: 88px;
  height: 88px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 64px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 88px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

.option-box:hover,
.option-box:active {
  cursor: pointer;
  border: 2px solid #25a3d9;
}

.option-selected {
  border: 2px solid #25a3d9;
}

.option-selected .option-text {
  color: #25a3d9;
}

.option-text {
  text-align: left;
  padding: 16px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

input[type="radio"] {
  outline: none;
  margin-left: 16px;
  border: 1px solid grey;
  border-radius: 16px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
}

input[type="radio"]:checked {
  border: none;
  outline: none;
  -webkit-appearance: none;
}

input[type="radio"]:after {
  outline: none;
  border-radius: 16px;
  content: "";
  display: block;
  height: 24px;
  width: 24px;
}

input[type="radio"]:checked:after {
  outline: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 24px;
  width: 24px;
  background-image: url("~@/assets/images/checkmark.svg");
}

label {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
