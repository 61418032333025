<template>
  <div class="dash-content">
    <div class="dash-header">
      <h2 class="header-text">Amelia Cloud</h2>
      <h3 class="subheader-text">Select from the categories below:</h3>
    </div>
    <Categories />
  </div>
</template>

<script>
import Categories from "@/components/Categories"

export default {
  name: "HomeContent",
  components: {
    Categories,
  },
}
</script>

<style>
.dash-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 16px 24px;
}

h2.header-text {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}

h3.subheader-text {
  width: 240px;
  margin: 0;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #8c8c8c;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>
