<template>
  <div
    v-if="step"
    :style="{
      'background-image': step.backgroundImg
        ? 'linear-gradient(180deg, rgba(37, 163, 217, 0.6) 0%, #25A3D9 100%),' +
          'url(' +
          step.backgroundImg +
          ')'
        : 'none',
    }"
    class="step"
    :class="{
      lite: step.nav.lite,
      'step-active': active,
    }"
  >
    <div
      class="content"
      :class="{ 'content-middle': step.content.middleAlign }"
      :style="{
        margin: step.content.margin ? step.content.margin : false,
      }"
      v-if="step.content"
    >
      <div v-if="step.content.img" class="img-container">
        <img :src="step.content.img" class="step-img" />
      </div>

      <div v-if="step.content.header" class="header-container">
        <div
          :class="{
            lite: step.nav.lite,
            'bold-header': step.content.boldHeader,
          }"
          class="step-header-text"
          v-html="
            typeof step.content.header === 'function'
              ? step.content.header()
              : step.content.header
          "
        ></div>
      </div>

      <div v-if="step.content.text" class="text-container">
        <div
          :class="{ lite: step.nav.lite }"
          class="step-text"
          v-html="
            typeof step.content.text === 'function'
              ? step.content.text()
              : step.content.text
          "
        ></div>
      </div>

      <div v-if="step.content.textHTML" class="text-container">
        <div
          v-html="step.content.textHTML"
          :class="{ lite: step.nav.lite }"
          class="step-text"
        ></div>
      </div>

      <div v-if="step.form" class="step-form">
        <component
          :form="step.form"
          :target="step.form.target"
          :handler="step.form.handler"
          :is="step.form.component"
        >
        </component>
      </div>

      <div v-if="step.component" class="step-component">
        <component :model="data" :data="data" :is="step.component"> </component>
      </div>

      <div v-if="step.content.html" class="step-html-container">
        <div
          :class="{ lite: step.nav.lite }"
          v-html="
            typeof step.content.html === 'function'
              ? step.content.html()
              : step.content.html
          "
        ></div>
      </div>
    </div>

    <div
      class="footer"
      v-if="step.footer && step.footer.show()"
      :class="{
        'footer-with-border': !step.nav.lite,
      }"
    >
      <div class="footer-elm">
        <button
          v-if="step.footer && step.footer.button"
          @click="step.footer.button.handler"
          :class="{
            'footer-btn': true,
            lite: step.nav.lite,
            'small-btn': step.footer.button.small,
          }"
        >
          {{ step.footer.button.text }}
        </button>
      </div>
    </div>
    <div class="footer-status">
      <div
        :style="{ width: percent + '%' }"
        class="footer-status-bar"
        :class="{ lite: step.nav.lite }"
      ></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus"

export default {
  name: "ScreeningStep",
  props: ["step", "data", "active", "percent"],
  methods: {
    handleNav(dir) {
      EventBus.$emit(dir)
    },
  },

  computed: {},
}
</script>

<style scoped>
.lite {
  color: white;
}

.bold-header {
  font-weight: bold;
}

.footer {
  /*padding-bottom: 32px;*/
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 95px;
  text-align: center;
}

.footer-elm {
  height: 100%;
  position: relative;
}

.footer-btn.lite {
  color: #25a3d9;
  background: #ffffff;
  border: 1px solid #ffffff;
}

.footer-btn:hover {
  background: #2190c0;
}

.footer-btn.lite:hover {
  background: #f1f2f1;
}

.footer-btn {
  display: block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  padding: 16px 32px;
  box-sizing: border-box;
  border-radius: 32px;

  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;

  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  background: #25a3d9;
  color: #ffffff;
  border: 1px solid #25a3d9;
}

.small-btn {
  padding: 4px 10px;
  background: none;
  border: 1px solid #25a3d9;
  color: #25a3d9;
  box-sizing: border-box;
  text-transform: uppercase;
  border-radius: 16px;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 130%;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #25a3d9;
}

.footer-header {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

.footer-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

.step {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
}

@media (max-width: 640px) {
  .content {
    margin: 164px auto;
    display: block;
    width: 90%;
    height: auto;
  }

  .footer-with-border {
    /*padding-bottom: 0px;*/
    background: linear-gradient(180deg, hsla(120, 4%, 100%, 0), #ffffff);
  }
}

.onbottom {
  height: unset;
  position: relative;
  padding-bottom: 64px;
}

.content {
  margin: 164px auto;
  display: block;
  width: 90%;
  height: auto;
}

.content-middle {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.step-form {
  display: block;
  width: 100%;
}

.img-container {
  margin-bottom: 8px;
  width: 100%;
}

.header-container {
  font-style: normal;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  color: black;

  margin-bottom: 8px;
  margin: 0 auto 0 auto;
}

.text-container {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #4d4d4d;

  overflow-wrap: anywhere;

  max-width: 70%;
  margin: 6px auto 32px auto;
}

@media (max-width: 640px) {
  .header-container {
    font-size: 24px;
  }

  .text-container {
    font-size: 16px;
  }
}

button {
  font-family: "brandon-grotesque";
}

.footer-status {
  position: fixed;
  bottom: 0px;
  height: 8px;
  width: 100%;
  vertical-align: middle;
  line-height: 8px;
}

.footer-status-bar {
  height: 8px;
  background: #25a3d9;
  color: #fff;
  text-align: right;
  padding-right: 8px;
  box-sizing: border-box;
  min-width: 20px;
  font-size: 14px;
  font-weight: bold;
}

.footer-status-bar.lite {
  background: white;
}
</style>
