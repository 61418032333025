<template>
  <div class="table">
    <AppointmentTools
      :data="tableData"
      :config="config"
      :type="model.type"
      v-model="filter"
    />

    <div class="stats" v-if="tableData && metrics">
      <div class="stats-cell total">Total: {{ total }}</div>
      <div class="stats-cell booked">Booked: {{ booked }}</div>
      <div class="stats-cell cancelled">Cancelled: {{ cancelled }}</div>
      <div class="stats-cell fulfilled">Fulfilled: {{ fulfilled }}</div>
      <div class="stats-cell arrived">Arrived: {{ arrived }}</div>
      <div class="stats-cell pending">Pending: {{ pending }}</div>
    </div>

    <div class="graph" v-if="tableData && metrics">
      <div
        class="graph-cell booked"
        :style="{ width: (booked / total) * 100 + '%' }"
      >
        {{ Math.floor((booked / total) * 100) + "%" }}
      </div>
      <div
        class="graph-cell cancelled"
        :style="{ width: (cancelled / total) * 100 + '%' }"
      >
        {{ Math.floor((cancelled / total) * 100) + "%" }}
      </div>
      <div
        class="graph-cell fulfilled"
        :style="{ width: (fulfilled / total) * 100 + '%' }"
      >
        {{ Math.floor((fulfilled / total) * 100) + "%" }}
      </div>
      <div
        class="graph-cell arrived"
        :style="{ width: (arrived / total) * 100 + '%' }"
      >
        {{ Math.floor((arrived / total) * 100) + "%" }}
      </div>
      <div
        class="graph-cell pending"
        :style="{ width: (pending / total) * 100 + '%' }"
      >
        {{ Math.floor((pending / total) * 100) + "%" }}
      </div>
    </div>
    <div class="row">
      <div
        class="cell header"
        v-for="(v, k) in model.tableFields()"
        :key="JSON.stringify(v)"
        :style="{
          order: Object.keys(model.tableFields()).indexOf(k),
          justifyContent: 'left',
        }"
      >
        <div class="key" @click="sortDataBy(k)">
          {{ v.header }}
        </div>

        <div
          v-html="sortIcon()"
          v-if="k == activeSort"
          class="sort-active"
        ></div>
      </div>
    </div>

    <div v-if="input.length < 1" class="row">
      <div class="cell">No data</div>
    </div>

    <transition-group name="list-complete" tag="ul">
      <div
        v-for="(row, r) in tableData"
        class="row list-complete-item"
        :key="r + '-' + JSON.stringify(row)"
      >
        <div
          class="cell"
          :key="k"
          :style="{
            width:
              'calc(100%/' +
              (Object.keys(model.tableFields()).length + 1) +
              ')',
            order: Object.keys(model.tableFields()).indexOf(k),
            justifyContent: 'left',
          }"
          v-for="(v, k) in model.tableFields()"
        >
          <div :class="v.klass" :style="v.styler ? v.styler(row) : ''">
            {{
              v.formatter
                ? v.formatter(row, extractField(row, k))
                : extractField(row, k)
            }}
            <div class="tooltip-content" v-if="v.klass == 'tooltip'">
              {{
                v.formatter
                  ? v.formatter(row, extractField(row, k))
                  : extractField(row, k)
              }}
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import AppointmentTools from "@/components/AppointmentTools"
import { sort } from "@/utils/svg"

export default {
  name: "AppointmentTable",
  props: ["loading", "model", "metrics"],
  components: {
    AppointmentTools,
  },
  data() {
    return {
      input: [],
      config: {},
      sortIcon: sort,
      sortAsc: true,
      activeSort: "",
      filter: "",
      filteredData: [],
    }
  },

  mounted() {
    this.input = this.model.data
    console.log(this.input)
    this.config = this.model.toolConfig
    this.activeSort = Object.keys(this.model.tableFields())[0]
  },

  methods: {
    getJustfication: function (key) {
      let headers = Object.keys(this.model.tableFields())
      let len = headers.length
      let index = headers.indexOf(key)

      if (index === 0) {
        return "left"
      } else if (index === len - 1) {
        return "flex-end"
      } else {
        return "center"
      }
    },

    sortDataBy: function (key) {
      this.activeSort = key
      this.sortAsc = !this.sortAsc

      console.log(this.input)
      this.input = this.input.sort((a, b) =>
        this.sortAsc
          ? this.extractField(a, key).localeCompare(this.extractField(b, key))
          : this.extractField(b, key).localeCompare(this.extractField(a, key))
      )
    },

    extractField: function (row, field) {
      if (Array.isArray(field)) {
        let vals = []

        for (var v in field) {
          vals.push(this.extractField(row, field[v]))
        }

        return vals.join(" ")
      } else if (
        this.model.tableFields()[field] &&
        this.model.tableFields()[field].fields
      ) {
        let values = this.model.tableFields()[field].fields

        return this.extractField(row, values)
      } else {
        if (!field.match(/\./)) {
          return row[field]
        } else {
          let fields = field.split(".")
          let val = row

          for (var f in fields) {
            if (val && val[fields[f]]) {
              val = val[fields[f]]
            } else {
              return "No Data"
            }
          }

          return val
        }
      }
    },

    applyFilter: function () {
      this.filteredData = this.input.filter((x) => {
        let found = false
        let search = this.filter.toLowerCase().split(" ")
        let data = JSON.stringify(x).toLowerCase()

        for (var s in search) {
          if (!data.includes(search[s])) {
            return false
          }
        }

        return true
      })
    },
  },

  computed: {
    formOpen: function () {
      return (
        this.showUpdateForm ||
        this.showProcessForm ||
        this.showNewForm ||
        this.showDeletesConfirm
      )
    },

    total: function () {
      return this.tableData.length
    },

    cancelled: function () {
      return this.tableData.filter((x) => x.status == "cancelled").length
    },

    booked: function () {
      return this.tableData.filter((x) => x.status == "booked").length
    },

    fulfilled: function () {
      return this.tableData.filter((x) => x.status == "fulfilled").length
    },

    pending: function () {
      return this.tableData.filter((x) => x.status == "pending").length
    },

    arrived: function () {
      return this.tableData.filter((x) => x.status == "arrived").length
    },

    tableData: function () {
      try {
        if (this.activeSort) {
          let prop = this.activeSort

          if (!this.filter) {
            return this.input.sort((a, b) =>
              this.sortAsc
                ? this.extractField(a, prop).localeCompare(
                    this.extractField(b, prop)
                  )
                : this.extractField(b, prop).localeCompare(
                    this.extractField(a, prop)
                  )
            )
          } else {
            return this.filteredData.sort((a, b) =>
              this.sortAsc
                ? this.extractField(a, prop).localeCompare(
                    this.extractField(b, prop)
                  )
                : this.extractField(b, prop).localeCompare(
                    this.extractField(a, prop)
                  )
            )
          }
        }
      } catch {
        return this.input
      }
    },
  },

  watch: {
    filter() {
      if (this.filter) {
        this.applyFilter()
      }
    },
  },
}
</script>

<style scoped>
.table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  justify-content: center;

  min-width: 90%;
  max-width: 96%;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px auto 48px;
}

.row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 42px;

  background: white;
  box-sizing: border-box;
  /*border-radius: 4px;*/

  cursor: default;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.cell {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  text-transform: uppercase;

  color: #1f1f1f;

  flex: none;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 0;
}

.header {
  font-weight: bold;
  text-transform: none;
  color: #54575a;
  font-size: 12px;
  line-height: 15px;
}

.key {
  cursor: pointer;
  padding: 10px;
}

.sort-active {
  display: contents;
  position: absolute;
}

.pill {
  padding: 4px;
  min-width: 56px;
  color: white;

  font-weight: bold;
  background: #8c8c8c;
  border-radius: 4px;
}

.used,
.ordered {
  background: #54575a;
}

ul {
  padding: 0;
  margin: auto;
  width: 100%;
}

.list-complete-item {
  transition: all 0.5s ease;
}

.list-complete-enter-from,
.list-complete-leave-to {
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
  bottom: 0;
  box-shadow: none;
}

.no-transform {
  text-transform: none;
}

.no-box-shadow {
  box-shadow: none;
}

@media (max-width: 800px) {
  .table {
    width: 600px;
  }
}

@media (max-width: 640px) {
  .table {
    width: 100%;
    max-width: 100%;
    margin: 0 !important;
  }

  .row {
    border: none;
    border-radius: 0;
    margin: 0;
    margin: 0;
  }

  .timebox {
    border-radius: 0;
    box-shadow: none;
  }

  .stats {
    font-size: 10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  z-index: 1500;
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  z-index: 1500;
  opacity: 0;
}

.scan-buffer {
  left: -999rem;
  position: absolute;
}

.tooltip {
  white-space: nowrap;
  text-transform: lowercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-content {
  display: none;
}

.cell:hover .tooltip-content {
  background: #1496bb;
  color: #fff;
  display: block;
  height: content;
  width: 200px;
  text-align: left;
  padding: 20px;
  white-space: initial;
  left: 40%;
  position: absolute;
  border-radius: 4px;
  font-size: 16px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.status {
  height: 24px;
  width: 80px;
  border-radius: 4px;
  font-weight: 600;
  line-height: 24px;
  padding: 0 2px 0 2px;
}

.timebox {
  border-right: 1px solid #eeeeee;
  border-radius: 4px 0 0 4px;
  padding-right: 2px;
  font-weight: 600;
  height: 40px;
  width: 80px;
  line-height: 40px;
  background: #eeeeee;
}

.stats {
  background: #ddd;
  color: white;
  font-weight: 700;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, calc(100% / 6));
  text-align: left;
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 2px 2px #0000000f;
  font-size: 12px;
}

.stats-cell {
  margin: 4px;
  padding: 4px;
  border-radius: 4px;
}

.graph {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 4px;
  border-radius: 0 0 4px 4px;
  margin-bottom: 6px;
  background: linear-gradient(#0000001a, #00000045, #0000001a);
}

.graph-cell:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.graph-cell:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.graph-cell {
  line-height: 20px;
  color: white;
  font-weight: bold;
  display: inline-block;
  height: 100%;
  background: repeating-linear-gradient(90deg, #00000024, transparent 6px);
}

.booked {
  background-color: rgb(37, 163, 217);
}

.fulfilled {
  background-color: rgb(84, 87, 90);
}

.cancelled {
  background-color: rgb(218, 0, 0);
}

.pending {
  background-color: rgb(140, 140, 140);
}

.total {
  background-color: white;
  color: black;
}

.arrived {
  background-color: rgb(37, 217, 127);
}
</style>
