import axios from "axios"
import Model from "./model.js"
import Provider from "./providers.js"

export default class Reminder extends Model {
  constructor(props) {
    super(props)

    this.data = []
    this.externalProviders = []
    this.appointmentTypes = []
    this.type = "reminder"
    this.remotePath = props.remotePath || "/api/reminders"
    this.providerPath = props.providerPath || "/api/practice-settings/providers"
    this.practiceRemotePath = props.practiceRemotePath || "/api/admin/practices"
    this.appointmentTypePath =
      props.appointmentTypePath || "/api/appointment-types"
    this.toolConfig = props.toolConfig || {
      export: true,
      search: true,
      newForm: true,
      multiUpdate: false,
      updateForm: true,
      formStepCount: 3,
      bigForm: true,
    }

    let defaultSettingsConfig = {
      newForm: true,
      updateForm: true,
      multiUpdate: false,
      pageUpdate: false,
      bigForm: true,
    }

    this.settingsConfig = props.settingsConfig
      ? { ...defaultSettingsConfig, ...props.settingsConfig }
      : defaultSettingsConfig
  }

  async fetchData() {
    // await super.fetchData()
    console.log("Reminder Fetch")
    await this.getReminderData()
    await this.getAppointmentTypes()
    this.providerData = await this.getProviderData()
  }

  async getNewForm() {
    let newForm = super.getNewForm()

    return newForm
  }

  // async getUpdateForm() {
  //   let practiceOptions = this.getPracticeOptions()
  //   let updateForm = super.getUpdateForm()
  //   updateForm.practice_id.options = practiceOptions

  //   return updateForm
  // }

  createdMsg(resp) {
    let msg = super.createdMsg()
    msg.field = resp.data.data.name

    return msg
  }

  updatedMsg(resp) {
    let msg = super.updatedMsg()
    msg.field = resp.data.data.name

    return msg
  }

  // formatPayload(input, type) {
  //   let keys =
  //     type == "update"
  //       ? Object.keys(this.updateFormFields())
  //       : Object.keys(this.newFormFields())

  //   let data = input
  //   let payload = {}
  //   let formData = new FormData()

  //   for (var key in keys) {
  //     let val = data[keys[key]]

  //     payload[keys[key]] = data[keys[key]]

  //     if (payload[keys[key]].type == "number") {
  //       payload[keys[key]] = parseInt(data[keys[key]])
  //     }

  //     if (keys[key] == "uid") {
  //       formData.append("uid", val)
  //     }
  //   }

  //   // payload["app_metadata"] = {
  //   //   role: input.role,
  //   //   practice_id: input.practice_id,
  //   // }

  //   formData.append("data", JSON.stringify(payload))

  //   return formData
  // }

  getPracticeOptions() {
    let options = {}

    for (var p in this.practiceData) {
      let o = this.practiceData[p]

      options[o.city] = o.uid
    }

    return options
  }

  getVisitAppointmentTypesOptions() {
    let at = { "None": "00000000-0000-0000-0000-000000000000" }

    for (let key in this.appointmentTypes) {
      let k = this.appointmentTypes[key]
      at[k.type] = k.uid
    }

    return at
  }

  async getProviderData() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.providerPath}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then(d => {
          this.externalProviders = d.data.data.externalProviders
        })
        .catch(e => console.log(e))
    }
  }

  getProviderOptions() {
    let options = {}

    for (var p in this.externalProviders) {
      let o = this.externalProviders[p]

      options[o.name] = o.uid
    }

    return options
  }

  async getReminderData() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.remotePath}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then(d => {
          this.data = d.data.data
        })
        .catch(e => console.log(e))
    }
  }

  async getAppointmentTypes() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.appointmentTypePath}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then(d => {
          this.appointmentTypes = d.data
        })
        .catch(e => console.log(e))
    }
  }

  async postData(data) {
    if (this.auth) {
      await axios
        .post(`${this.backendUrl}${this.remotePath}`, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${this.auth.accessToken}`,
            Id: this.auth.user.name,
          },
        })
        .then(resp => {
          this.msg = this.createdMsg(resp)
        })
        .catch(e => {
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  async putData(data) {
    var id = data.uid
    if (this.auth) {
      await axios
        .put(`${this.backendUrl}${this.remotePath}/${id}`, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${this.auth.accessToken}`,
            Id: this.auth.user.name,
          },
        })
        .then(resp => {
          this.msg = this.updatedMsg(resp)
        })
        .catch(e => {
          console.log(e)
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  async deleteData(data) {
    for (var i in data) {
      await super.deleteData(data[i].uid)
    }
  }

  schema() {
    return {
      name: {
        header: "Name",
        label: "Name",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Name",
        type: "text",
        required: true,
        step: 1,
        value: null,
      },

      providers: {
        header: "Providers",
        label: "Provider",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Providers",
        type: "multi-checkbox",
        value: [],
        formatter: r => {
          const providers = r.providers.map(p => {
            const exPr = this.externalProviders.filter(ep => ep.uid === p)
            return exPr[0].name + "<br>"
          })

          return providers.join("")
        },
        required: true,
        options: this.getProviderOptions(),
        step: 1,
      },

      hoursBefore: {
        header: "Hours Before",
        label: "Hours Before",
        display: true,
        placeholder: "Hours Before",
        type: "number",
        newForm: true,
        updateForm: true,
        required: true,
        step: 1,
      },

      active: {
        header: "Active",
        label: "Active",
        display: true,
        styler: () => {
          return { "text-align": "center" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Active",
        type: "select",
        required: true,
        options: {
          "True": true,
          "False": false,
        },
        step: 1,
      },

      // sendType: {
      //   header: "Send Type",
      //   label: "Send Type",
      //   display: true,
      //   newForm: true,
      //   updateForm: true,
      //   placeholder: "Send Type",
      //   type: "select",
      //   required: false,
      //   options: {
      //     "Text and Email": "both",
      //     "Text Only": "text",
      //     "Email Only": "email",
      //   },
      //   step: 2,
      // },

      textMessage: {
        header: "Text Message",
        label: "Text Message",
        placeholder: "Text Message",
        display: true,
        type: "textarea",
        display: true,
        newForm: true,
        updateForm: true,
        required: false,
        step: 2,
      },

      // emailMessage: {
      //   header: "Email Message",
      //   label: "Email Message",
      //   placeholder: "Email Message",
      //   type: "textarea",
      //   display: true,
      //   newForm: true,
      //   updateForm: true,
      //   required: false,
      //   step: 2,
      // },

      askConfirm: {
        header: "Ask Confirm",
        label: "Ask for Confirmation",
        placeholder: "Ask Confirm",
        type: "select",
        display: true,
        newForm: true,
        updateForm: true,
        required: false,
        options: {
          "Yes": "true",
          "No": "false",
        },
        value: "false",
        step: 3,
      },

      askCancel: {
        header: "Ask Cancel",
        label: "Ask for Cancel",
        placeholder: "Ask Cancel",
        type: "select",
        display: true,
        newForm: true,
        updateForm: true,
        required: false,
        options: {
          "Yes": "true",
          "No": "false",
        },
        value: "false",
        step: 3,
      },

      appointmentTypeUid: {
        header: "Appointment Type",
        label: "Appointment Type",
        placeholder: "Appointment Type",
        type: "select",
        display: true,
        newForm: true,
        updateForm: true,
        required: false,
        value: null,
        options: this.getVisitAppointmentTypesOptions(),
        step: 3,
      },

      uid: {
        type: "hidden",
        updateForm: true,
        step: 1,
        value: null,
        required: true,
      },
    }
  }
}
