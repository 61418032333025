<template>
  <div>
    <form @submit.prevent="handleSubmit" class="step-text-field">
      <input
        v-if="active"
        @keyup="updateValue"
        :value="data[target]"
        class="text-field"
        placeholder="(___) ___-____"
        maxlength="14"
        type="tel"
      />

      <input
        v-if="!active"
        :value="data[target]"
        class="text-field"
        placeholder="(___) ___-____"
        maxlength="14"
        type="text"
        disabled
      />

      <button class="text-field-submit" :disabled="!data[target]"></button>
    </form>

    <div class="info" v-if="data && data.location && data.location.phoneNumber">
      if you’re outside the us,<br />please give us a call at
      <div class="phone-number-container">
        <a :href="`tel:${data.location.phoneNumber}`">{{
          data.location.phoneNumber
        }}</a>

        <div class="phone-number">
          {{ data.location.phoneNumber }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus"
export default {
  name: "PhoneField",
  props: {
    handler: {
      type: Function,
      default: () => {},
    },

    active: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: {},
    },

    target: {
      type: String,
      default: "",
    },
  },

  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
        document.querySelector(".step-active").scroll({
          top: 100,
          left: 0,
          behavior: "smooth",
        })
      },
    },
  },

  methods: {
    updateValue(e) {
      let val = e.target.value
      EventBus.$emit("input", this.target, this.formatPhoneNumber(val))
    },

    formatPhoneNumber(value) {
      if (!value) return value
      const phoneNumber = value.replace(/[^\d]/g, "")
      const phoneNumberLength = phoneNumber.length
      if (phoneNumberLength < 4) return phoneNumber
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`
    },

    handleSubmit() {
      this.handler()
    },
  },
}
</script>

<style scoped>
.phone-number-container {
  display: inline-block;
}

.info {
  margin-top: 16px;
  color: #4d4d4d;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  display: inline-block;
}

.info a {
  display: none;
  color: #25a3d9;
}

.phone-number {
  display: block;
  text-decoration: underline;
}

@media (max-width: 640px) {
  .info a {
    display: block;
  }

  .phone-number {
    display: none;
  }
}

form.step-text-field {
  display: grid;
  max-width: 375px;
  margin: 0 auto 0 auto;
  padding: 12px;
  grid-template-areas: "input submit";
  background: #ffffff;
  border: 2px solid #25a3d9;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
}

@media (max-width: 640px) {
  form.step-text-field {
    width: 100% !important;
    margin: 0 auto;
  }
}

.text-field:focus {
  outline: none;
}

.text-field-submit {
  grid-area: submit;
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 4px;
  border: 1px solid #25a3d9;
  border-radius: 16px;
  color: white;

  background-position: center;
  background-repeat: no-repeat;
  background-color: #25a3d9;
  background-image: url("~@/assets/images/forward-lite.svg");
}

.text-field-submit:disabled {
  background-color: #8c8c8c;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/forward-lite.svg");
  border: 1px solid #8c8c8c;
}

.text-field {
  grid-area: input;
  padding-left: 6px;
  height: 32px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-family: "brandon-grotesque";
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.04em;
  width: calc(100% - 24px);
}
</style>
