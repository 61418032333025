import axios from "axios"
import config from "@/../config.json"
const backendUrl = config.backend_url[process.env.ENV]

export default class Model {
  constructor(props) {
    this.data = []
    this.msg = ""
    this.query = props.query || ""
    this.type = props.type || "model"
    this.backendUrl = props.backendUrl || backendUrl
    this.remotePath = props.remotePath || ""
    this.columns = props.columns || false
    this.toolConfig = props.toolConfig || {
      export: true,
      search: true,
      newForm: true,
      updateForm: true,
      scanner: true,
      multiUpdate: true,
      formStepCount: 1,
      bigForm: false,
    }

    if (props.auth) {
      this.auth = props.auth
    }
  }

  schema() {
    return {}
  }
  getNewForm() {
    return this.newFormFields()
  }
  getUpdateForm() {
    return this.updateFormFields()
  }
  getSettingsForm() {
    return this.settingsFormFields()
  }
  createdMsg(resp) {
    return { action: "created", field: this.type }
  }
  deleteMsg(resp) {
    return { action: "deleted", field: this.type }
  }
  updatedMsg(resp) {
    return { action: "updated", field: this.type }
  }

  setAuth(auth) {
    this.auth = auth
  }

  tableFields() {
    let tableFields = {}
    let schema = this.schema()

    if (this.columns) {
      for (var i in schema) {
        if (schema[i].display && this.columns.includes(i)) {
          tableFields[i] = schema[i]
        }
      }
    } else {
      for (var i in schema) {
        if (schema[i].display) {
          tableFields[i] = schema[i]
        }
      }
    }

    return tableFields
  }

  newFormFields() {
    let fields = {}
    let schema = this.schema()

    for (var i in schema) {
      if (schema[i].newForm) {
        fields[i] = schema[i]
      }
    }

    return fields
  }

  updateFormFields() {
    let fields = {}
    let schema = this.schema()

    for (var i in schema) {
      if (schema[i].updateForm) {
        fields[i] = schema[i]
      }
    }

    return fields
  }

  settingsFormFields() {
    let fields = {}
    let schema = this.schema()

    for (var i in schema) {
      if (schema[i].settingsForm) {
        fields[i] = schema[i]
        fields[i].step = 1
      }
    }

    return fields
  }

  filterAttrs(names, data) {
    let attrs = {}

    for (var name in names) {
      if (data[names[name]]) {
        attrs[names[name]] = data[names[name]]
      }
    }

    return attrs
  }

  formatPayload(input) {
    let payload = input

    // for (var key in payload) {
    //   console.log(payload[key])
    //   if (payload[key] === "") {
    //     delete payload[key]
    //   }
    // }

    return payload
  }

  async fetchData() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.remotePath}${this.query}`, {
          headers: {
            authorization: `Bearer ${this.auth.accessToken}`,
          },
        })
        .then(resp => {
          this.data = resp.data.data
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  async postData(data) {
    if (this.auth) {
      await axios
        .post(`${this.backendUrl}${this.remotePath}`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${this.auth.accessToken}`,
            Id: this.auth.user.name,
          },
        })
        .then(resp => {
          this.msg = this.createdMsg(resp)
        })
        .catch(e => {
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  async putData(id, data) {
    if (this.auth) {
      await axios
        .put(
          `${this.backendUrl}${this.remotePath}/${id}`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${this.auth.accessToken}`,
              Id: this.auth.user.name,
            },
          }
        )
        .then(resp => {
          this.msg = this.updatedMsg(resp)
        })
        .catch(e => {
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  async deleteData(id) {
    console.log(id)
    if (this.auth) {
      await axios
        .delete(`${this.backendUrl}${this.remotePath}/${id}`, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${this.auth.accessToken}`,
            Id: this.auth.user.name,
          },
        })
        .then(resp => {
          this.msg = this.deleteMsg(resp)
        })
        .catch(e => {
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  stateOptions() {
    return {
      Alabama: "AL",
      Alaska: "AK",
      Arizona: "AZ",
      Arkansas: "AR",
      California: "CA",
      Colorado: "CO",
      Connecticut: "CT",
      Delaware: "DE",
      "District Of Columbia": "DC",
      Florida: "FL",
      Georgia: "GA",
      Hawaii: "HI",
      Idaho: "ID",
      Illinois: "IL",
      Indiana: "IN",
      Iowa: "IA",
      Kansas: "KS",
      Kentucky: "KY",
      Louisiana: "LA",
      Maine: "ME",
      Maryland: "MD",
      Massachusetts: "MA",
      Michigan: "MI",
      Minnesota: "MN",
      Mississippi: "MS",
      Missouri: "MO",
      Montana: "MT",
      Nebraska: "NE",
      Nevada: "NV",
      "New Hampshire": "NH",
      "New Jersey": "NJ",
      "New Mexico": "NM",
      "New York": "NY",
      "North Carolina": "NC",
      "North Dakota": "ND",
      Ohio: "OH",
      Oklahoma: "OK",
      Oregon: "OR",
      Pennsylvania: "PA",
      "Rhode Island": "RI",
      "South Carolina": "SC",
      "South Dakota": "SD",
      Tennessee: "TN",
      Texas: "TX",
      Utah: "UT",
      Vermont: "VT",
      Virginia: "VA",
      Washington: "WA",
      "West Virginia": "WV",
      Wisconsin: "WI",
      Wyoming: "WY",
    }
  }
}
