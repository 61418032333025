<template>
  <form class="location">
    <div
      class="location-box"
      :class="{ 'location-selected': input == i }"
      :key="JSON.stringify(practice)"
      v-for="(practice, i) in data"
    >
      <label>
        <input
          @input="updateLocation"
          v-model="input"
          :value="i"
          type="radio"
        />
        <img src="~@/assets/images/hover-checkmark.svg" class="check" />
        <img src="~@/assets/images/checkmark.svg" class="check-active" />
        <div class="practice-name">
          {{ practiceName(practice) }}
        </div>
      </label>

      <transition name="slide">
        <div v-show="showProviders.includes(i)" class="providers-container">
          <div
            class="providers"
            :key="k"
            v-for="(v, k) in formatProviders(practice.providers)"
          >
            <div class="role">
              {{ k ? k + "s" : "Not Assigned" }}
            </div>

            <div :key="provider" v-for="provider in v" class="provider">
              {{ provider }}
            </div>
          </div>
        </div>
      </transition>

      <div
        v-if="practice.providers"
        @click="handleProvidersClick(i)"
        class="show-provider"
      >
        <div class="show-provider-button">
          {{ showProviders.includes(i) ? "HIDE PROVIDERS" : "SHOW PROVIDERS" }}
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios"
import config from "@/../config.json"
import { EventBus } from "@/event-bus"

const backendUrl = config.backend_url[process.env.ENV]

export default {
  name: "LocationSelector",
  props: {
    handler: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      showProviders: [],
      input: null,
      data: null,
    }
  },

  async mounted() {
    await this.fetchData()
  },

  methods: {
    practiceName(practice) {
      return `${practice.name.split("Amelia ")[1]}, ${practice.state}`
    },

    formatProviders(providers) {
      let byRole = {}
      for (var p in providers) {
        if (
          providers[p].providerRole.name &&
          providers[p].providerRole.name != "patient care coordinator" &&
          !byRole[providers[p].providerRole.name]
        ) {
          byRole[providers[p].providerRole.name] = []
        }

        if (
          providers[p].providerRole.name &&
          providers[p].providerRole.name != "patient care coordinator"
        ) {
          byRole[providers[p].providerRole.name].push(providers[p].name)
        }
      }

      return byRole
    },

    handleProvidersClick(i) {
      if (this.showProviders.includes(i)) {
        this.showProviders = this.showProviders.filter((x) => x != i)
      } else {
        this.showProviders.push(i)
      }
    },

    updateLocation(e) {
      EventBus.$emit("input", "location", this.data[e.target.value])
    },

    async fetchData() {
      await axios
        .get(`${backendUrl}/api/practices`)
        .then((resp) => {
          this.data = resp.data.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>

<style scoped>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 250px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.providers {
  margin-bottom: 16px;
}

.role {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #4d4d4d;
  margin-bottom: 4px;
}

.providers-container {
  padding-left: 56px;
  margin-bottom: 16px;
  height: auto;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.04em;

  color: #8c8c8c;
}

.provider {
  text-align: left;
}

.show-provider {
  cursor: pointer;
  border-top: 1px solid #f1f2f1;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
}

.show-provider-button {
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #25a3d9;
}

form.location {
  display: flex;
  flex-direction: column;
  max-width: 375px;
  margin: 0 auto 0 auto;
  padding: 2px !important;
}

.location-box {
  align-items: center;
  cursor: pointer;
  margin: 0 0 8px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
}

.location-box.location-selected input[type="radio"] ~ .check-active {
  display: block;
}

.location-box.location-selected input[type="radio"] ~ .check,
.location-box.location-selected input[type="radio"] {
  display: none;
}
.location-box input[type="radio"] {
  outline: none;
  background: transparent url("~@/assets/images/radio.svg");
}

.location-box:hover input[type="radio"] ~ .check {
  display: block;
}

.location-box.location-selected:hover input[type="radio"] ~ .check {
  display: none;
}

.location-box:hover input[type="radio"] {
  display: none;
}

.location-box input[type="radio"] ~ .check {
  display: none;
}

.location-box input[type="radio"] ~ .check-active,
.location-box input[type="radio"] ~ .check-active {
  display: none;
}

.location-box:hover {
  border: 2px solid #25a3d9;
}

.location-selected {
  border: 2px solid #25a3d9;
}

.location-selected .practice-name {
  color: #25a3d9;
}

.practice-name {
  text-align: left;
  padding: 16px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #1a1a1a;
  cursor: pointer;
}

input[type="radio"] {
  outline: none;
  cursor: pointer;
  margin: 0;
  -webkit-appearance: none;
}

input[type="radio"]:checked {
  outline: none;
  border: none;
  -webkit-appearance: none;
}

input[type="radio"]:after {
  outline: none;
  border-radius: 16px;
  content: "";
  display: block;
  height: 16px;
  width: 16px;
}

input[type="radio"]:checked:after {
  outline: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 16px;
  width: 16px;
  background-image: url("~@/assets/images/checkmark.svg");
}

label {
  padding: 0px 0px 0px 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
