import Vue from "vue"
import Router from "vue-router"
import Api from "@/views/Api"
import Admin from "@/views/Admin"
import Booking from "@/views/Booking"
import PracticeAdmin from "@/views/PracticeAdmin"
import Home from "@/views/Home"
import Login from "@/views/Login"
import Profile from "@/views/Profile"
import Inventory from "@/views/Inventory"
import Appointments from "@/views/Appointments"
import Schedule from "@/views/Schedule"
import ConfirmAppointment from "@/views/ConfirmAppointment"
import CancelAppointment from "@/views/CancelAppointment"

import Item from "@/models/item"
import User from "@/models/users"
import Practice from "@/models/practices"
import Patient from "@/models/patients"
import Appointment from "@/models/appointments"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "dashboard",
      meta: { protected: true },
      component: Home,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/admin",
      name: "admin",
      meta: { protected: true },
      component: Admin,
      props: {
        pagerConfig: { style: "toggle" },
        models: {
          "Users": new User({
            columns: ["name", "practice"],
          }),
          "Practices": new Practice({}),
        },
      },
    },
    {
      path: "/api",
      name: "API Explorer",
      meta: { protected: true },
      component: Api,
    },
    {
      path: "/practice-settings",
      name: "Practice Settings",
      meta: { protected: true },
      component: PracticeAdmin,
      props: {
        pagerConfig: { style: "toggle" },
        models: {
          "Practice": new Practice({
            toolConfig: { search: false, newForm: false },
            remotePath: "/api/practice-settings/practice",
          }),
          "Users": new User({
            remotePath: "/api/practice-settings/users",
            practiceRemotePath: "/api/practice-settings/practice",
            columns: ["name", "role"],
          }),
        },
      },
    },
    {
      path: "/patients",
      name: "patients",
      meta: { protected: true },
      component: Inventory,
      props: {
        models: {
          "Patient": new Patient({}),
        },
      },
    },
    {
      path: "/appointments",
      name: "appointments",
      meta: { protected: true },
      component: Appointments,
      props: {
        metrics: true,
        input: new Appointment({}),
      },
    },
    {
      path: "/schedule",
      name: "schedule",
      meta: { protected: true },
      component: Schedule,
    },
    {
      path: "/profile",
      name: "profile",
      meta: { protected: true },
      component: Profile,
    },
    {
      path: "/implants",
      name: "implants",
      meta: { protected: true },
      component: Inventory,
      props: (route) => ({
        models: {
          "In Stock": new Item({
            processForm: true,
            query: "?status=instock&status=onhold",
            columns: ["serialNumber", "referenceNumber", "status"],
          }),

          "Placed": new Item({
            query: "?status=used",
            columns: ["patient", "serialNumber"],
          }),

          "On Order": new Item({
            query: "?status=ordered",
            columns: ["referenceNumber", "createdAt", "origin"],
          }),

          "Removed": new Item({
            query: "?status=expired&status=requested&status=returned",
            columns: ["serialNumber", "referenceNumber", "status"],
          }),

          "All": new Item({
            query: "?status=instock&status=onhold&status=used&status=ordered&status=expired&status=requested&status=returned",
            columns: ["serialNumber", "brand", "referenceNumber", "status"],
            // columns: ["patient", "serialNumber", "referenceNumber", "createdAt", "origin", "status"],
          }),
        },
      }),
    },
    {
      path: "/co/:token",
      name: "confirm",
      component: ConfirmAppointment,
    },
    {
      path: "/ca/:token",
      name: "cancel",
      component: CancelAppointment,
    },
    {
      path: "/booking",
      name: "booking",
      component: Booking,
      meta: { protected: process.env.ENV == "prod" },
    },
  ],
})

export default router
