<template>
  <div class="reasons">
    <div class="search-bar">
      <div class="search-field-icon"></div>
      <input
        v-model="search"
        placeholder="Search procedures and treatments"
        type="text"
      />
    </div>

    <div class="option">
      <div class="not-found" v-if="options.length <= 0">
        <div class="not-found-box">
          <div class="not-found-text">No results found.</div>
        </div>
      </div>
      <transition-group name="slide-fade">
        <div
          class="options"
          v-for="(option, i) in options"
          :key="JSON.stringify(option)"
        >
          <div :class="{ 'option-selected': input == i }" class="option-box">
            <label>
              <div class="option-title">
                <input
                  @input="updateOption(i)"
                  v-model="input"
                  :value="i"
                  type="radio"
                />
                <img src="~@/assets/images/hover-checkmark.svg" class="check" />
                <img
                  src="~@/assets/images/checkmark.svg"
                  class="check-active"
                />
                <div class="option-text">
                  {{ option.name }}
                </div>
              </div>
              <div class="option-content">
                <div v-if="option.priceTag" class="option-price-tag">
                  <div class="price-icon"></div>
                  <div class="price-text">
                    {{ option.priceTag }}
                  </div>
                </div>

                <div v-if="option.description" class="option-description">
                  {{ option.description }}
                </div>
              </div>
            </label>
          </div>

          <div class="option-separator"></div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import config from "@/../config.json"
import { EventBus } from "@/event-bus"
const backendUrl = config.backend_url[process.env.ENV]

export default {
  name: "ReasonSelector",
  props: ["data"],

  data() {
    return {
      search: null,
      input: null,
    }
  },

  methods: {
    updateOption(value) {
      if (this.input == value) {
        this.input = null
      } else {
        this.input = value
      }

      EventBus.$emit("input", "selectedReason", this.options[value])
    },

    async fetchData() {
      let practice_uid = this.data.location.uid

      await axios
        .get(`${backendUrl}/api/visit-reasons/${practice_uid}`)
        .then((resp) => {
          EventBus.$emit("input", "visitReasons", resp.data.data)
          console.log(resp.data.data)
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },

  computed: {
    options: function () {
      let options = []

      try {
        if (this.data.serviceType) {
          let visitReasons = this.data.visitReasons
          let filteredVisitReasons = []

          for (var i in visitReasons) {
            let found = false

            if (this.data.serviceType == visitReasons[i].serviceType) {
              found = true
            }

            if (found) {
              filteredVisitReasons.push(visitReasons[i])
            }
          }
          //      if (this.data.areas) {
          //        let visitReasons = this.data.visitReasons
          //        let filteredVisitReasons = []

          //        for (var i in visitReasons) {
          //          let found = 0
          //          let categories = visitReasons[i].categories

          //          for (var c in categories) {
          //            if (this.data.areas.includes(categories[c])) {
          //              found += 1
          //            }
          //          }

          //          if (found > 0) {
          //            filteredVisitReasons.push(visitReasons[i])
          //          }
          //        }

          options = filteredVisitReasons
            ? filteredVisitReasons
            : this.data.visitReasons
        } else {
          options = this.data.visitReasons
        }

        if (this.search) {
          return options
            .filter((x) =>
              JSON.stringify(x).toLowerCase().match(this.search.toLowerCase())
            )
            .sort((a, b) => ("" + a.order).localeCompare(b.order))
        } else {
          return options.sort((a, b) => ("" + a.order).localeCompare(b.order))
        }
      } catch {
        return []
      }
    },
  },

  watch: {
    "data.location": async function () {
      await this.fetchData()
    },
  },
}
</script>

<style scoped>
.search-bar {
  display: grid;
  max-width: 375px;
  margin: 24px 0 16px;
  padding: 8px 8px 8px 24px;
  width: 100%;
  height: 55px;
  grid-template-areas: "submit input";
  grid-template-columns: 34px;

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
}

.reasons {
  margin: auto;
  max-width: 375px;
}

.option {
  border-radius: 6px;
  overflow: hidden;
  display: block;
  width: 100%;
  padding: 8px !important;
  box-sizing: border-box;
  background: white;
  margin-bottom: 128px;
}

.option-box {
  padding-right: 16px;
  border: 2px solid white;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.option-box:hover {
  border-radius: 6px;
  border: 2px solid #25a3d9;
}

.option-content {
  width: 100%;
  margin-bottom: 16px;
}
.option-separator {
  margin: 6px 0px;
  border-bottom: 1px solid #f1f2f1;
}

span > .options:last-child .option-separator {
  border-bottom: none;
}

.option-selected {
  border-radius: 6px;
  background: rgba(37, 163, 217, 0.05);
  border: 2px solid #25a3d9;
}

.option-selected:hover input[type="radio"] {
  background: white;
}

.option-selected .option-text {
  color: #25a3d9;
}

.option-text {
  cursor: pointer;
  text-align: left;
  padding: 0 16px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

.option-box.option-selected input[type="radio"] ~ .check-active {
  display: block;
}

.option-box.option-selected input[type="radio"] ~ .check,
.option-box.option-selected input[type="radio"] {
  display: none;
}
.option-box input[type="radio"] {
  outline: none;
  background: transparent url("~@/assets/images/radio.svg");
}

.option-box:hover input[type="radio"] ~ .check {
  display: block;
}

.option-box.option-selected:hover input[type="radio"] ~ .check {
  display: none;
}

.option-box:hover input[type="radio"] {
  display: none;
}

.option-box input[type="radio"] ~ .check {
  display: none;
}

.option-box input[type="radio"] ~ .check-active,
.option-box input[type="radio"] ~ .check-active {
  display: none;
}

input[type="radio"] {
  outline: none;
  cursor: pointer;
  margin: 0;
  -webkit-appearance: none;
}

input[type="radio"]:checked {
  border: none;
  outline: none;
  -webkit-appearance: none;
}

input[type="radio"]:after {
  outline: none;
  border-radius: 16px;
  content: "";
  display: block;
  height: 16px;
  width: 16px;
}

input[type="radio"]:checked:after {
  outline: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 16px;
  width: 16px;
  background-image: url("~@/assets/images/checkmark.svg");
}

.option-title {
  padding: 0px 0px 0px 16px;
  margin: 16px 0 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.option-price-tag {
  display: flex;
  text-align: left;
  margin-bottom: 8px;
  padding-left: 52px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #8c8c8c;
}

.price-icon {
  margin-right: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 12px;
  width: 12px;
  background-image: url("~@/assets/images/price-tag.svg");
}

.option-description {
  text-align: left;
  padding-left: 52px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #4d4d4d;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
}

input[type="text"] {
  grid-area: input;
  background: #fff;
  border-radius: 4px;
  font-family: "brandon-grotesque";

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */
  border: none;
  padding: 0px;

  outline: none;
  letter-spacing: 0.04em;
}

.search-field-icon {
  grid-area: submit;
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/search.svg");
}

.not-found-box {
  padding: 8px;
}
.not-found-text {
  color: #4d4d4d;
}
</style>
