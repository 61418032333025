var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inventory"},[(_vm.loading)?_c('Loading',{attrs:{"transparent":true}}):_vm._e(),_vm._v(" "),(_vm.models)?_c('Pager',{attrs:{"pages":_vm.models,"config":_vm.pagerConfig},on:{"update:page":function($event){_vm.model = $event}}}):_vm._e(),_vm._v(" "),(_vm.model && _vm.model.link)?_c('div',{staticClass:"pagins"},[_c('button',{staticClass:"pagin",attrs:{"disabled":!_vm.model.link.filter(function (x) { return x.relation == 'first'; }).length > 0},on:{"click":function($event){$event.preventDefault();return _vm.handlePage('first')}}}),_vm._v(" "),_c('button',{staticClass:"pagin",attrs:{"disabled":!_vm.model.link.filter(function (x) { return x.relation == 'previous'; }).length > 0},on:{"click":function($event){$event.preventDefault();return _vm.handlePage('previous')}}},[_vm._v("\n      BACK\n    ")]),_vm._v(" "),_c('button',{staticClass:"pagin",attrs:{"disabled":!_vm.model.link.filter(function (x) { return x.relation == 'next'; }).length > 0},on:{"click":function($event){$event.preventDefault();return _vm.handlePage('next')}}},[_vm._v("\n      NEXT\n    ")]),_vm._v(" "),_c('button',{staticClass:"pagin",attrs:{"disabled":!_vm.model.link.filter(function (x) { return x.relation == 'last'; }).length > 0},on:{"click":function($event){$event.preventDefault();return _vm.handlePage('last')}}})]):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.models)?_c('VueTable',{attrs:{"post-loading":_vm.postLoading,"model":_vm.model,"scanner":_vm.scanner,"loading":_vm.loading},on:{"selected":function($event){_vm.selected = $event},"on-add":function ($event, callback) {
    _vm.handleAdd($event, callback);
  },"on-update":function ($event, callback) {
      _vm.handleUpdate($event, callback);
    },"on-updates":function ($event, selected, callback) {
        _vm.handleUpdates($event, selected, callback);
      },"on-process":function ($event, selected, callback) {
        _vm.handleProcess($event, selected, callback);
      },"on-delete":function ($event, callback) {
        _vm.handleDelete($event, callback);
      },"on-deletes":function ($event, callback) {
        _vm.handleDeletes($event, callback);
      }}}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.msg !== '')?_c('FloatMsg',{attrs:{"err":_vm.err,"msg":_vm.msg,"callback":function () {
      _vm.msg = '';
      _vm.err = false;
    }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }