<template>
  <div class="providers">
    <div
      class="option-wrapper"
      v-for="(option, i) in options"
      :key="JSON.stringify(option)"
    >
      <div @click.prevent="updateOption(option)" class="option">
        <div class="provider" :class="{ 'option-selected': input == i }">
          <div class="provider-img-container">
            <div
              class="provider-img"
              :style="getImgStyling(option.providerImageSrc)"
            ></div>
          </div>

          <div class="provider-info-container">
            <div class="provider-header">
              {{ option.name }}
            </div>

            <div class="provider-role">
              {{ option.providerRole.name }}
            </div>

            <div class="provider-background">
              {{ option.background }}
            </div>

            <div class="provider-first-slot">
              <div class="provider-first-slot-header">first available appt</div>
              {{ option.firstAvailable || "Data not found" }}
            </div>
          </div>
        </div>
      </div>

      <button class="choose" @click.prevent="updateOption(option)">
        Choose Provider
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { EventBus } from "@/event-bus"
import config from "@/../config.json"
const backendUrl = config.backend_url[process.env.ENV]

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

export default {
  name: "ProviderSelector",
  props: ["data", "handler"],

  data() {
    return {
      input: null,
    }
  },

  methods: {
    updateOption(e) {
      EventBus.$emit("input", "selectedProvider", e)
      this.handler()
    },

    getImgStyling(imgSrc) {
      if (imgSrc) {
        return {
          "background-image": `url(${imgSrc})`,
        }
      }
    },

    async fetchFirstAvailable() {
      for (var i in this.options) {
        let path = `${backendUrl}/api/schedule/${this.data.selectedReason.uid}`
        path += `/${this.options[i].uid}`

        await axios
          .get(path)
          .then((resp) => {
            let slots = resp.data.data

            console.log(slots[0])
            EventBus.$emit(
              "set-first-available",
              this.options[i],
              this.formatFirstSlot(slots[0])
            )
          })
          .catch((e) => {
            console.log(e)
          })
      }
    },

    formatFirstSlot(slot) {
      let day = new Date(slot.start)

      return `${dayNames[day.getDay() - 1]}, ${
        monthNames[day.getMonth()]
      } ${day.getDate()}, ${day.getFullYear()}`
    },
  },

  computed: {
    options: function () {
      let options = []
      if (this.data.location) {
        if (this.data.location.providers && this.data.selectedReason) {
          let providers = this.data.location.providers

          for (var i in providers) {
            let provider = providers[i]
            if (
              provider.visitReasonUids &&
              provider.visitReasonUids.includes(this.data.selectedReason.uid)
            ) {
              options.push(provider)
            }
          }
        }
      }

      try {
        return options.sort((a, b) =>
          new Date(a.firstAvailable) > new Date(b.firstAvailable) ? 1 : -1
        )
      } catch (e) {
        console.log(e)
        return options
      }
    },
  },

  watch: {
    "data.selectedReason": function () {
      this.fetchFirstAvailable()
      console.log("options")
    },
  },
}
</script>

<style scoped>
.providers {
  margin: auto;
  max-width: 375px;
}

.option {
  cursor: pointer;
  overflow: hidden;
  padding: 16px;
  text-align: left;
  background: white;

  border-radius: 6px 6px 0 0;
}

.option-content {
  width: 100%;
  margin-bottom: 16px;
}
.option-separator {
  margin: 6px 0px;
  border-bottom: 1px solid #f1f2f1;
}

.option > .options:last-child .option-separator {
  border-bottom: none;
}

.option-selected {
  border-radius: 6px;
  background: rgba(37, 163, 217, 0.05);
  border: 2px solid #25a3d9;
}

.option-selected .option-text {
  color: #25a3d9;
}

.option-text {
  text-align: left;
  padding: 0 16px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

input[type="radio"] {
  outline: none;
  margin-left: 16px;
  border: 1px solid grey;
  border-radius: 16px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
}

input[type="radio"]:checked {
  border: none;
  outline: none;
  -webkit-appearance: none;
}

input[type="radio"]:after {
  outline: none;
  border-radius: 16px;
  content: "";
  display: block;
  height: 24px;
  width: 24px;
}

input[type="radio"]:checked:after {
  outline: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 24px;
  width: 24px;
  background-image: url("~@/assets/images/checkmark.svg");
}

label {
  margin: 16px 0 8px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.option-price-tag {
  display: flex;
  text-align: left;
  margin-bottom: 8px;
  padding-left: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #8c8c8c;
}

.price-icon {
  margin-right: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 12px;
  width: 12px;
  background-image: url("~@/assets/images/price-tag.svg");
}

.option-description {
  text-align: left;
  padding-left: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #4d4d4d;
}

.provider {
  display: flex;
}

.provider-img-container {
  width: 74px;
  height: 74px;
  display: inline-block;
}

.provider-info-container {
  display: inline-block;
  padding-left: 16px;
  width: 100%;
}

.provider-img {
  width: 74px;
  height: 74px;
  background-size: 100%;
  border-radius: 100px;
  background-position: center center;
  background-image: url("~@/assets/images/logo.svg");
  /*background-color: orange;*/
  background-repeat: no-repeat;
}

.provider-header {
  font-style: normal;
  font-weight: normal;
  -webkit-text-stroke: 0.2px;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

.provider-role,
.provider-first-slot-header {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 140%;
  color: #457a9e;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  margin: 4px 0 8px 0;
}

.provider-background {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: #4d4d4d;

  margin-bottom: 16px;
}

button.choose {
  border: 1px solid #25a3d9;
  padding: 16px;
  background: #25a3d9;
  color: white;
  box-sizing: border-box;
  text-transform: uppercase;
  font-family: brandon-grotesque;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 6px 6px;
  width: 100%;
  margin: 0;
}

.option-wrapper {
  border-radius: 6px;
  padding: 0;
  margin-bottom: 16px;
  box-shadow: 0px 0px 4px #c5c5c5;
}

.footer-info-container {
  margin-top: 32px;
}

.footer-header {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 6px;

  text-align: center;
  letter-spacing: 0.04em;

  color: #1a1a1a;
}

.footer-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 16px;

  text-align: center;
  letter-spacing: 0.04em;

  color: #1a1a1a;
}

.choose.footer {
  background: none;
}

.provider-first-slot {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;

  letter-spacing: 0.04em;
  color: #4d4d4d;
  margin: 4px 0px;
}
</style>
