<template>
  <router-link :to="`/${category.name}`">
    <div :style="{ backgroundImage: `url(${category.img})` }" class="category">
      <p>
        {{ category.name.split("-").join(" ") }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Category",
  props: {
    category: {
      type: Object,
      default: () => ({
        name: "",
        img: "",
      }),
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.category:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.category p {
  color: white;
  width: 155.22px;
  height: fit-content;
  top: 38%;
  position: relative;
  font-family: "brandon-grotesque";
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.08em;
  text-align: center;
}

.category {
  cursor: pointer;
  display: flex;
  width: 155.22px;
  height: 240px;
  margin: 8px;

  border-radius: 4px;

  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: multiply;
  background-position: center center;
}
</style>
