<template>
  <div v-if="$auth.isLoading" class="login">
    <div class="login-container">
      <div class="logo">
        <img src="@/assets/images/amelia-logo.png" />
      </div>
      <Loading :transparent="true" />
    </div>
  </div>

  <div v-else-if="$route.meta.protected == false" id="app">
    <div class="container-booking">
      <div>
        <router-view />
      </div>
    </div>
  </div>

  <div v-else-if="['confirm', 'cancel'].includes($route.name)" id="app">
    <div class="container-confirm">
      <div>
        <router-view />
      </div>
    </div>
  </div>

  <div v-else id="app">
    <div v-if="$auth.isAuthenticated">
      <NavBar />
      <transition name="slide">
        <div class="container">
          <div>
            <router-view />
          </div>
        </div>
      </transition>
      <Footer />
    </div>
    <Login v-else />
  </div>
</template>

<script>
import Loading from "@/components/Loading"
import NavBar from "@/components/NavBar"
import Login from "@/views/Login"

export default {
  name: "app",

  components: {
    NavBar,
    Loading,
    Login,
  },

  mounted() {
    console.log(this.$route)
  },
}
</script>

<style>
button {
  cursor: pointer;
}
#app {
  display: grid;
  height: 100%;
  font-family: "brandon-grotesque";
}

.container {
  padding-top: 72px;
}

html {
  font-family: "brandon-grotesque";
  height: 100%;
}

body {
  background: #f1f2f1;
  margin: 0;
  height: 100%;
  text-align: center;
  padding: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  z-index: 10000;
  background-color: #1f1f1f;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);
}

.result-block-container .result-block {
  opacity: 1;
}

.loading {
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
}

.userImg {
  border-radius: 100px;
  display: block;
  height: 100px;
  margin: 0 auto;
  width: 100px;
}

.hero .app-logo {
  max-width: 10.5rem;
}
</style>
