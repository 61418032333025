<template>
  <div class="results" v-if="results.length">
    <a
      v-for="(result, index) in results"
      :key="index"
      @click="handleClick(result)"
    >
      {{ result.resource.name[0].given[0] }}
      {{ result.resource.name[0].family }} -
      {{ result.resource.birthDate }}
    </a>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus"

export default {
  props: {
    results: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      res: [],
    }
  },
  methods: {
    handleClick(result) {
      let r = result.resource
      let patient = {}

      const container = document.querySelector(".results")
      const name = document.querySelector('[name="name"')
      const birthday = document.querySelector('[name="dateOfBirth')
      const address = document.querySelector('[name="streetAddress"')
      const city = document.querySelector('[name="city"')
      const state = document.querySelector('[name="state"')
      const zip = document.querySelector('[name="zipCode"')
      const phone = document.querySelector('[name="phoneNumber"')
      const email = document.querySelector('[name="email"')

      name.value = `${r.name[0].given[0]} ${r.name[0].family}`
      birthday.value = r.birthDate
      address.value = r.address[0].line ? r.address[0].line[0] : ""
      city.value = r.address[0].city
      state.value = r.address[0].state
      zip.value = r.address[0].postalCode
      r.telecom.forEach((t) => {
        if (t.system === "phone" && (t.use === "home" || t.use === "mobile"))
          phone.value = t.value
        if (t.system === "email") email.value = t.value
      })

      patient.name = `${r.name[0].given[0]} ${r.name[0].family}`
      patient.firstName = r.name[0].given[0]
      patient.lastName = r.name[0].family
      patient.dateOfBirth = r.birthDate
      patient.streetAddress = r.address[0].line ? r.address[0].line[0] : ""
      patient.city = r.address[0].city
      patient.state = r.address[0].state
      patient.zipCode = r.address[0].postalCode
      r.telecom.forEach((t) => {
        if (t.system === "phone" && (t.use === "home" || t.use === "mobile")) {
          patient.phoneNumber = t.value
        }
        if (t.system === "email") {
          patient.email = t.value
        }
      })
      patient.emrid = r.id

      container.remove()
      EventBus.$emit("patient-clicked", patient)
    },
  },
}
</script>

<style scoped>
.results {
  background: #fff;
  border: 1px solid #ccc;
  position: absolute;
  width: 100%;
  z-index: 100;
}
a {
  display: block;
  text-align: left;
  padding: 7px 10px;
  border-bottom: 1px solid #ccc;
  line-height: 1.5;
}

a:hover {
  background: #25a3d9;
  color: #fff;
  cursor: pointer;
}

a:last-child {
  border: none;
}
</style>
