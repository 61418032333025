import axios from "axios"
import Model from "./model.js"

export default class Patient extends Model {
  constructor(props) {
    super(props)

    this.link = []
    this.type = "patient"
    this.remotePath = "/api/proxy/nt/Patient"
    this.toolConfig = props.toolConfig || {
      export: true,
      search: true,
      newForm: false,
      multiUpdate: false,
      updateForm: false,
      formStepCount: 1,
    }
  }

  async fetchData() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.remotePath}${this.query}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then((resp) => {
          let res = resp.data.entry
          this.link = resp.data.link
          this.data = []

          for (var i in res) {
            let row = {}

            for (var f in this.schema()) {
              row[f] = this.schema()[f].extractor(res[i])

              if (!this.data.includes(row)) {
                this.data.push(row)
              }
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  schema() {
    return {
      name: {
        header: "Name",

        extractor: (row) => {
          try {
            return (
              `${row.resource.name[0].family}, ` +
              `${row.resource.name[0].given[0]}`
            )
          } catch (e) {
            return "no data"
          }
        },

        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
      },

      dob: {
        header: "DOB",

        extractor: (row) => {
          if (row.resource.birthDate) {
            return row.resource.birthDate
          } else {
            return "no data"
          }
        },

        display: true,
        klass: "no-transform",
      },

      email: {
        header: "Email",
        extractor: (row) => {
          try {
            return row.resource.telecom.filter((x) => x.system == "email")[0]
              .value
          } catch (e) {
            return "no data"
          }
        },
        display: true,
        klass: "no-transform",
      },

      phone: {
        header: "Phone",
        extractor: (row) => {
          try {
            return row.resource.telecom
              .filter((x) => x.system == "phone")
              .filter((x) => x.use == "home")[0].value
          } catch (e) {
            return "no data"
          }
        },
        display: true,
        klass: "no-transform",
      },
    }
  }
}
