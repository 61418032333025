<template>
  <article class="cancel-appointment">
    <Loading :transparent="true" v-if="loading" />
    <CancelAppointment
      :provider="provider"
      :patient="patient"
      :location="location"
      :startDateTime="startDateTime"
      :procedure="procedure"
      v-if="error === '' && !loading"
    />

    <h1 v-if="error && !loading">{{ error }}</h1>
  </article>
</template>

<script>
import axios from "axios"
import CancelAppointment from "@/components/CancelAppointment"
import Loading from "@/components/Loading"
import config from "@/../config.json"

const backendUrl = config.backend_url[process.env.ENV]

export default {
  components: {
    CancelAppointment,
    Loading,
  },
  data() {
    return {
      patient: "",
      provider: "",
      startDateTime: "",
      location: "",
      procedure: "",
      appointment: {},
      error: "",
      loading: false,
    }
  },
  mounted() {
    const token = this.$route.params.token
    this.loading = true
    axios
      .get(`${backendUrl}/api/cancel/${token}`)
      .then(response => {
        this.appointment = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.error =
            "I'm sorry, but we couldn't find an appointment with that ID."
        } else {
          this.error = error.response.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  },
  watch: {
    // whenever question changes, this function will run
    appointment: function(newAppointment, oldAppointment) {
      newAppointment.participant.map(p => {
        if (p.actor.reference.includes("Patient")) {
          let name = p.actor.display.split(",")
          this.patient = `${name[1]} ${name[0]}`
        }

        if (p.actor.reference.includes("Practitioner")) {
          let name = p.actor.display.split(",")
          this.provider = `${name[1]} ${name[0]}`
        }

        if (p.actor.reference.includes("Location")) {
          this.location = p.actor.display
        }
      })

      newAppointment.extension.map(e => {
        if (e.valueReference.reference.includes("appointment-purpose")) {
          this.procedure = e.valueReference.display
        }
      })

      let date = new Date(newAppointment.start)

      this.startDateTime = `${date.toLocaleString()}`
    },
  },
}
</script>
<style lang="scss" scoped>
.cancel-appointment {
  box-sizing: border-box;
  padding: 16px;
  margin: 35px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fafafa;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;

    h3 {
      overflow-wrap: break-word;
      font-size: 1em;
    }
  }
}
</style>
