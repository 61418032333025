import axios from "axios"
import Model from "./model.js"

export default class Appointment extends Model {
  constructor(props) {
    super(props)
    this.day = new Date()
    this.query = `/${this.day.toISOString().substr(0, 10)}`

    this.remotePath = "/api/appointments"

    this.toolConfig = props.toolConfig || {
      export: false,
      search: true,
      newForm: false,
      multiUpdate: false,
      updateForm: false,
    }
  }

  updateQuery() {
    this.query = `/${this.day.toISOString().substr(0, 10)}`
  }

  async fetchData() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.remotePath}${this.query}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then((resp) => {
          this.data = resp.data.data
        })
        .catch((e) => {
          console.log(e)
        })

      //if (this.data) {
      //  this.data = this.data.filter(
      //    (x) => x.patientName != "" && x.providerName != ""
      //  )
      //}
      console.log(this.data)
    }
  }

  schema() {
    return {
      "start": {
        header: "Time",
        klass: "timebox",
        formatter: (row) => {
          return new Date(row.start).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        },

        display: true,
      },

      "status": {
        header: "Status",
        klass: "status",
        styler: (row) => {
          switch (row.status) {
            case "arrived":
              return { "background": "#25d97f", "color": "white" }
            case "cancelled":
              return { "background": "#da0000", "color": "white" }
            case "fulfilled":
              return { "background": "#54575a", "color": "white" }
            case "pending":
              return { "background": "#8c8c8c", "color": "white" }
            case "booked":
              return { "background": "#25a3d9", "color": "white" }
            case "noshow":
              return { "background": "#1a1a1a", "color": "white" }
            case "proposed":
              return { "background": "orange", "color": "white" }
            default:
              return {}
          }
        },
        display: true,
      },

      "patientName": {
        header: "Patient",
        display: true,
        klass: "no-transform",
        styler: () => {
          return {
            "text-align": "left",
            "font-weight": "600",
          }
        },
      },

      "providerName": {
        header: "Provider",
        styler: () => {
          return {
            "text-transform": "none",
            "text-align": "left",
            "font-weight": "600",
          }
        },
        display: true,
      },

      //'type': {
      //    header: 'Type',
      //    extractor: row => {
      //        try {
      //            return row.resource.extension
      //                .filter(x => x.url.includes("appointment-type"))[0]
      //                .valueReference
      //                .display

      //        } catch(e) {
      //            return 'no data'
      //        }
      //    },
      //    display: true,
      //},

      "comment": {
        header: "Comment",
        display: false,
        extractor: (row) => {
          return row.resource.comment
        },
        klass: "tooltip",
      },
    }
  }
}
