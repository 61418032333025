<template>
  <div class="menu" :class="{ 'menu-active': showMenu }">
    <transition name="fade">
      <div @click="toggle" class="overlay" v-if="showMenu"></div>
    </transition>

    <transition name="slide">
      <div class="menu-container" v-if="showMenu">
        <div class="menu-wrap">
          <div @click="toggle" class="menu-close">
            <span v-html="closeIcon()"></span>
          </div>
          <div class="menu-category-container" v-for="(v, k) in categories">
            <div class="menu-sub-wrap" @click="toggle">
              <router-link
                :to="{ name: k.toLowerCase() }"
                v-if="!v.subcategories"
              >
                {{ k }}
              </router-link>
            </div>

            <div v-if="v.subcategories">
              <div @click="() => toggleSub(k)" class="menu-sub-text">
                {{ k }}
                <div class="chevron" v-if="v.subcategories">
                  <span v-if="expanded != k" v-html="chevronDown()"> </span>
                  <span v-if="expanded == k" v-html="chevronUp()"> </span>
                </div>
              </div>

              <transition name="fade">
                <div v-show="expanded == k" class="menu-link-wrap">
                  <div
                    v-for="(a, b) in v.subcategories"
                    class="menu-link-row"
                    @click="toggle"
                  >
                    <router-link :to="`/${b.toLowerCase()}`">
                      <div class="menu-link-text">
                        {{ b }}
                      </div>
                      <div class="menu-row-counter">
                        {{ a.count }}
                      </div>
                    </router-link>
                  </div>
                </div>
              </transition>
            </div>
          </div>

          <UserMenu @onToggle="toggle" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import UserMenu from "@/components/UserMenu"
import { closeIcon, chevronDown, chevronUp } from "@/utils/svg"

export default {
  name: "slidemenu",
  components: { UserMenu },
  props: ["showMenu", "categories"],
  data() {
    return {
      closeIcon: closeIcon,
      chevronDown: chevronDown,
      chevronUp: chevronUp,
      expanded: "",
    }
  },

  methods: {
    toggle: function () {
      this.$emit("onToggle")
    },

    toggleSub: function (category) {
      if (this.expanded == "" || this.expanded != category) {
        this.expanded = category
      } else {
        this.expanded = ""
      }
    },
  },
}
</script>

<style scoped>
.menu-active {
  width: 100%;
  height: 100%;
  position: fixed;
}

.overlay {
  background: black;
  opacity: 0.7;
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

.menu-container {
  width: 250px;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;

  box-sizing: border-box;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;

  background: #1f1f1f;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.24);
}

.menu-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  width: 100%;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.menu-close {
  width: 32px;
  height: 32px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.menu-category-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px 0px;

  text-align: left;

  font-family: "brandon-grotesque";
  font-style: normal;
  font-weight: bold;
  text-transform: none;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.04em;

  /* White */

  color: #ffffff;
}

.menu-container a {
  text-decoration: none;
  display: contents;
  color: #ffffff;
}

.menu-sub-wrap,
.menu-sub-text {
  cursor: pointer;
}

.menu-sub-text {
}

.menu-link-wrap {
  text-align: left;
}

.menu-link-row {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px;
  margin: 16px 0px 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #f1f2f1;
}

.menu-link-text {
  flex: none;
  flex-grow: 0;
}

.menu-row-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  position: static;
  width: 29px;
  height: 20px;

  border: 1px solid rgba(241, 242, 241, 0.48);
  box-sizing: border-box;
  border-radius: 4px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
}

.chevron {
  width: 16px;
  height: 16px;

  flex: none;
  order: 1;
  flex-grow: 0;

  display: inline-flex;
  right: 46px;
  position: absolute;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter,
.slide-leave-active {
  right: -300px;
  height: 100vh;
  position: fixed;
}
</style>
