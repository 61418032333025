<template>
  <div v-if="form">
    <div class="form-video" v-if="form.video">
      <iframe
        class="video"
        :src="form.video"
        frameborder="0"
        allow="fullscreen"
        allowfullscreen
      ></iframe>
    </div>

    <div v-if="form.options" class="option">
      <div :key="JSON.stringify(option)" v-for="option in form.options">
        <a
          v-if="option.href"
          :href="option.href"
          :target="option.target"
          rel="noopener"
          class="option-box"
        >
          {{ option.text }}
        </a>

        <div
          v-if="option.handler"
          @click="option.handler()"
          class="option-box"
          :class="option.klass"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PregnancySplash",
  props: {
    handler: {
      type: Function,
      default: () => {},
    },

    form: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.form-video {
  margin: 0 auto 24px;
}

.video {
  margin: 0 auto;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  height: 300px;
}

@media (max-width: 600px) {
  .video {
    height: 220px;
  }
}

.option {
  flex-direction: column;
  max-width: 375px;
  margin: 24px auto 0 auto;
  padding: 2px !important;
}

.option-box:hover {
  border: 1.5px solid #25a3d9;
}

.option-box {
  display: block;
  align-items: center;

  margin: 0 auto 8px auto;
  width: 100%;
  height: 44px;
  background: #ffffff;

  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 32px;

  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #25a3d9;
}

.option-box:hover {
  cursor: pointer;
}

.option-box.continue {
  background: #25a3d9;
  color: #ffffff;
  border: 1.5px solid #25a3d9;
}

.option-selected {
  border: 2px solid #25a3d9;
}

.option-selected .option-text {
  color: #25a3d9;
}

.option-text {
  text-align: left;
  padding: 16px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

input[type="radio"] {
  outline: none;
  margin-left: 16px;
  border: 1px solid grey;
  border-radius: 16px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
}

input[type="radio"]:checked {
  border: none;
  outline: none;
  -webkit-appearance: none;
}

input[type="radio"]:after {
  outline: none;
  border-radius: 16px;
  content: "";
  display: block;
  height: 24px;
  width: 24px;
}

input[type="radio"]:checked:after {
  outline: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 24px;
  width: 24px;
  background-image: url("~@/assets/images/checkmark.svg");
}

label {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
