export function csv(array) {
  // Use first element to choose the keys and the order
  var keys = Object.keys(array[0])

  // Build header
  var result = keys.join("\t") + "\n"

  // Add the rows
  array.forEach(function (obj) {
    result += keys.map((k) => obj[k]).join("\t") + "\n"
  })

  return result
}
