<template>
  <div class="appointments">
    <Loading :transparent="true" v-if="loading" />

    <div v-if="model" class="pagins">
      <button class="pagin" @click.prevent="setDay('back')"><</button>
      <div class="date-picker">
        <input
          :value="formatDate(model.day)"
          @input="(e) => setDay(e.target.valueAsDate)"
          type="date"
        />
      </div>

      <button class="pagin" @click.prevent="setDay('next')">></button>
    </div>

    <AppointmentTable
      v-if="!loading && model"
      @selected="selected = $event"
      :metrics="metrics"
      :model="model"
      :loading="loading"
    />

    <transition name="fade">
      <FloatMsg
        v-if="msg !== ''"
        :err="err"
        :msg="msg"
        :callback="
          () => {
            msg = ''
            err = false
          }
        "
      />
    </transition>
  </div>
</template>

<script>
import Loading from "@/components/Loading"
import FloatMsg from "@/components/FloatMsg"
import AppointmentTable from "@/components/AppointmentTable"

export default {
  name: "appointments",
  props: ["input", "metrics"],
  components: {
    Loading,
    FloatMsg,
    AppointmentTable,
  },

  data() {
    return {
      msg: "",
      err: false,
      selected: [],
      loading: true,
      model: null,
    }
  },

  mounted() {
    this.model = this.input
  },

  methods: {
    formatDate(d) {
      return d.toISOString().substr(0, 10)
    },

    dateToggle: (e) => {
      e.target.focus()
      e.target.click()
      console.log(e)
      console.log(e.target)
    },

    setDay(day) {
      if (day == "back") {
        this.model.day.setDate(this.model.day.getDate() - 1)
      } else if (day == "next") {
        this.model.day.setDate(this.model.day.getDate() + 1)
      } else {
        this.model.day = day
      }

      this.model.updateQuery()
    },

    reset: async function () {
      const accessToken = await this.$auth.getTokenSilently()
      let auth = {
        accessToken: accessToken,
        user: this.$auth.user,
        role: this.$auth.appMetadata.role,
      }
      this.model.setAuth(auth)

      if (this.model) {
        this.fetchData()
      } else {
        this.loading = false
      }
    },

    fetchData: async function () {
      this.loading = true

      await this.model.fetchData()
      this.loading = false
    },
  },

  watch: {
    "model.query": function () {
      this.loading = true
      this.reset()
    },

    $route() {
      this.reset()
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.date-picker {
  display: inline-block;
}

input[type="date"] {
  height: 30px;
  -webkit-min-logical-width: calc(100% - 16px);
  background-image: url("~@/assets/images/cal.png");
  background-position: left 94% bottom 50%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-color: white;

  padding-left: 8px;
  color: #8c8c8c;
  border-radius: 0px;
  border: 1px solid #8c8c8c;
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

select.form-field {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.form-field {
  background-image: url("~@/assets/images/chevron.png");
  background-position: left 94% bottom 50%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-color: white;
}

.add-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

.add-overlay-box {
  position: fixed;
  overflow-y: hidden;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  background: #f1f2f1;
  border-radius: 4px;

  width: 380px;

  z-index: 5500;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;
}

form {
  width: 100%;
}

@media (max-width: 640px) {
  .add-overlay {
    width: 100vw;
    height: 100%;
    overflow-y: auto;
  }

  .add-overlay-box {
    overflow-y: scroll;
    top: 50%;
    left: 50%;

    position: fixed;

    width: 100%;
    height: 100%;
  }

  .modal-header {
    padding: 12px 12px 0px !important;
  }

  form {
    width: 93% !important;
    padding: 12px !important;
    margin: 0 auto;
  }
}

.cancel-button,
.next-button,
.submit-button {
  width: 50%;
  height: 56px;
  border-radius: 4px;
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.cancel-button,
.cancel-button:focus,
.cancel-button:active {
  outline: none;
  background: none;
  margin: 8px 8px 0px 0px;
  color: #8c8c8c;
  outline: unset;
  border: 1px solid #8c8c8c;
}

.submit-button {
  background: #25d97f;
  margin: 8px 0px 8px 0px;
  border: 1px solid #25d97f;
  color: #ffffff;
}

.next-button,
.next-button:focus,
.next-button:active {
  outline: none;
  background: #25a3d9;
  margin: 8px 0px 8px 0px;
  border: 1px solid #25a3d9;
  color: #ffffff;
}

.pagin {
  color: #8c8c8c;
  height: 32px;
  width: 32px;
  border-radius: 0px;
  border: 1px solid #8c8c8c;
  background: white;
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  padding: 4px;
}

.pagin:disabled {
  background: white;
  border: 1px solid #8c8c8c;
}

.pagins {
  display: inline-block;
  margin-top: 16px;
}

.datebox {
  color: black;
  display: inline-block;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  margin: 0 6px 0 6px;
}
</style>
