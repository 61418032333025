import Vue from "vue"
import App from "./components/App.vue"
import router from "./router"

// Import the Auth0 configuration and plugin
import config from "../config.json"

import { Auth0Plugin } from "@/auth/auth0-plugin"

import "./assets/css/style.css"

Vue.config.productionTip = false

// Install the authentication plugin
Vue.use(Auth0Plugin, {
  domain: config.auth0[process.env.ENV].domain,
  clientId: config.auth0[process.env.ENV].clientId,
  audience: config.auth0[process.env.ENV].audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  },
})

new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
})
