import { deleteIconSmall, update } from "@/utils/svg"

import Model from "./model.js"
import Practice from "./practices.js"
import Process from "./process.js"

export default class Item extends Model {
  constructor(props) {
    super(props)

    this.type = "item"
    this.remotePath = "/api/items"
    this.toolConfig = props.toolConfig || {
      export: true,
      search: true,
      newForm: true,
      multiUpdate: true,
      updateForm: true,
      processForm: props.processForm,
      formStepCount: 1,
      scanner: true,
    }

    this.processForm = new Process({})
  }

  setAuth(auth) {
    super.setAuth(auth)
    this.processForm.auth = auth
  }

  createdMsg(resp) {
    let msg = super.createdMsg()
    msg.field = resp.data.data.serialNumber

    return msg
  }

  updatedMsg(resp) {
    let msg = super.updatedMsg()
    msg.field = resp.data.data.serialNumber

    return msg
  }

  deletesMsg(count) {
    let msg = super.deleteMsg()
    msg["count"] = count

    return msg
  }

  updatesMsg(count) {
    let msg = super.updatedMsg()
    msg["count"] = count

    return msg
  }

  formatPayload(input) {
    let payload = input

    for (var key in payload) {
      if (payload[key] === "" && key !== "notes") {
        delete payload[key]
      }
    }

    return payload
  }

  async putData(data) {
    var id = data.uid
    await super.putData(id, data)
  }

  async deleteDatas(items) {
    for (var i in items) {
      await super.deleteData(items[i].uid)
    }

    this.msg = this.deletesMsg(items.length)
  }

  async deleteData(data) {
    var id = data[0].uid
    await super.deleteData(id)
  }

  async putDatas(data, items) {
    let payload = {}
    let schema = this.schema()

    for (var i in schema) {
      if (schema[i].multiUpdate) {
        payload[i] = data[i]
      }
    }

    for (var i in items) {
      payload.uid = items[i].uid

      await this.putData(payload)
    }

    this.msg = this.updatesMsg(items.length)
  }

  selectActions() {
    return {
      delete: {
        klass: "icon",
        icon: deleteIconSmall(),
        identifier: this.columns[0],
      },

      update: {
        klass: "icon",
        icon: update(),
        identifier: this.columns[0],
      },

      process: {
        klass: "btn",
        text: "Process",
        identifier: this.columns[0],
      },
    }
  }

  schema() {
    return {
      "serialNumber": {
        header: "Serial",
        display: true,
        newForm: true,
        updateForm: true,
        placeholder: "Serial Number",
        type: "text",
        required: true,
        styler: () => {
          return { textAlign: "left" }
        },
      },

      "brand": {
        header: "Brand",
        display: true,
        newForm: true,
        updateForm: true,
        placeholder: "Brand",
        type: "select",
        required: true,
        options: {
          "Allergan": "Allergan",
          "Mentor": "Mentor",
          "Motiva": "Motiva",
          "Sientra": "Sientra",
        }
      },

      "referenceNumber": {
        header: "Catalog",
        display: true,
        newForm: true,
        updateForm: true,
        placeholder: "Catalog Number",
        type: "text",
        required: true,
      },

      "status": {
        header: "Status",
        klass: "pill",
        styler: this.statusStyler,
        formatter: this.statusFormatter,
        display: true,
        newForm: true,
        multiUpdate: true,
        updateForm: true,
        placeholder: "Status",
        type: "select",
        required: true,
        options: {
          "In Stock": "instock",
          "Ordered": "ordered",
          "On Hold": "onhold",
          "Expired": "expired",
          "Placed": "used",
          "Requested": "requested",
          "Returned": "returned",
          "All": "all",
        },
      },

      "patient": {
        header: "Patient",
        klass: "no-transform",
        display: true,
        fields: ["consign.patient.firstName", "consign.patient.lastName"],
      },

      "surgeon": {
        header: "Surgeon",
        klass: "no-transform",
        display: true,
        fields: ["consign.surgeonFirstName", "consign.surgeonLastName"],
      },

      "createdAt": {
        header: "Date Ordered",
        display: true,
        formatter: this.dateFormatter,
      },

      "origin": {
        header: "Origin",
        klass: "pill",
        styler: () => {
          return {
            background: "#54575A",
          }
        },
        display: true,
        newForm: true,
        updateForm: true,
        placeholder: "Origin",
        type: "select",
        required: true,
        formatter: this.originFormatter,
        options: {
          "Consignment": "consignment",
          "Ordered": "ordered",
        },
      },

      "practiceUid": {
        placeholder: "Practice",
        type: "select",
        updateForm: this.auth.role == "admin",
        required: false,
        options: {},
      },

      "notes": {
        header: "Notes",
        updateForm: true,
        multiUpdate: true,
        placeholder: "Notes",
        type: "textarea",
        required: false,
      },

      "uid": {
        required: true,
        updateForm: true,
        type: "hidden",
      },
    }
  }

  getPracticeOptions() {
    let options = {}

    for (var p in this.practiceData) {
      let o = this.practiceData[p]

      options[o.city] = o.uid
    }

    return options
  }

  async getUpdateForm() {
    let updateForm = super.getUpdateForm()

    if (this.auth.role == "admin") {
      let practiceOptions = this.getPracticeOptions()
      updateForm["practiceUid"].options = practiceOptions
    }

    return updateForm
  }

  async fetchData() {
    await super.fetchData()
    if (this.auth.role == "admin") {
      this.practiceData = await this.getPracticeData()
    }
  }

  async getPracticeData() {
    if (this.auth) {
      let practice = new Practice({ auth: this.auth })
      await practice.fetchData()

      return practice.data
    }
  }

  statusStyler(row) {
    switch (row.status) {
      case "onhold":
        return { background: "#25A3D9" }
      case "instock":
        return { background: "#8C8C8C" }
      case "expired":
        return { background: "#DA0000" }
      case "requested":
        return { background: "#1F1F1F" }
      case "returned":
        return { background: "#54575A" }
    }
  }

  statusFormatter(row) {
    switch (row.status) {
      case "onhold":
        return "On Hold"
      case "instock":
        return "In Stock"
      default:
        return row.status
    }
  }

  originFormatter(row) {
    switch (row.origin) {
      case "consignment":
        return "Consigned"
      default:
        return row.origin
    }
  }

  dateFormatter(row) {
    return new Date(row.createdAt).toLocaleDateString()
  }
}
