import Model from "./model.js"
import axios from "axios"

export default class VisitReason extends Model {
  constructor(props) {
    super(props)

    this.data = []
    this.appointmentPurposes = []
    this.appointmentTypes = []
    this.type = "visit reason"
    this.remotePath = props.remotePath || "/api/visit-reasons"

    this.appointmentTypePath =
      props.appointmentTypePath || "/api/appointment-types"

    this.appointmentPurposePath =
      props.appointmentPurposePath || "/api/appointment-purposes"

    this.toolConfig = props.toolConfig || {
      export: false,
      search: false,
      newForm: true,
      multiUpdate: false,
      updateForm: true,
      formStepCount: 2,
      bigForm: true,
    }

    let defaultSettingsConfig = {
      newForm: true,
      multiUpdate: false,
      pageUpdate: false,
      formStepCount: 2,
      bigForm: false,
    }

    this.settingsConfig = props.settingsConfig
      ? { ...defaultSettingsConfig, ...props.settingsConfig }
      : defaultSettingsConfig
  }

  async fetchData() {
    await super.fetchData()
    //await this.getVisitReasonData()
    await this.getVisitAppointmentPurposes()
    await this.getVisitAppointmentTypes()
  }

  formatPayload(input, type) {
    let keys =
      type == "update"
        ? Object.keys(this.updateFormFields())
        : Object.keys(this.newFormFields())

    let schema = this.schema()

    let data = input
    let payload = {}

    for (var key in keys) {
      let val = data[keys[key]]

      payload[keys[key]] = ["true", "false"].includes(val) ? eval(val) : val

      if (schema[keys[key]].type == "number") {
        payload[keys[key]] = parseInt(data[keys[key]])
      }
    }

    payload["practiceUid"] = this.auth.practice_id

    return payload
  }

  createdMsg(resp) {
    let msg = super.createdMsg()
    msg.field = resp.data.data.name
    return msg
  }

  updatedMsg(resp) {
    let msg = super.updatedMsg()
    msg.field = resp.data.data.name
    return msg
  }

  async getNewForm() {
    let newForm = super.getNewForm()

    return newForm
  }

  getPracticeOptions() {
    let options = {}

    for (var p in this.practiceData) {
      let o = this.practiceData[p]

      options[o.city] = o.uid
    }

    return options
  }

  async getVisitReasonData() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.remotePath}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then((d) => (this.data = d.data.data))
        .catch((e) => console.log(e))
    }
  }

  async getVisitAppointmentPurposes() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.appointmentPurposePath}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then((d) => (this.appointmentPurposes = d.data))
        .catch((e) => console.log(e))
    }
  }

  async getVisitAppointmentTypes() {
    if (this.auth) {
      await axios
        .get(`${this.backendUrl}${this.appointmentTypePath}`, {
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })
        .then((d) => {
          this.appointmentTypes = d.data
          console.log("apptTypes: " + this.appointmentTypes)
        })
        .catch((e) => console.log(e))
    }
  }

  async putData(data) {
    var id = data.uid

    if (this.auth) {
      await axios
        .put(`${this.backendUrl}${this.remotePath}/${id}`, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${this.auth.accessToken}`,
            Id: this.auth.user.name,
          },
        })
        .then((resp) => {
          this.msg = this.updatedMsg(resp)
        })
        .catch((e) => {
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  async deleteData(data) {
    for (var i in data) {
      await super.deleteData(data[i].uid)
    }
  }

  async postData(data) {
    if (this.auth) {
      await axios
        .post(`${this.backendUrl}${this.remotePath}`, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${this.auth.accessToken}`,
            Id: this.auth.user.name,
          },
        })
        .then((resp) => {
          this.msg = this.createdMsg(resp)
        })
        .catch((e) => console.log(e))
    }
  }

  getVisitAppointmentTypesOptions() {
    let at = {}

    for (const key of this.appointmentTypes) {
      at[key.type] = key.uid
    }

    return at
  }

  getVisitAppointmentPurposeOptions() {
    let ap = {}

    for (const key of this.appointmentPurposes) {
      ap[key.purpose] = key.uid
    }

    return ap
  }

  schema = () => {
    return {
      "name": {
        header: "Reason",
        label: "Reason",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Reason",
        type: "text",
        step: 1,
        value: null,
        required: true,
      },
      "minutes": {
        header: "Minutes",
        label: "Minutes",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Minutes",
        type: "number",
        step: 1,
        value: null,
        required: true,
      },
      "categories": {
        header: "Categories",
        label: "Categories",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Categories",
        type: "array",
        formatter: function (r) {
          return r.categories ? r.categories.join(",") : ""
        },
        step: 1,
        value: [],
        required: false,
      },
      "serviceType": {
        header: "Service Type",
        label: "Service Type",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Service Type",
        type: "select",
        options: {
          "Medspa": "medspa",
          "Surgery": "surgery",
          "Onboarding": "onboarding",
        },
        step: 1,
        value: null,
        required: true,
      },
      "appointmentTypeUid": {
        header: "Appointment Type",
        label: "Appointment Type",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Appointment Type",
        type: "select",
        options: this.getVisitAppointmentTypesOptions(),
        formatter: function (r) {
          return r.appointmentType.type
        },
        step: 1,
        value: null,
        required: true,
      },
      "appointmentPurposeUid": {
        header: "Appointment Purpose",
        label: "Appointment Purpose",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Appointment Purpose",
        type: "select",
        options: this.getVisitAppointmentPurposeOptions(),
        formatter: function (r) {
          return r.appointmentPurpose.purpose
        },
        step: 1,
        value: null,
        required: true,
      },

      "requiresScreening": {
        header: "Requires Screening",
        label: "Requires Screening",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Requires Screening",
        type: "select",
        options: {
          "No": false,
          "Yes": true,
        },
        step: 1,
        value: false,
        required: true,
      },

      "requiresOnboarding": {
        header: "Requires Onboarding",
        label: "Requires Onboarding",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "right" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Requires Onboarding",
        type: "select",
        options: {
          "No": false,
          "Yes": true,
        },
        step: 1,
        value: false,
        required: true,
      },

      chargeAmount: {
        header: "Charge Amount",
        label: "Charge Amount",
        display: false,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Charge Amount",
        type: "number",
        step: 1,
        value: null,
        required: false,
      },
      "uid": {
        type: "hidden",
        updateForm: true,
        step: 1,
        value: null,
        required: true,
      },
      "priceTag": {
        header: "Pricing",
        label: "Pricing Info",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Pricing Info",
        type: "text",
        step: 2,
        value: null,
        required: false,
      },
      "order": {
        header: "Order",
        label: "List Order",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "List Order",
        type: "number",
        step: 2,
        value: null,
        required: false,
      },
      description: {
        header: "Description",
        label: "Description",
        display: false,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Description",
        type: "textarea",
        step: 2,
        value: null,
        required: false,
      },
    }
  }
}
