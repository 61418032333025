var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formLabel)?_c('div',{staticClass:"form-field-label"},[_vm._v(_vm._s(_vm.formLabel)+" Details")]):_vm._e(),_vm._v(" "),(_vm.input && _vm.form)?_c('div',_vm._l((_vm.formSteps),function(v,k){return _c('div',{key:k,staticClass:"form-field-wrap",style:(v.formStyle ? v.formStyle() : ''),on:{"change":_vm.checkForm}},_vm._l((Array.from({ length: _vm.stepCount }, function (x, i) { return i + 1; })),function(step){return _c('div',{key:step,staticClass:"form-step",class:{
          'form-field-hidden': step !== _vm.currentStep,
          'step-select': v.type == 'select' || v.type == 'date',
        }},[(v.step == step && v.label)?_c('div',{staticClass:"form-field-label",class:{ 'form-field-hidden': step !== _vm.currentStep }},[_vm._v("\n          "+_vm._s(v.label)+"\n        ")]):_vm._e(),_vm._v(" "),(
            v.step == step &&
            ['array', 'text', 'number', 'email', 'password'].includes(v.type)
          )?_c('input',{ref:k,refInFor:true,staticClass:"form-field",class:{
            'form-field-hidden': step !== _vm.currentStep,
            'invalid': _vm.invalids[k],
          },attrs:{"disabled":_vm.postLoading || v.disabled || (_vm.scanner && _vm.scanner.scanning),"name":k,"required":v.required,"type":v.type,"placeholder":v.placeholder},domProps:{"value":_vm.input[k]},on:{"input":function (value) { return _vm.handleChange(value, k); },"blur":function($event){return _vm.handleBlur(k)}}}):_vm._e(),_vm._v(" "),(v.step == step && k.toLowerCase().match('password'))?_c('span',{staticClass:"pw-icon",class:{ 'form-field-hidden': step !== _vm.currentStep },domProps:{"innerHTML":_vm._s(v.type == 'text' ? _vm.pwShown() : _vm.pwHidden())},on:{"click":function($event){v.type = v.type == 'password' ? 'text' : 'password'}}}):_vm._e(),_vm._v(" "),(v.step == step && ['date'].includes(v.type))?_c('input',{ref:k,refInFor:true,staticClass:"form-field",class:{
            'form-field-hidden': step !== _vm.currentStep,
            'invalid': _vm.invalids[k],
          },attrs:{"disabled":_vm.postLoading,"name":k,"required":v.required,"type":v.type,"placeholder":v.placeholder},domProps:{"value":_vm.input[k]},on:{"input":function (value) { return _vm.handleChange(value, k); },"blur":function($event){return _vm.handleBlur(k)}}}):_vm._e(),_vm._v(" "),(v.type == 'file')?_c('div',[(_vm.input[k].src)?_c('div',{staticClass:"image-preview"},[_c('img',{staticClass:"file-preview",attrs:{"src":_vm.input[k].src}})]):(_vm.input[k])?_c('div',{staticClass:"image-preview"},[_c('img',{staticClass:"file-preview",attrs:{"src":_vm.input[k]}})]):_vm._e(),_vm._v(" "),(v.step == step && ['file'].includes(v.type))?_c('input',{ref:k,refInFor:true,staticClass:"form-field",class:{
              'form-field-hidden': step !== _vm.currentStep,
              'invalid': _vm.invalids[k],
            },attrs:{"disabled":_vm.postLoading,"name":k,"accept":_vm.acceptFileList(),"required":v.required,"type":v.type,"placeholder":v.placeholder},on:{"input":function (value) { return _vm.handleChange(value, k); }}}):_vm._e()]):_vm._e(),_vm._v(" "),(v.step == step && v.type == 'textarea')?_c('textarea',{ref:k,refInFor:true,staticClass:"form-field",class:{
            'form-field-hidden': step !== _vm.currentStep,
            'invalid': _vm.invalids[k],
          },attrs:{"disabled":_vm.postLoading,"name":k,"required":v.required,"type":"text","placeholder":v.placeholder},domProps:{"value":_vm.input[k]},on:{"input":function (value) { return _vm.handleChange(value, k); }}}):_vm._e(),_vm._v(" "),(v.step == step && v.type == 'select')?_c('select',{ref:k,refInFor:true,staticClass:"form-field",class:{
            'form-field-hidden': step !== _vm.currentStep,
            'invalid': _vm.invalids[k],
          },attrs:{"disabled":_vm.postLoading,"name":k,"required":v.required},domProps:{"value":_vm.input[k]},on:{"input":function (value) { return _vm.handleChange(value, k); },"blur":function($event){return _vm.handleBlur(k)}}},[(v.placeholder)?_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("\n            "+_vm._s(v.placeholder)+"\n          ")]):_vm._e(),_vm._v(" "),_vm._l((v.options),function(o,p){return _c('option',{key:o,domProps:{"value":o}},[_vm._v("\n            "+_vm._s(p
                .split(" ")
                .map(function (x) { return (x[0] ? x[0].toUpperCase() + x.substr(1) : x); })
                .join(" "))+"\n          ")])})],2):_vm._e(),_vm._v(" "),(v.step == step && v.type == 'multi-checkbox')?_c('div',{staticClass:"multi-check"},_vm._l((v.options),function(option,name){return _c('div',{key:JSON.stringify(option),staticClass:"form-option-box",class:{ 'form-option-selected': _vm.input[k].includes(option) }},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input[k]),expression:"input[k]"}],attrs:{"type":"checkbox"},domProps:{"value":option,"checked":Array.isArray(_vm.input[k])?_vm._i(_vm.input[k],option)>-1:(_vm.input[k])},on:{"change":function($event){var $$a=_vm.input[k],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.input, k, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.input, k, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.input, k, $$c)}}}}),_vm._v(" "),_c('div',{staticClass:"form-option-text"},[_vm._v("\n                "+_vm._s(name)+"\n              ")]),_vm._v(" "),(v.nested && _vm.input[v.nested])?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input[v.nested][option]),expression:"input[v.nested][option]"}],staticClass:"form-option-nested",attrs:{"placeholder":_vm.input[v.nested].placeholder,"step":"5","type":"number"},domProps:{"value":(_vm.input[v.nested][option])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.input[v.nested], option, $event.target.value)}}}):_vm._e()])])}),0):_vm._e()])}),0)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }