<template>
  <form @submit.prevent="handleSubmit" class="step-text-field">
    <input
      v-if="active"
      @keyup="updateValue"
      :value="data[target]"
      :placeholder="placeholder"
      class="text-field"
      type="text"
    />

    <input
      v-if="!active"
      :value="data[target]"
      :placeholder="placeholder"
      class="text-field"
      type="text"
    />

    <button class="text-field-submit" :disabled="!data[target]"></button>
  </form>
</template>

<script>
import { EventBus } from "@/event-bus"
export default {
  name: "TextField",
  props: {
    handler: {
      type: Function,
      default: () => {},
    },

    data: {
      type: Object,
      default: {},
    },

    active: {
      type: Boolean,
      default: false,
    },

    target: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },
  },

  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
        document.querySelector(".step-active").scroll({
          top: 100,
          left: 0,
          behavior: "smooth",
        })
      },
    },
  },

  methods: {
    updateValue(e) {
      EventBus.$emit("input", this.target, e.target.value)
    },

    handleSubmit() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
      this.handler()
    },
  },
}
</script>

<style scoped>
form.step-text-field {
  display: grid;
  max-width: 375px;
  margin: 0 auto 0 auto;
  padding: 12px;
  grid-template-areas: "input submit";
  background: #ffffff;
  border: 2px solid #25a3d9;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
}

@media (max-width: 640px) {
  form.step-text-field {
    width: 100% !important;
    margin: 0 auto;
  }
}

.text-field:focus {
  outline: none;
}

.text-field-submit {
  grid-area: submit;
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 4px;
  border: 1px solid #25a3d9;
  border-radius: 16px;
  color: white;

  background-position: center;
  background-repeat: no-repeat;
  background-color: #25a3d9;
  background-image: url("~@/assets/images/forward-lite.svg");
}

.text-field-submit:disabled {
  background-color: #8c8c8c;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/forward-lite.svg");
  border: 1px solid #8c8c8c;
}

.text-field {
  grid-area: input;
  padding-left: 6px;
  height: 32px;
  border: none;
  font-family: "brandon-grotesque";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.04em;
  width: calc(100% - 24px);
}
</style>
