<template>
  <div>
    <div class="provider" v-if="data && data.pccProvider">
      <div class="avatar">
        <div class="provider-img-container">
          <div
            class="provider-img"
            :style="getImgStyling(data.pccProvider.providerImageSrc)"
          ></div>
        </div>
      </div>

      <div class="provider-info-container">
        <div class="provider-header">Meet {{ firstName }}!</div>

        <div class="provider-text-container">
          <div class="provider-text">
            {{ firstName }} will be your Patient Care<br />Coordinator, your
            dedicated resource before,<br />during and after your surgery
            journey.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetPcc",
  props: ["data"],

  methods: {
    getImgStyling(imgSrc) {
      if (imgSrc) {
        return {
          "background-image": `url(${imgSrc})`,
        }
      }
    },
  },

  computed: {
    firstName() {
      return this.data.pccProvider.name.split(" ")[0]
    },
  },
}
</script>

<style scoped>
.name {
  color: #25a3d9;
  font-weight: bold;
  display: inline-block;
}
.provider-text-container {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #4d4d4d;
  margin: 16px auto;
}

.provider-text {
  margin: 24px auto;
}

.provider-img-container {
  width: 120px;
  height: 120px;
  position: relative;
  display: inline-block;
}

.provider-info-container {
  display: inline-block;
  width: 100%;
}

.provider-img {
  width: 120px;
  height: 120px;
  background-size: 100%;
  border-radius: 100px;
  background-position: center center;
  background-image: url("~@/assets/images/logo.svg");
  /*background-color: orange;*/
  background-repeat: no-repeat;
}

.provider-header {
  margin-top: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
}

.provider {
  width: 328px;
  margin: auto;
}
</style>
