<template>
  <div class="float" :class="msg.action">
    <div class="content">
      <div v-if="msg.count" class="count" :class="'count-' + msg.action">
        {{ msg.count }}
      </div>

      <div class="text">
        <div class="text-field">
          {{ msg.field }}
        </div>
        <div class="text-action">{{ msg.action }}.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatMsg",
  props: ["msg", "err", "callback"],

  mounted() {
    console.log(this.msg)
    setTimeout(this.callback, 2000)
  },
}
</script>

<style>
.text-field {
  font-weight: bold;
  display: inline-block;
}

.text-action {
  display: inline-block;
}

.float {
  display: inline-flex;
  width: 600px;
  position: fixed;
  background: #25d97f;
  color: white;
  height: 47px;
  line-height: 47px;
  border-radius: 4px;

  margin: 0 auto;
  bottom: 16px;
  left: 0;
  right: 0;
}

@media (max-width: 640px) {
  .float {
    width: 380px;
  }
}

.deleted {
  background: #d45252;
}

.err {
  background: #d45252;
}

.count {
  display: flex;
  position: relative;
  top: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  color: #25d97f;
  background: #ffffff;
  border-radius: 16px;

  margin-right: 8px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
}

.count-deleted {
  color: #d45252;
}

.content {
  margin-left: 16px;
  display: flex;
}
</style>
