<template>
  <Inventory :pager-config="pagerConfig" :models="models" />
</template>

<script>
import Inventory from "@/views/Inventory"

export default {
  name: "admin",
  props: ["models", "pagerConfig"],
  components: {
    Inventory,
  },
}
</script>

<style>
.profile {
  margin-top: 86px;
}
</style>
