<template>
  <PracticeSettings :pager-config="pagerConfig" :models="models" />
</template>

<script>
import PracticeSettings from "@/views/PracticeSettings"

export default {
  name: "practice_admin",
  props: ["models", "pagerConfig"],
  components: {
    PracticeSettings,
  },
}
</script>

<style>
.profile {
  margin-top: 86px;
}
</style>
