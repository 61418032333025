<template>
  <div class="api">
    <div class="api-content">
      <div class="input">
        <div class="input-container">
          <div class="input-header">Endpoint Path</div>
          <input v-model="path" placeholder="Proxy Addr" type="text" />
        </div>

        <div class="buttons">
          <div
            :style="{
              background: statusCode == (200 || 201) ? 'green' : 'red',
            }"
            v-if="statusCode"
            class="status-code"
          >
            {{ statusCode }}
          </div>

          <button
            :class="{ 'loading-button': loading }"
            :disabled="loading || !headersValid"
            @click.prevent="fetchData"
          >
            {{ loading ? "..." : "GET" }}
          </button>

          <button
            :class="{ 'loading-button': loading }"
            :disabled="loading || !payloadValid || !headersValid"
            @click.prevent="postData"
          >
            {{ loading ? "..." : "POST" }}
          </button>

          <button
            :class="{ 'loading-button': loading }"
            :disabled="loading || !payloadValid || !headersValid"
            @click.prevent="putData"
          >
            {{ loading ? "..." : "PUT" }}
          </button>
        </div>

        <div class="input-container">
          <label for="checkbox">
            <input v-model="includeAuthHeaders" type="checkbox" />
            include auth headers?
          </label>
        </div>

        <div class="input-container">
          <div
            class="textarea-header"
            :class="{ 'invalid-payload': !payloadValid }"
          >
            POST Request Payload
          </div>

          <textarea
            id="payload"
            :class="{ 'invalid-payload': !payloadValid }"
            class="payload"
            v-model="payload"
          >
          </textarea>
        </div>

        <div class="input-container">
          <div
            class="textarea-header"
            :class="{ 'invalid-payload': !headersValid }"
          >
            HEADERS
          </div>

          <textarea
            id="headers"
            :class="{ 'invalid-payload': !headersValid }"
            class="headers"
            v-model="headers"
          >
          </textarea>
        </div>

        <div v-if="error" class="error">
          {{ error }}
        </div>
      </div>

      <div v-if="response" class="results">
        <pre>
                    <code>
                        {{JSON.stringify(response, null, 2)}}
                    </code>
                </pre>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import config from "@/../config.json"
const backendUrl = config.backend_url[process.env.ENV]

export default {
  name: "api",
  data() {
    return {
      response: null,
      loading: false,
      path: `${backendUrl}/api/proxy/nt/Patient`,
      error: null,
      statusCode: null,
      payload: "{}",
      headers: "{}",
      payloadValid: true,
      headersValid: true,
      includeAuthHeaders: true,
    }
  },

  mounted() {
    var el = document.getElementById("payload")
    el.onkeydown = function (e) {
      if (e.keyCode === 9) {
        // tab was pressed

        // get caret position/selection
        var val = this.value,
          start = this.selectionStart,
          end = this.selectionEnd

        // set textarea value to: text before caret + tab + text after caret
        this.value = val.substring(0, start) + "\t" + val.substring(end)

        // put caret at right position again
        this.selectionStart = this.selectionEnd = start + 1

        // prevent the focus lose
        return false
      }
    }
  },

  methods: {
    async postData() {
      this.loading = true
      this.response = null
      this.statusCode = null
      this.error = null
      let headers = JSON.parse(this.headers)

      const accessToken = await this.$auth.getTokenSilently()

      console.log(this.payload)

      await axios
        .post(this.path, this.payload, {
          headers: this.includeAuthHeaders
            ? {
                authorization: `bearer ${accessToken}`,
              }
            : headers,
        })
        .then((resp) => {
          this.response = resp.data
          this.loading = false
          this.statusCode = resp.status
        })
        .catch((e) => {
          this.error = e
          this.statusCode = e.response.status
          this.response = e.response.data
          this.loading = false
        })

      console.log(this.response)
    },

    async putData() {
      this.loading = true
      this.response = null
      this.statusCode = null
      this.error = null
      let headers = JSON.parse(this.headers)

      const accessToken = await this.$auth.getTokenSilently()

      console.log(this.payload)

      await axios
        // .put(this.path, this.payload.replace(/\s/g, ""), {
        .put(this.path, JSON.stringify(this.payload), {
          headers: this.includeAuthHeaders
            ? {
                authorization: `bearer ${accessToken}`,
              }
            : headers,
        })
        .then((resp) => {
          this.response = resp.data
          this.loading = false
          this.statusCode = resp.status
        })
        .catch((e) => {
          this.error = e
          this.statusCode = e.response.status
          this.response = e.response.data
          this.loading = false
        })

      console.log(this.response)
    },

    async fetchData() {
      this.loading = true
      this.response = null
      this.statusCode = null
      this.error = null
      let headers = JSON.parse(this.headers)

      const accessToken = await this.$auth.getTokenSilently()

      await axios
        .get(this.path, {
          headers: this.includeAuthHeaders
            ? {
                authorization: `bearer ${accessToken}`,
              }
            : headers,
        })
        .then((resp) => {
          this.loading = false
          this.response = resp.data
          this.statusCode = resp.status
        })
        .catch((e) => {
          this.loading = false
          this.error = e
          this.response = e.response.data
          this.statusCode = e.response.status
        })
    },
  },

  watch: {
    payload() {
      this.error = null
      this.payloadValid = true

      try {
        JSON.parse(this.payload)
      } catch (e) {
        this.payloadValid = false
        this.error = e.message

        return
      }
    },
    headers() {
      this.error = null
      this.headersValid = true

      try {
        JSON.parse(this.headers)
      } catch (e) {
        this.headersValid = false
        this.error = e.message

        return
      }
    },
  },
}
</script>

<style scoped>
.api {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 86px auto 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.input-container {
  width: 100%;
  margin: 0 auto 16px auto;
}

.input input[type="text"] {
  width: 100%;
  font-size: 16px;
  height: 32px;
  border-radius: 0 0 6px 6px;
  box-sizing: border-box;
  border: none;
  text-indent: 16px;
}

.input label {
  display: inline;
  color: white;
}

.input-header {
  font-size: 14px;
  font-weight: bold;
  line-height: 32px;
  width: 100%;
  height: 32px;
  color: #8c8c8c;
  background: white;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #f1f2f1;
  text-indent: 16px;
}

.input button {
  text-transform: uppercase;
  font-size: 16px;
  height: 32px;
  padding: 0 16px 0 16px;
  border-radius: 6px;
  border: none;
  color: white;
  background: #25a3d9;
  display: inline-block;
}

.input {
  width: 100%;
  padding: 0;
  text-align: left;
}

.results {
  resize: vertical;
  overflow: scroll;
  text-align: left;
  background: #eae4de;
  color: #8c8c8c;
  height: 55vh;
  width: 100%;
  position: relative;
  border-radius: 6px;
  border: none;
  font-weight: bold;
}

button.loading-button,
button:disabled {
  background: grey !important;
}

.buttons {
  width: 100%;
  text-align: right;
  margin-bottom: 16px;
}

.textarea-header {
  font-size: 14px;
  font-weight: bold;
  line-height: 32px;
  width: 100%;
  height: 32px;
  background: #54575a;
  color: #f1f2f1;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #8c8c8c;
  text-indent: 16px;
}

textarea {
  padding: 16px 0 0 16px !important;
  font-size: 16px;
  width: calc(100% - 16px);
  resize: vertical;
  background: #54575a;
  color: #f1f2f1;
  border-radius: 0 0 6px 6px;
  border: none;
  display: block;
}

.error {
  color: #d94325;
  background: #d552523d;
  border-radius: 6px;
  height: 32px;
  line-height: 32px;
  margin-top: 8px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

label {
  margin-bottom: 6px;
  display: block;
  color: #54575a;
}

.status-code {
  border-radius: 6px;
  display: block;
  float: left;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 32px;
  height: 32px;
  padding: 0 16px 0 16px;
  border: none;
  color: white;
  background: #8c8c8c;
}

.invalid-payload {
  background: #d94325;
}

.api-content {
  margin: 0 auto 0 auto;
  border-radius: 6px;
  max-width: 800px;
  padding: 16px;
  background: #1a1a1a;
}
</style>
