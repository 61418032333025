<template>
  <div
    class="page-menu"
    :class="{ 'toggle-style': config && config.style == 'toggle' }"
  >
    <div v-if="config && config.style == 'toggle'">
      <div class="toggle-row">
        <div
          @click="() => updatePage(page)"
          :class="{ 'toggle-active': currentPage == page }"
          class="toggle-options"
          v-for="page in Object.keys(pages)"
        >
          {{ page }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="current-row">
        <div
          v-if="Object.keys(pages).length > 1"
          @click="() => updatePage('prev')"
          class="left-arrow"
        >
          <div class="chevron" v-html="chevronLeft()"></div>
        </div>

        <div
          class="page-menu-heading"
          :class="{ 'show-pointer': Object.keys(pages).length > 1 }"
          @click="showPagerDrop = !showPagerDrop"
        >
          <div class="page-menu-text">
            {{ currentPage }}
          </div>

          <div
            v-html="caretClosed()"
            v-if="showPagerDrop && Object.keys(pages).length > 1"
            class="page-menu-caret"
          ></div>

          <div
            v-html="caretOpened()"
            v-if="!showPagerDrop && Object.keys(pages).length > 1"
            class="page-menu-caret"
          ></div>
        </div>

        <div
          v-if="Object.keys(pages).length > 1"
          @click="() => updatePage('next')"
          class="right-arrow"
        >
          <div class="chevron" v-html="chevronRight()"></div>
        </div>
      </div>

      <div class="page-menu-options">
        <transition name="slide">
          <div
            class="page-menu-option-container"
            v-if="showPagerDrop && Object.keys(pages).length > 1"
          >
            <div
              v-for="(k, v) in Object.keys(pages).filter(
                (x) => x != currentPage
              )"
              :style="{ order: Object.keys(pages).indexOf(k) }"
              @click="() => updatePage(k)"
              class="page-menu-option"
            >
              {{ k }}
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  caretOpened,
  caretClosed,
  chevronLeft,
  chevronRight,
} from "@/utils/svg"

export default {
  name: "pager",
  props: ["pages", "config"],
  data() {
    return {
      chevronLeft: chevronLeft,
      chevronRight: chevronRight,
      caretClosed: caretClosed,
      caretOpened: caretOpened,
      currentPage: "",
      showPagerDrop: false,
    }
  },

  mounted() {
    this.currentPage = Object.keys(this.pages)[0]
    this.$emit("update:page", this.pages[this.currentPage])
  },

  methods: {
    updatePage(dir) {
      if (dir == "prev") {
        this.setPrev()
      } else if (dir == "next") {
        this.setNext()
      } else {
        this.currentPage = dir
      }

      this.showPagerDrop = false
      this.$emit("update:page", this.pages[this.currentPage])
    },

    setPrev() {
      let pages = Object.keys(this.pages)
      let currentIndex = pages.indexOf(this.currentPage)

      this.currentPage = pages[0]

      if (currentIndex == 0) {
        this.currentPage = pages[pages.length - 1]
      } else {
        this.currentPage = pages[currentIndex - 1]
      }
    },

    setNext() {
      let pages = Object.keys(this.pages)
      let currentIndex = pages.indexOf(this.currentPage)
      this.currentPage = pages[0]

      if (currentIndex < pages.length - 1) {
        this.currentPage = pages[currentIndex + 1]
      } else {
        this.currentPage = pages[0]
      }
    },
  },
}
</script>

<style>
.page-menu {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  width: 600px;
}

.page-menu-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  position: static;
  width: 235px;
  height: 54px;

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 10px;
}

.page-menu-options {
  display: block;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.page-menu-option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 54px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}

.page-menu-option-container {
  margin: auto;
  background: #f1f2f1;
  width: 600px;

  transition: all 0.3s ease-in-out;
  position: absolute;
  width: 100%;
  z-index: 0;
  top: -86px;
  padding-top: 86px;
  border-radius: 0 0 6px 6px;

  box-shadow: 0 1px 0px 0px black, 0 0px 0px 0px black, 1px 0 1px 0px black,
    -1px 0 1px 0px black;
}

.page-menu-caret {
  width: 16px;
  height: 16px;

  align-items: center;
  display: flex;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}

.current-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 0;

  height: 54px;

  background: #f1f2f1;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}

.toggle-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 100%;

  position: static;
  height: 54px;
  left: 16px;
  top: 16px;

  /* Soft Gray */

  background: #f1f2f1;
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px auto 0px auto;
}

.toggle-active {
  background: rgba(37, 163, 217, 0.1);
  border: 1px solid #25a3d9;
  border-radius: 4px;
  color: #25a3d9 !important;
  font-weight: bold;
  cursor: auto !important;
}

.toggle-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;

  cursor: pointer;
  color: #8c8c8c;

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 1px;
}

.toggle-options:hover {
  opacity: 0.7;
}

.left-arrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 54px;
  height: 54px;

  border-radius: 4px 0px 0px 4px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.show-pointer {
  cursor: pointer;
}

.right-arrow:hover,
.left-arrow:hover,
.page-menu-option:hover {
  cursor: pointer;
}

.page-menu-option:hover {
  background: #8c8c8c;
  color: white;
}

.page-menu-option:last-child {
  border-radius: 0px 0px 6px 6px;
}

.right-arrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 54px;
  height: 54px;

  border-radius: 0px 4px 4px 0px;

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 0px;
}

.chevron {
  width: 16px;
  height: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.inventory {
  background: white;
  margin-bottom: 16px;
  display: block;
  min-height: 100vh;
}

.slide-enter,
.slide-leave-active {
  top: -200px;
  z-index: -1;
  box-shadow: none;
}

.slide-enter-active {
  box-shadow: none;
}

.slide-enter-to .page-menu-option:hover,
.slide-leave-to .page-menu-option:hover {
  background: unset;
  z-index: -1;
}

.no-box-shadow {
  box-shadow: none;
}

@media (max-width: 640px) {
  .page-menu {
    width: 100%;
  }

  .toggle-style {
    width: 360px;
  }

  .page-menu-option-container {
    width: 100%;
    left: unset;
  }

  .toggle-row {
    /*width: 96%;*/
  }
}
</style>
