export default class Scanner {
  constructor() {
    this.buffer = '';
    this.type = null;
    this.scanning = false;
  }

  parseData() {
    if (this.buffer.match(/^21\d+\]$/)) {
      console.log('serial found');

      this.buffer = this.buffer.slice(2, -1);
      this.type = 'serialNumber';
    } else if (this.buffer.match(/^240\d+-\d+\w+\]$/)) {
      console.log('ref found');

      this.buffer = this.buffer.slice(3, -1);
      this.type = 'referenceNumber';
    } else {
      console.log('no valid data found');
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }

    if (event.key === '[' && !this.scanning) {
      console.log('scan beginning');

      this.buffer = '';
      this.type = null;
      this.scanning = true;

      setTimeout(() => {
        console.log('in timeout');

        this.buffer = '';
        this.type = null;
        this.scanning = false;
      }, 500);
    } else if (this.scanning) {
      this.buffer = this.buffer + event.key.toUpperCase();
    }
  }
}
