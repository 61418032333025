<template>
  <div class="table reminders">
    <transition name="fade">
      <NewForm
        v-if="showNewForm && model.toolConfig.newForm"
        :model="model"
        :config="model.toolConfig"
        :post-loading="postLoading"
        :type="model.type"
        @toggle-click="handleAddClick($event)"
      />
    </transition>

    <transition name="fade">
      <UpdateForm
        v-if="showUpdateForm && model.toolConfig.updateForm"
        :model="model"
        :selected="selected"
        :post-loading="postLoading"
        :config="model.toolConfig"
        :type="model.type"
        :identifier="
          model.selectActions
            ? model.selectActions()['update']['identifier']
            : null
        "
        @toggle-click="handleUpdateToggle($event)"
      />
    </transition>

    <TableTools
      :data="tableData"
      :config="config"
      :type="model.type"
      @add-click="handleAddClick($event)"
      v-model="filter"
    />

    <div class="row table-head">
      <div
        class="cell header"
        :key="k"
        v-for="(v, k) in model.tableFields()"
        :style="{
          width:
            'calc(96%/' + (Object.keys(model.tableFields()).length + 1) + ')',
          order: Object.keys(model.tableFields()).indexOf(k),
          justifyContent: getJustfication(k),
        }"
      >
        <div class="key" @click="sortDataBy(k)">
          {{ v.header }}
        </div>

        <div
          v-html="sortIcon()"
          v-if="k == activeSort"
          class="sort-active"
        ></div>
      </div>
    </div>

    <transition-group name="list-complete" tag="div">
      <div
        v-for="row in tableData"
        @click="toggleSelected(row)"
        :class="{ selected: selected.includes(row) }"
        class="row list-complete-item"
        :key="JSON.stringify(row)"
      >
        <div
          class="cell"
          :key="k"
          :style="{
            width:
              'calc(96%/' + (Object.keys(model.tableFields()).length + 1) + ')',
            order: Object.keys(model.tableFields()).indexOf(k),
            justifyContent: getJustfication(k),
          }"
          v-for="(v, k) in model.tableFields()"
          :data-title="v.label"
        >
          <div :class="[v.klass, 'key']" :style="v.styler ? v.styler(row) : ''">
            <!-- {{
              v.formatter
                ? v.formatter(row, extractField(row, k))
                : extractField(row, k)
            }} -->
            <span
              v-if="v.formatter"
              v-html="v.formatter(row, extractField(row, k))"
            ></span>
            <span v-else>{{ extractField(row, k) }}</span>
            <div class="tooltip-content" v-if="v.klass == 'tooltip'">
              {{
                v.formatter
                  ? v.formatter(row, extractField(row, k))
                  : extractField(row, k)
              }}
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import TableTools from "@/components/TableTools"
import { sort } from "@/utils/svg"
import NewForm from "@/components/NewForm"
import FloatTool from "@/components/FloatTool"
import DeletesConfirm from "@/components/DeletesConfirm"
import UpdateForm from "@/components/UpdateForm"
import { EventBus } from "@/event-bus"

export default {
  name: "ReminderTable",
  props: ["loading", "postLoading", "model"],
  components: {
    NewForm,
    FloatTool,
    DeletesConfirm,
    UpdateForm,
    TableTools,
  },

  data() {
    return {
      input: [],
      selected: [],
      config: {},
      sortIcon: sort,
      sortAsc: true,
      activeSort: "",
      filter: "",
      filteredData: [],
      showNewForm: false,
      showUpdateForm: false,
      showDeletesConfirm: false,
    }
  },

  mounted() {
    this.input = this.model.data
    this.config = this.model.toolConfig

    EventBus.$on("submit-new", this.handleSubmitNew)
    EventBus.$on("submit-update", this.handleSubmitUpdate)
    EventBus.$on("submit-delete", this.handleSubmitDelete)
  },

  destroyed() {
    console.log("Usertable destroyed")
    EventBus.$off("submit-new")
    EventBus.$off("submit-update")
    EventBus.$off("submit-delete")
  },

  methods: {
    async handleSubmitNew(e) {
      await this.model.postData(e)
      this.showNewForm = !this.showNewForm
      this.selected = []

      EventBus.$emit("reset", true)
      EventBus.$emit("msg", this.model.msg)
    },

    async handleSubmitUpdate(e) {
      console.log("handleSubmit: " + e)
      await this.model.putData(e)
      this.showUpdateForm = !this.showUpdateForm
      this.selected = []

      EventBus.$emit("reset", true)
      EventBus.$emit("msg", this.model.msg)
    },

    async handleSubmitDelete(e) {
      await this.model.deleteData(e)
      this.showUpdateForm = !this.showUpdateForm
      this.selected = []

      EventBus.$emit("reset", true)
      EventBus.$emit("msg", this.model.msg)
    },

    toggleSelected: function(row) {
      if (row) {
        if (this.config.multiUpdate) {
          if (this.selected.includes(row)) {
            this.selected = this.selected.filter(x => x !== row)
          } else {
            this.selected.push(row)
          }
        }

        if (!this.config.multiUpdate) {
          if (this.selected.includes(row)) {
            this.selected = []
          } else {
            this.selected.push(row)
          }

          this.showUpdateForm = !this.showUpdateForm
        }

        this.$emit("selected", this.selected)
      }
    },

    handleAddClick: function() {
      this.showNewForm = !this.showNewForm
    },

    handleUpdateToggle: function() {
      this.selected = this.config.multiUpdate ? this.selected : []
      this.showUpdateForm = !this.showUpdateForm
    },

    getJustfication: function(key) {
      let headers = Object.keys(this.model.tableFields())
      let len = headers.length
      let index = headers.indexOf(key)

      if (index === 0) {
        return "left"
      } else if (index === len - 1) {
        return "left"
      } else {
        return "left"
      }
    },

    sortDataBy: function(key) {
      this.activeSort = key
      this.sortAsc = !this.sortAsc

      this.input = this.input.sort((a, b) =>
        this.sortAsc
          ? this.extractField(a, key).localeCompare(this.extractField(b, key))
          : this.extractField(b, key).localeCompare(this.extractField(a, key))
      )
    },

    extractField: function(row, field) {
      if (field === "provider") {
        console.log("PROVIDER: " + row[field].name)
      }
      if (Array.isArray(field)) {
        let vals = []

        for (var v in field) {
          vals.push(this.extractField(row, field[v]))
        }

        return vals.join(" ")
      } else if (
        this.model.tableFields()[field] &&
        this.model.tableFields()[field].fields
      ) {
        let values = this.model.tableFields()[field].fields

        return this.extractField(row, values)
      } else {
        if (!field.match(/\./)) {
          return row[field]
        } else {
          let fields = field.split(".")
          let val = row

          for (var f in fields) {
            if (val && val[fields[f]]) {
              val = val[fields[f]]
            } else {
              return "No Data"
            }
          }

          return val
        }
      }
    },

    applyFilter: function() {
      this.filteredData = this.input.filter(x => {
        let search = this.filter.toLowerCase().split(" ")
        let data = JSON.stringify(x).toLowerCase()

        for (var s in search) {
          if (!data.includes(search[s])) {
            return false
          }
        }
        return true
      })
    },
  },

  computed: {
    tableData: function() {
      if (this.activeSort) {
        let prop = this.activeSort

        if (!this.filter) {
          return this.input.sort((a, b) =>
            this.sortAsc
              ? this.extractField(a, prop).localeCompare(
                  this.extractField(b, prop)
                )
              : this.extractField(b, prop).localeCompare(
                  this.extractField(a, prop)
                )
          )
        } else {
          return this.filteredData.sort((a, b) =>
            this.sortAsc
              ? this.extractField(a, prop).localeCompare(
                  this.extractField(b, prop)
                )
              : this.extractField(b, prop).localeCompare(
                  this.extractField(a, prop)
                )
          )
        }
      } else {
        return this.model.data
      }
    },
  },

  watch: {
    filter() {
      if (this.filter) {
        this.applyFilter()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  justify-content: center;

  width: 96% !important;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px auto 48px;
}

.row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: auto;

  background: #f1f2f1;
  border: 1px solid #f1f2f1;
  box-sizing: border-box;
  border-radius: 4px;

  cursor: pointer;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: 6px auto 0;
}

.selected {
  background: #cbcccb !important;
}

.cell {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  text-transform: uppercase;

  color: #1f1f1f;

  flex: none;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;

  height: auto;
}

.header {
  font-weight: bold;
  text-transform: none;
  color: #54575a;
  font-size: 12px;
  line-height: 15px;
}

.key {
  cursor: pointer;
  padding: 10px;
}

.sort-active {
  display: contents;
  position: absolute;
}

.pill {
  padding: 4px;
  min-width: 56px;
  color: white;

  font-weight: bold;
  background: #8c8c8c;
  border-radius: 4px;
}

.used,
.ordered {
  background: #54575a;
}

div {
  padding: 0;
  margin: auto;
  width: 100%;
}

.list-complete-item {
  transition: all 0.5s ease;
}

.list-complete-enter-from,
.list-complete-leave-to {
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
  bottom: 0;
  box-shadow: none;
}

.no-transform {
  text-transform: none;
}

.no-box-shadow {
  box-shadow: none;
}

@media (max-width: 800px) {
  .table {
    width: 600px;
  }
}

@media (max-width: 640px) {
  .table {
    width: 360px;
  }
}

.fade-enter-active,
.fade-leave-active {
  z-index: 1500;
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  z-index: 1500;
  opacity: 0;
}

.scan-buffer {
  left: -999rem;
  position: absolute;
}

.tooltip {
  white-space: nowrap;
  text-transform: lowercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-content {
  display: none;
}

.cell:hover .tooltip-content {
  background: #1496bb;
  color: #fff;
  display: block;
  height: content;
  width: 200px;
  text-align: left;
  padding: 20px;
  white-space: initial;
  left: 40%;
  position: absolute;
  border-radius: 4px;
  font-size: 16px;
  transform: translateY(10px);
  transition: all 0.25s ease-out;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.reminders.table .multi-check {
  max-height: 250px;
}

.table.reminders {
  .row.table-head {
    & + div {
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .table.reminders {
    width: 100%;

    .search-row {
      margin: 0 0 15px;
      width: 100%;
    }

    .row.table-head {
      display: none !important;

      & + div {
        width: 100%;
      }
    }

    .row.list-complete-item {
      flex-direction: column;
      width: 100%;
      height: auto;
      margin-bottom: 15px;
      padding: 10px 5px;

      &:hover {
        background: #dfdfdf;
      }
    }
    .cell {
      display: flex;
      width: 100% !important;
      justify-content: left;
      height: auto;
      padding: 8px 15px;
      box-sizing: border-box;

      .key {
        line-height: 1.5;
        text-align: left !important;
      }

      &:before {
        content: attr(data-title) ": ";
        font-weight: bold;
        margin-right: 5px;
        line-height: 1.5;
        width: auto;
        flex: none;
        align-self: center;
      }
    }
  }
}
</style>
