var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showNewForm && _vm.model.toolConfig.newForm)?_c('NewForm',{attrs:{"model":_vm.model,"config":_vm.model.toolConfig,"post-loading":_vm.postLoading,"type":_vm.model.type},on:{"toggle-click":function($event){return _vm.handleAddClick($event)}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showUpdateForm && _vm.model.toolConfig.updateForm)?_c('UpdateForm',{attrs:{"model":_vm.model,"selected":_vm.selected,"post-loading":_vm.postLoading,"config":_vm.model.toolConfig,"type":_vm.model.type,"identifier":_vm.model.selectActions
          ? _vm.model.selectActions()['update']['identifier']
          : null},on:{"toggle-click":function($event){return _vm.handleUpdateToggle($event)}}}):_vm._e()],1),_vm._v(" "),_c('TableTools',{attrs:{"data":_vm.tableData,"config":_vm.config,"type":_vm.model.type},on:{"add-click":function($event){return _vm.handleAddClick($event)}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.model.tableFields()),function(v,k){return _c('div',{key:k,staticClass:"cell header",style:({
        order: Object.keys(_vm.model.tableFields()).indexOf(k),
        justifyContent: _vm.getJustfication(k),
      })},[_c('div',{staticClass:"key",on:{"click":function($event){return _vm.sortDataBy(k)}}},[_vm._v("\n        "+_vm._s(v.header)+"\n      ")]),_vm._v(" "),(k == _vm.activeSort)?_c('div',{staticClass:"sort-active",domProps:{"innerHTML":_vm._s(_vm.sortIcon())}}):_vm._e()])}),0),_vm._v(" "),_c('transition-group',{attrs:{"name":"list-complete","tag":"ul"}},_vm._l((_vm.tableData),function(row){return _c('div',{key:JSON.stringify(row),staticClass:"row list-complete-item",class:{ selected: _vm.selected.includes(row) },on:{"click":function($event){return _vm.toggleSelected(row)}}},_vm._l((_vm.model.tableFields()),function(v,k){return _c('div',{key:k,staticClass:"cell",style:({
          width:
            'calc(95%/' + (Object.keys(_vm.model.tableFields()).length + 1) + ')',
          order: Object.keys(_vm.model.tableFields()).indexOf(k),
          justifyContent: _vm.getJustfication(k),
        })},[(v.type == 'file')?_c('div',{class:v.klass,style:(v.styler ? v.styler(row) : '')}):_c('div',{class:v.klass,style:(v.styler ? v.styler(row) : '')},[_vm._v("\n          "+_vm._s(v.formatter
              ? v.formatter(row, _vm.extractField(row, k))
              : _vm.extractField(row, k))+"\n\n          "),(v.klass == 'tooltip')?_c('div',{staticClass:"tooltip-content"},[_vm._v("\n            "+_vm._s(v.formatter
                ? v.formatter(row, _vm.extractField(row, k))
                : _vm.extractField(row, k))+"\n          ")]):_vm._e()])])}),0)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }