<template>
  <div>
    <form @submit.prevent="handleSubmit" class="step-text-field">
      <input
        v-if="active"
        maxlength="10"
        @keyup="updateValue"
        :value="data[target]"
        :placeholder="placeholder"
        class="text-field"
        type="tel"
      />

      <input
        v-if="!active"
        maxlength="10"
        :value="data[target]"
        :placeholder="placeholder"
        class="text-field"
        type="text"
        disabled
      />

      <button
        class="text-field-submit"
        :disabled="!data[target] || !valid"
      ></button>
    </form>

    <div class="error">
      <transition name="fade">
        <div v-if="data[target] && data[target].length > 5 && !valid">
          Date must be formatted like {{ placeholder }}.
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus"
export default {
  name: "BirthdayField",
  props: {
    handler: {
      type: Function,
      default: () => {},
    },

    data: {
      type: Object,
      default: {},
    },

    active: {
      type: Boolean,
      default: false,
    },

    target: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },
  },

  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
        document.querySelector(".step-active").scroll({
          top: 100,
          left: 0,
          behavior: "smooth",
        })
      },
    },
  },

  computed: {
    valid() {
      if (this.data[this.target]) {
        return (
          this.data[this.target].length == 10 &&
          this.data[this.target].match(/^\d{2}\/\d{2}\/\d{4}$/)
        )
      } else {
        return false
      }
    },
  },

  methods: {
    updateValue(e) {
      let val = e.target.value
      console.log(val)
      EventBus.$emit("input", this.target, this.formatInput(val))
    },

    formatInput(val) {
      if (!val) return val

      var v = val
      if (v.match(/^\d{2}$/) !== null) {
        val = v + "/"
      } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
        val = v + "/"
      }

      return val
    },

    handleSubmit() {
      this.handler()
    },
  },
}
</script>

<style scoped>
form.step-text-field {
  display: grid;
  max-width: 375px;
  margin: 0 auto 0 auto;
  padding: 12px;
  grid-template-areas: "input submit";
  background: #ffffff;
  border: 2px solid #25a3d9;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
}

@media (max-width: 640px) {
  form.step-text-field {
    width: 100% !important;
    margin: 0 auto;
  }
}

.text-field:focus {
  outline: none;
}

.text-field-submit {
  grid-area: submit;
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 4px;
  border: 1px solid #25a3d9;
  border-radius: 16px;
  color: white;

  background-position: center;
  background-repeat: no-repeat;
  background-color: #25a3d9;
  background-image: url("~@/assets/images/forward-lite.svg");
}

.text-field-submit:disabled {
  background-color: #8c8c8c;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/forward-lite.svg");
  border: 1px solid #8c8c8c;
}

.text-field {
  grid-area: input;
  padding-left: 6px;
  height: 32px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.04em;
  width: calc(100% - 24px);
  font-family: "brandon-grotesque";
}

.error {
  height: 24px;
  margin-top: 16px;
  color: #4d4d4d;
}
</style>
