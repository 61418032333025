<template>
  <div class="nav" :class="{ 'nav-with-border': !step.nav.lite }">
    <div class="nav-elm left">
      <button
        class="nav-obj back"
        :class="{ lite: step.nav.lite }"
        v-if="step.nav.back"
        @click="handleNav(screener ? 'back-screen' : 'back')"
      ></button>
    </div>

    <div class="nav-elm center">
      <div
        class="nav-obj logo"
        v-if="step.nav.logo"
        :class="{ lite: step.nav.lite }"
      ></div>
    </div>

    <div class="nav-elm right">
      <button
        class="nav-obj close"
        :class="{ lite: step.nav.lite }"
        v-if="step.nav.close"
        @click="handleNav('next')"
      ></button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus"

export default {
  name: "Header",
  props: ["step", "screener"],
  methods: {
    handleNav(dir) {
      EventBus.$emit(dir)
    },
  },

  computed: {},
}
</script>

<style scoped>
.lite {
  color: white;
}

.bold-header {
  font-weight: bold;
}

.nav-elm {
  padding: 0px;
  border: none;
  display: inline-block;
}

.nav-obj {
  display: inline-table;
  text-align: center;
  height: 32px;
  width: 32px;
}

.nav {
  z-index: 10000;
  padding: 16px 0;
  position: fixed;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
}

.nav-with-border {
  background: #fff;
  box-shadow: 0px 5px 8px #80808026;
}

.left {
  margin-left: 32px;
  text-align: left;
  height: 32px;
}
.center {
  height: 38px;
  text-align: center;
}
.right {
  margin-right: 32px;
  text-align: right;
}

button.back {
  border: none;
  background: none;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/back.svg");
}

.logo {
  height: 38px;
  margin-bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/logo.svg");
}

button.back.lite {
  background-image: url("~@/assets/images/back-lite.svg");
}

button.close.lite {
  background-image: url("~@/assets/images/close-lite.svg");
}

.logo.lite {
  background-image: url("~@/assets/images/logo-lite.svg");
}
</style>
