<template>
  <div class="categories">
    <div class="category-container" v-for="category in categories">
      <Category v-if="category.display" :category="category" />
    </div>
  </div>
</template>

<script>
import Category from "@/components/Category"

export default {
  name: "Categories",
  data() {
    return {
      categories: [
        {
          name: "implants",
          img: require("../assets/images/implants.jpg"),
          display: true,
        },
        {
          name: "admin",
          img: require("../assets/images/admin.jpg"),
          display: this.$auth.appMetadata.role == "admin",
        },
        {
          name: "practice-settings",
          img: require("../assets/images/mixing-board.jpg"),
          display: ["practice-admin", "admin"].includes(
            this.$auth.appMetadata.role
          ),
        },
        {
          name: "api",
          img: require("../assets/images/api.jpg"),
          display: process.env.ENV !== "prod",
        },
      ],
    }
  },
  components: {
    Category,
  },
}
</script>

<style>
.category-container {
  display: inline-block;
}
</style>
