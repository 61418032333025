<template>
  <div>
    <div
      v-if="input && form"
      v-for="(v, k) in form"
      @change.lazy="checkForm()"
      :class="v.klass"
      class="form-field-wrap"
    >
      <label class="form-field-label" v-if="v.label">
        {{ v.label }}
      </label>

      <input
        :class="{ 'invalid': invalids[k] }"
        :disabled="postLoading || v.disabled"
        :name="k"
        :ref="k"
        v-if="['text', 'number', 'email', 'password'].includes(v.type)"
        @input="(value) => handleChange(value, k)"
        @blur="handleBlur(k)"
        :value="input[k]"
        class="form-field settings"
        :required="v.required"
        :type="v.type"
        :placeholder="v.placeholder"
      />

      <span
        v-html="v.type == 'text' ? pwShown() : pwHidden()"
        @click="v.type = v.type == 'password' ? 'text' : 'password'"
        class="pw-icon"
        v-if="k.toLowerCase().match('password')"
      >
      </span>

      <input
        :class="{
          'invalid': invalids[k],
        }"
        :disabled="postLoading"
        :name="k"
        :ref="k"
        v-if="['date'].includes(v.type)"
        @input="(value) => handleChange(value, k)"
        @blur="handleBlur(k)"
        :value="input[k]"
        class="form-field settings"
        :required="v.required"
        :type="v.type"
        :placeholder="v.placeholder"
      />

      <textarea
        :class="{
          'invalid': invalids[k],
        }"
        :disabled="postLoading"
        :name="k"
        :ref="k"
        v-if="v.type == 'textarea'"
        @input="(value) => handleChange(value, k)"
        :value="input[k]"
        class="form-field"
        :required="v.required"
        type="text"
        :placeholder="v.placeholder"
      >
      </textarea>

      <select
        :class="{
          'invalid': invalids[k],
        }"
        :disabled="postLoading"
        :name="k"
        :ref="k"
        v-if="v.type == 'select'"
        @input="(value) => handleChange(value, k)"
        @blur="handleBlur(k)"
        :value="input[k]"
        class="form-field settings"
        :required="v.required"
      >
        <option v-if="v.placeholder" value="" disabled>
          {{ v.placeholder }}
        </option>

        <option :key="JSON.stringify(o)" :value="o" v-for="(o, p) in v.options">
          {{ p }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { passwordToggleHidden, passwordToggleShown } from "@/utils/svg"

export default {
  name: "SettingsForm",
  props: ["form", "postLoading", "initInput"],
  data() {
    return {
      pwHidden: passwordToggleHidden,
      pwShown: passwordToggleShown,
      input: this.initInput,
      invalids: {},
    }
  },

  mounted() {
    this.checkForm()
  },

  methods: {
    handleChange(e, key) {
      if (this.form[key].onChange) {
        this.input[key] = this.form[key].onChange(e.target.value, key)
      } else if (this.form[key].toggleAlt) {
        let alt = this.form[key].toggleAlt()

        let values = Object.values(this.form[key].options)

        values.splice(values.indexOf(e.target.value), 1)

        this.input[alt] = values[0]
        this.input[key] = e.target.value
      } else if (this.form[key].type == "number") {
        this.input[key] = parseInt(e.target.value)
      } else {
        this.input[key] = e.target.value
      }
    },

    handleBlur(key) {
      if (this.form[key].pattern && this.form[key].required) {
        this.$set(
          this.invalids,
          key,
          !this.form[key].pattern.test(this.input[key])
        )
      } else if (this.form[key].pattern && this.input[key] != "") {
        this.$set(
          this.invalids,
          key,
          !this.form[key].pattern.test(this.input[key])
        )
      } else if (this.form[key].required && this.input[key] == "") {
        this.$set(this.invalids, key, true)
      } else {
        this.$set(this.invalids, key, false)
      }
    },

    checkForm() {
      var errors = []

      for (var f in this.form) {
        if (
          this.input[f] &&
          this.form[f].pattern &&
          !this.form[f].pattern.test(this.input[f])
        ) {
          errors.push(f + ": invalid")
        } else if (this.form[f].required && !this.input[f]) {
          errors.push(f + ": required")
        }
      }

      this.$emit("errors", errors)
    },
  },

  computed: {},

  watch: {
    initInput: function () {
      this.input = this.initInput
    },

    form: function () {
      this.checkForm()
    },

    input: {
      handler(n, o) {
        this.checkForm()

        this.$emit("input", this.input)
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style>
option {
  height: 56px;
  background: white;
}

input.settings,
select.settings {
  background: #f1f2f1;
}

select.form-field {
  background-image: url("~@/assets/images/chevron.png");
  background-position: left 94% bottom 50%;
  background-repeat: no-repeat, no-repeat, repeat-x;
}

select.form-field {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0px;
  padding: 0px 16px;

  position: static;
  width: 100%;
  height: 56px;
  box-sizing: border-box;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  background: #ffffff;
  border-radius: 4px;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.form-field-label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #1f1f1f;
  opacity: 0.5;
  margin: 8px 0px;
  text-align: left;
}

.form-step {
  position: relative;
}

.form-field-hidden {
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 0;
  margin: 0px;
  padding: 0px;
}

.pw-icon {
  position: absolute;
  top: 42px;
  right: 16px;
  cursor: pointer;
}

textarea.form-field {
  padding: 16px 16px 0px !important;
  height: 116px !important;
}

.invalid {
  border: 2px solid #d55252;
  background: rgba(213, 82, 82, 0.24);
}

.invalid::placeholder {
  color: #1f1f1f;
}

.invalid-label {
  color: #d55252;
  font-weight: bold;
}

.step-select {
  width: 100%;
}

input[type="date"].form-field {
  font-size: 14px !important;
}

.half-left {
  margin-top: -6px;
  float: left;
  width: 49.5%;
}

.half-right {
  margin-top: -6px;
  float: right;
  width: 49.5%;
}
</style>
