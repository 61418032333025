<template>
  <section>
    <h1>Hello, {{ patient }}!</h1>
    <p>
      Your appointment for {{ procedure }} on <br />
      <b>{{ startDateTime }}</b> <br />
      with <b>{{ provider }}</b> <br />
      at {{ location }} has been <b>confirmed</b>.
    </p>
  </section>
</template>

<script>
export default {
  name: "ConfirmAppointment",
  props: {
    patient: {
      type: String,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    startDateTime: {
      type: String,
      required: true,
    },
    procedure: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: "Hello from ConfirmAppointment",
    }
  },
}
</script>
<style lang="scss" scoped>
p {
  line-height: 1.6;

  b {
    font-size: 18px;
  }
}
</style>
