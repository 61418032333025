<template>
  <div>
    <div @click="handleClose" class="add-overlay"></div>

    <div class="add-overlay-box">
      <div class="modal-header">
        <div class="modal-header-text">
          Delete {{ type.charAt(0).toUpperCase() + type.slice(1) + "s" }}
        </div>

        <div
          @click="handleClose"
          class="modal-header-close"
          v-html="closeIcon()"
        ></div>
      </div>

      <div class="delete-body">
        You are about to delete the following {{ type }}(s) forever:

        <ul class="list">
          <li class="item" v-for="item in selected">
            {{ item[identifier] }}
          </li>
        </ul>
      </div>

      <div class="button-wrap">
        <button
          :disabled="postLoading"
          @click.prevent="handleClose"
          class="cancel-button"
        >
          Cancel
        </button>

        <button
          :disabled="postLoading"
          @click.prevent="handleConfirmClick"
          class="delete-button"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteIcon, closeIconDark } from "@/utils/svg"

export default {
  name: "UpdateForm",
  props: ["type", "identifier", "selected", "postLoading"],
  data() {
    return {
      closeIcon: closeIconDark,
      deleteIcon: deleteIcon,
      errors: [],
      loading: false,
      showDeleteConfirm: false,
    }
  },

  methods: {
    handleClose: function (e) {
      this.$emit("toggle-click", e)
    },

    handleConfirmClick: function () {
      this.$emit("on-deletes", this.selected)
    },
  },
}
</script>

<style scoped>
@media (max-width: 640px) {
  .add-overlay {
    height: 100%;
  }

  .add-overlay-box {
    height: unset;
    width: 90%;
  }

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px !important;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.modal-header-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1f1f1f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-header-icon {
  height: 20px;
  width: 16px;
}

.button-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 0px 0px;

  position: static;
  width: 100%;
  height: 72px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.cancel-button,
.delete-button {
  width: 50%;
  height: 56px;
  border-radius: 4px;
  display: inline;
  margin: 8px 8px 0px 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.cancel-button {
  background: none;
  color: #8c8c8c;
  border: 1px solid #8c8c8c;
}

.delete-button {
  background: #d55252;
  border: 1px solid #d55252;
  color: #ffffff;
}

button:disabled {
  border: grey;
  background: grey;
}

.delete-body {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.item {
  font-weight: bold;
  text-align: left;
}

.list {
  list-style-position: inside;
  padding-top: 16px;
}
</style>
