<template>
  <div class="user-actions-wrap">
    <div
      @click="toggle"
      v-if="$auth.appMetadata.role == 'admin'"
      class="action-container"
    >
      <div class="action-icon">
        <span v-html="gear()"></span>
      </div>
      <div class="action-text">
        <router-link to="/admin" class="nav-link"> Admin </router-link>
      </div>
    </div>

    <div @click="toggle" class="action-container">
      <div class="action-icon">
        <span v-html="profileIcon()"></span>
      </div>

      <div class="action-text">
        {{ $auth.user.nickname }}
        <!--<router-link to="/profile" class="nav-link">
                    {{$auth.user.nickname}}
                </router-link>-->
      </div>
    </div>

    <div @click="logout" class="action-container">
      <div class="action-icon">
        <span v-html="logoutIcon()"></span>
      </div>
      <div class="action-text">Logout</div>
    </div>
  </div>
</template>

<script>
import { smallProfileIcon, logout, gear } from "@/utils/svg"

export default {
  name: "usermenu",
  data() {
    return {
      profileIcon: smallProfileIcon,
      logoutIcon: logout,
      gear: gear,
    }
  },
  methods: {
    toggle: function () {
      this.$emit("onToggle")
    },

    logout() {
      this.$auth.logout()
      this.$router.push({ path: "/login" })
      this.emit("onToggle")
    },
  },
}
</script>

<style>
.user-actions-wrap a,
.user-actions-wrap a:visited {
  text-decoration: none;
  color: white;
}

.user-actions-wrap {
  /* Auto Layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;

  width: 100%;
  left: 24px;
  bottom: 24px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-transform: none;
}

.action-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 0px 16px;
}

.action-icon {
  position: static;
  width: 16px;
  height: 16px;
  left: 0px;
  top: 1.5px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.action-text {
  position: static;
  left: 24px;
  top: 0px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>
