<template>
  <div class="tab-menu tab-style">
    <div>
      <div class="tab-row">
        <div
          @click="() => updateTab(tab)"
          :class="{ 'tab-active': currentTab == tab }"
          class="tab-options"
          v-for="tab in tabs"
          :key="tab"
        >
          {{ tab }}
        </div>
      </div>

      <div class="tab-row tab-row-mobile">
        <select
          class="form-field settings"
          v-model="tab"
          @change="() => updateTab()"
        >
          <option v-for="tab in tabs" :key="tab" :value="tab">
            {{ tab }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tabs"],
  data() {
    return {
      currentTab: "",
      tab: "Info",
    }
  },

  mounted() {
    this.currentTab = this.tabs[0]
    this.$emit("update-tab", this.currentTab)
  },

  methods: {
    updateTab(dir) {
      console.log(!dir)
      if (dir == "prev") {
        this.setPrev()
      } else if (dir == "next") {
        this.setNext()
      } else if (!dir && this.tab) {
        console.log("TAB SELECTED")
        this.currentTab = this.tab
      } else {
        this.currentTab = dir
      }

      this.$emit("update-tab", this.currentTab)
    },

    setPrev() {
      let tabs = this.tabs
      let currentIndex = tabs.indexOf(this.currentTab)

      this.currentTab = tabs[0]

      if (currentIndex == 0) {
        this.currentTab = tabs[tabs.length - 1]
      } else {
        this.currentTab = tabs[currentIndex - 1]
      }
    },

    setNext() {
      let tabs = this.tabs
      let currentIndex = tabs.indexOf(this.currentTab)
      this.currentTab = tabs[0]

      if (currentIndex < tabs.length - 1) {
        this.currentTab = tabs[currentIndex + 1]
      } else {
        this.currentTab = tabs[0]
      }
    },
  },
}
</script>

<style>
.tab-menu {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 960px;
}

.tab-menu-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  position: static;
  width: 235px;
  height: 54px;

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 10px;
}

.tab-menu-options {
  display: block;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.tab-menu-option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 54px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}

.tab-menu-option-container {
  margin: auto;
  background: #f1f2f1;
  width: 600px;

  transition: all 0.3s ease-in-out;
  position: absolute;
  width: 100%;
  z-index: 0;
  top: -86px;
  padding-top: 86px;

  box-shadow: 0 1px 0px 0px black, 0 0px 0px 0px black, 1px 0 1px 0px black,
    -1px 0 1px 0px black;
}

.tab-menu-caret {
  width: 16px;
  height: 16px;

  align-items: center;
  display: flex;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}

.current-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 0;

  height: 54px;

  background: #f1f2f1;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}

.tab-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  border-radius: 4px;

  position: static;
  height: 52px;
  left: 16px;
  top: 16px;

  /* Soft Gray */

  background: #f1f2f1;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px auto 0px auto;
}

.tab-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  height: 100%;

  background: #f1f2f1;

  cursor: pointer;
  color: #8c8c8c;

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 1px;
}

.tab-options:first-child {
  border-radius: 4px 0 0 4px;
}

.tab-options:last-child {
  border-radius: 0 4px 4px 0;
}

.tab-active {
  background: rgba(37, 163, 217, 0.1);
  border: 1px solid #25a3d9;
  color: #25a3d9 !important;
  font-weight: bold;
  cursor: auto !important;
  height: 100%;
}

.tab-options:hover {
  opacity: 0.7;
}

.show-pointer {
  cursor: pointer;
}

.tab-menu-option:hover {
  cursor: pointer;
}

.tab-menu-option:hover {
  background: #8c8c8c;
  color: white;
}

/* .tab-menu-option:last-child {
} */

.inventory {
  background: white;
  margin-bottom: 16px;
  display: block;
  min-height: 100vh;
}

.slide-enter,
.slide-leave-active {
  top: -200px;
  z-index: -1;
  box-shadow: none;
}

.slide-enter-active {
  box-shadow: none;
}

.slide-enter-to .tab-menu-option:hover,
.slide-leave-to .tab-menu-option:hover {
  background: unset;
  z-index: -1;
}

.no-box-shadow {
  box-shadow: none;
}

.tab-row-mobile {
  display: none;
}

@media (max-width: 640px) {
  .tab-menu {
    width: 100%;
  }

  .tab-style {
    width: 93%;
  }

  .tab-menu-option-container {
    width: 100%;
    left: unset;
  }

  .tab-row {
    display: none;
  }

  .tab-row-mobile {
    display: block;
  }
}
</style>
