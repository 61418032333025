var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.step)?_c('div',{staticClass:"step",class:{
    lite: _vm.step.nav.lite,
    'step-active': _vm.active,
  },style:({
    'background-image': _vm.step.backgroundImg
      ? 'linear-gradient(180deg, rgba(37, 163, 217, 0.6) 0%, #25A3D9 100%),' +
        'url(' +
        _vm.step.backgroundImg +
        ')'
      : 'none',
  })},[(_vm.step.content)?_c('div',{staticClass:"content",class:{ 'content-middle': _vm.step.content.middleAlign },style:({
      margin: _vm.step.content.margin ? _vm.step.content.margin : false,
    })},[(_vm.step.content.img)?_c('div',{staticClass:"img-container"},[_c('img',{staticClass:"step-img",attrs:{"src":_vm.step.content.img}})]):_vm._e(),_vm._v(" "),(_vm.step.content.header)?_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"step-header-text",class:{
          lite: _vm.step.nav.lite,
          'bold-header': _vm.step.content.boldHeader,
        },domProps:{"innerHTML":_vm._s(
          typeof _vm.step.content.header === 'function'
            ? _vm.step.content.header()
            : _vm.step.content.header
        )}})]):_vm._e(),_vm._v(" "),(_vm.step.content.text)?_c('div',{staticClass:"text-container"},[_c('div',{staticClass:"step-text",class:{ lite: _vm.step.nav.lite },domProps:{"innerHTML":_vm._s(
          typeof _vm.step.content.text === 'function'
            ? _vm.step.content.text()
            : _vm.step.content.text
        )}})]):_vm._e(),_vm._v(" "),(_vm.step.content.textHTML)?_c('div',{staticClass:"text-container"},[_c('div',{staticClass:"step-text",class:{ lite: _vm.step.nav.lite },domProps:{"innerHTML":_vm._s(_vm.step.content.textHTML)}})]):_vm._e(),_vm._v(" "),(_vm.step.form)?_c('div',{staticClass:"step-form"},[_c(_vm.step.form.component,{tag:"component",attrs:{"form":_vm.step.form,"target":_vm.step.form.target,"handler":_vm.step.form.handler}})],1):_vm._e(),_vm._v(" "),(_vm.step.component)?_c('div',{staticClass:"step-component"},[_c(_vm.step.component,{tag:"component",attrs:{"model":_vm.data,"data":_vm.data}})],1):_vm._e(),_vm._v(" "),(_vm.step.content.html)?_c('div',{staticClass:"step-html-container"},[_c('div',{class:{ lite: _vm.step.nav.lite },domProps:{"innerHTML":_vm._s(
          typeof _vm.step.content.html === 'function'
            ? _vm.step.content.html()
            : _vm.step.content.html
        )}})]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.step.footer && _vm.step.footer.show())?_c('div',{staticClass:"footer",class:{
      'footer-with-border': !_vm.step.nav.lite,
    }},[_c('div',{staticClass:"footer-elm"},[(_vm.step.footer && _vm.step.footer.button)?_c('button',{class:{
          'footer-btn': true,
          lite: _vm.step.nav.lite,
          'small-btn': _vm.step.footer.button.small,
        },on:{"click":_vm.step.footer.button.handler}},[_vm._v("\n        "+_vm._s(_vm.step.footer.button.text)+"\n      ")]):_vm._e()])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"footer-status"},[_c('div',{staticClass:"footer-status-bar",class:{ lite: _vm.step.nav.lite },style:({ width: _vm.percent + '%' })})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }