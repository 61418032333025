<template>
  <div>
    <div class="date-selector">
      <div class="nav">
        <div class="nav-elm left">
          <button
            v-html="chevronLeft()"
            class="nav-obj back"
            @click="handleNav('back')"
          ></button>
        </div>

        <div class="nav-elm center">
          <div class="nav-obj current-month">
            {{ currentMonthDisplay }} {{ currentYear }}
          </div>
        </div>
        <div class="nav-elm right">
          <button
            v-html="chevronRight()"
            class="nav-obj close"
            @click="handleNav('next')"
          ></button>
        </div>
      </div>
      <!--{{ currentDate }}-->
      <div class="calendar">
        <div class="day-of-week">
          <div>SUN</div>
          <div>MON</div>
          <div>TUE</div>
          <div>WED</div>
          <div>THU</div>
          <div>FRI</div>
          <div>SAT</div>
        </div>
        <div v-if="data" class="date-grid" :class="startDay">
          <div
            class="date"
            :key="index"
            @click="selectDay(index)"
            :class="getButtonClass(index)"
            v-for="index in daysInMonth"
          >
            {{ index }}
          </div>
        </div>
      </div>

      <div v-if="selectedDay" class="selected-date">
        <div class="selected-header">Next, choose a time slot</div>
        <div class="selected-text">
          {{ selectedDayFormatted }}
        </div>

        <div v-if="selectedDaySlots" class="slots-holder-overlay">
          <div v-if="selectedDaySlots" class="slots-holder">
            <button
              @click="selectSlot(slot)"
              :key="JSON.stringify(slot)"
              v-for="slot in selectedDaySlots"
              :class="{ 'selected-slot': slot == selectedSlot }"
              class="slots"
            >
              {{
                new Date(slot.start).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </button>
          </div>
        </div>
      </div>

      <div class="info-container">
        <VerifyLoading :input="verifyInput" />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus"
import { chevronLeft, chevronRight } from "@/utils/svg"
import VerifyLoading from "@/components/VerifyLoading"
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

export default {
  name: "DateSelector",
  components: {
    VerifyLoading,
  },
  props: {
    handler: {
      type: Function,
      default: () => {},
    },

    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      chevronLeft: chevronLeft,
      chevronRight: chevronRight,
      currentDate: null,
      currentMonth: null,
      currentYear: null,
      currentMonthSlots: {},
      selectedDayIndex: null,
      selectedDay: null,
      selectedSlot: null,
      verifyingState: null,
      verifyMsg: null,
      verifySubMsg: null,
    }
  },

  mounted() {
    EventBus.$on("verifying", () => {
      this.verifySubMsg = null
      this.verifying = true
      this.verifyingState = "fetching"
      this.verifyMsg = "Verifying availability..."
      this.scroll()
    })

    EventBus.$on("verifyingSuccess", () => {
      this.verifySubMsg = null
      this.verifying = false
      this.verifyingState = "success"
      this.verifyMsg = "Time slot available!"
    })

    EventBus.$on("verifyingFailed", () => {
      this.verifySubMsg = null
      this.verifying = false
      this.verifyingState = "failed"
      this.verifySubMsg =
        `Please select another time or call <a href="tel:${this.data.location.phoneNumber}">` +
        this.data.location.phoneNumber +
        "</a>."
      this.verifyMsg = "Time slot no longer available"
    })

    EventBus.$on("verifyingReset", () => {
      this.verifying = false
      this.verifyingState = null
      this.verifyMsg = null
      this.verifySubMsg = null
    })
  },

  methods: {
    scroll() {
      document.querySelector(".step-active").scroll({
        top: 3000,
        left: 0,
        behavior: "smooth",
      })
    },

    selectSlot(slot) {
      EventBus.$emit("verifying")
      EventBus.$emit("input", "slot", slot)
      this.selectedSlot = slot

      this.handler()
    },

    selectDay(index) {
      this.scroll()

      let indexDate = new Date(this.currentYear, this.currentMonth, index)
      if (this.hasSlots(index)) {
        this.selectedDayIndex = index
        this.selectedDay = indexDate.toLocaleDateString()
      }
    },

    getButtonClass(index) {
      let classes = []
      let d = new Date(this.currentYear, this.currentMonth, index)
      classes.push(d.toDateString().split(" ")[0].toLowerCase())

      if (this.hasSlots(index)) {
        classes.push("has-slots")
      }

      if (this.selectedDayIndex == index) {
        classes.push("selected-day")
      }

      return classes
    },

    hasSlots(index) {
      let indexDate = new Date(this.currentYear, this.currentMonth, index)

      return Object.keys(this.currentMonthSlots).includes(
        indexDate.toLocaleDateString()
      )
    },

    getCurrentMonthSlots() {
      return this.data && this.data.slots
        ? this.data.slots.filter((x) => {
            let currentMonth = this.currentMonth + 1

            currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth
            return x.start.match(`${this.currentYear}-${currentMonth}`)
          })
        : []
    },

    setCurrentMonthSlots() {
      let slots = {}
      let currSlots = this.getCurrentMonthSlots()

      for (var c in currSlots) {
        let key = new Date(currSlots[c].start).toLocaleDateString()

        if (!slots[key]) {
          slots[key] = []
        }

        slots[key].push(currSlots[c])
      }

      this.currentMonthSlots = slots
    },

    handleNav(dir) {
      if (dir == "back") {
        if (this.currentMonth == 0) {
          this.currentMonth = 11
          this.currentYear -= 1
        } else {
          this.currentMonth -= 1
        }
      } else if (dir == "next") {
        if (this.currentMonth == 11) {
          this.currentMonth = 0
          this.currentYear += 1
        } else {
          this.currentMonth += 1
        }
      }

      this.currentDate = new Date(this.currentYear, this.currentMonth, 1)
      this.setCurrentMonthSlots()
    },
  },

  computed: {
    verifyInput() {
      return {
        state: this.verifyingState,
        msg: this.verifyMsg,
        subMsg: this.verifySubMsg,
      }
    },

    startDay() {
      let start = new Date(this.currentYear, this.currentMonth, 1)

      return start.toDateString().split(" ")[0].toLowerCase()
    },

    daysInMonth() {
      return new Date(this.currentYear, this.currentMonth + 1, 0).getDate()
    },

    currentMonthDisplay() {
      if (this.currentMonth != null) {
        return monthNames[this.currentMonth]
      }
    },

    selectedDaySlots() {
      return this.currentMonthSlots[this.selectedDay]
    },

    selectedDayFormatted() {
      let day = new Date(this.selectedDay)

      return `${dayNames[day.getDay() - 1]}, ${
        monthNames[day.getMonth()]
      } ${day.getDate()}, ${day.getFullYear()}`
    },

    sortedSlots() {
      if (this.data.slots) {
        return this.data.slots.sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        )
      } else {
        return []
      }
    },
  },

  watch: {
    "data.slots": function () {
      if (this.data.slots) {
        let currDate = new Date(this.sortedSlots[0].start)
        currDate.setDate(1)
        this.currentDate = currDate

        this.currentMonth = this.currentDate.getMonth()
        this.currentYear = this.currentDate.getFullYear()

        this.setCurrentMonthSlots()
      }
    },
  },
}
</script>

<style scoped>
.date-selector {
  padding: 24px;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 372px;
  border-radius: 6px;
  background: white;
  box-sizing: border-box;
}

.nav-elm {
  position: relative;
  height: 32px;
  line-height: 32px;
  width: 100%;
  padding: 0px;
  border: none;
  display: inline-block;
}

.left {
  text-align: left;
  width: 32px;
}

.right {
  width: 32px;
  text-align: right;
}

.nav-obj {
  display: inline-table;
  align-items: flex-start;
  text-align: center;
  height: 32px;
}

.nav {
  display: flex;
  width: 100%;
  height: 32px;
  margin-bottom: 12px;
}

button.back {
  border: none;
  background: none;
}

button.close {
  border: none;
  background: none;
}

.logo {
  margin-bottom: 0;
}

.day-of-week,
.date-grid {
  display: grid;
  text-align: center;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #8c8c8c;
  margin-bottom: 8px;
}

.current-month {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #1a1a1a;
}

.sun div:first-child {
  grid-column: 1;
}

.mon div:first-child {
  grid-column: 2;
}

.tue div:first-child {
  grid-column: 3;
}

.wed div:first-child {
  grid-column: 4;
}

.thu div:first-child {
  grid-column: 5;
}

.fri div:first-child {
  grid-column: 6;
}

.sat div:first-child {
  grid-column: 7;
}

.date {
  border-radius: 16px;
  background: none;
  border: none;
  height: 32px;
  width: 32px;
  line-height: 32px;

  font-family: "brandon-grotesque";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  letter-spacing: 0.04em;

  color: #8c8c8c;
}

.has-slots {
  border: 1px solid #25a3d9;
  color: #25a3d9;
  cursor: pointer;
}

.has-slots:hover,
.selected-day {
  color: white;
  background: #25a3d9;
}

.selected-date {
  margin-top: 12px;
}

.selected-header {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 140%;

  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.selected-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #8c8c8c;
  margin-bottom: 16px;
}

.slots-holder {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 9px;
  padding-right: 9px;
}

.slots-holder-overlay {
  position: relative;
}

.slots-holder-overlay:before {
  height: 100%;
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), white 95%);
  width: 16px;
}

.slots-holder-overlay:after {
  height: 100%;
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 95%
  );
  width: 16px;
}

/*.slots-holder-overlay > .slots-holder,
.slots-holder > .slots-holder-overlay {
  z-index: -1;
  position: relative;
}*/

.slots {
  height: 40px;
  margin: 0 4px;
  padding: 8px 16px;
  display: inline-block;
  background: white;
  border: 1px solid #25a3d9;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  font-family: "brandon-grotesque";

  color: #25a3d9;
}

.slots:hover,
.selected-slot,
.slots:active {
  outline: none;
  cursor: pointer;
  color: white;
  background: #25a3d9;
}
</style>
