import Practice from "./practices.js"
import Model from "./model.js"

export default class PracticeUser extends Model {
  constructor(props) {
    super(props)

    this.type = "user"
    this.remotePath = props.remotePath || "/api/practice-settings/users"
    this.practiceRemotePath =
      props.practiceRemotePath || "/api/practice-settings/practice"

    this.toolConfig = props.toolConfig || {
      export: true,
      search: true,
      newForm: true,
      multiUpdate: false,
      updateForm: true,
      formStepCount: 1,
    }
  }

  async fetchData() {
    await super.fetchData()
    this.practiceData = await this.getPracticeData()

    for (var u in this.data) {
      this.data[u].practice = this.practiceData
    }

    console.log(this.data)
  }

  async getNewForm() {
    let practiceOptions = this.getPracticeOptions()
    let newForm = super.getNewForm()
    newForm["practice_id"].options = practiceOptions

    return newForm
  }

  getPracticeOptions() {
    let options = {}
    options[this.practiceData.city] = this.practiceData.uid

    return options
  }

  async getPracticeData() {
    if (this.auth) {
      let practice = new Practice({
        auth: this.auth,
        remotePath: this.practiceRemotePath,
      })
      await practice.fetchData()

      return practice.data
    }
  }

  formatPayload(input, type) {
    let keys =
      type == "update"
        ? Object.keys(this.updateFormFields())
        : Object.keys(this.newFormFields())

    let data = input
    let payload = {}

    for (var key in keys) {
      payload[keys[key]] = data[keys[key]]
    }

    payload["app_metadata"] = {
      role: input.role,
      practice_id: input.practice_id,
    }

    return payload
  }

  async putData(data) {
    var id = data.user_id
    await super.putData(id, data)
  }

  async deleteData(data) {
    for (var i in data) {
      await super.deleteData(data[i].user_id)
    }
  }

  lastLoginFormatter(row) {
    return new Date(row.last_login).toLocaleString()
  }

  getRoleOptions() {
    return {
      "Practice User": "user",
      "Practice Admin": "practice-admin",
    }
  }

  schema() {
    return {
      "name": {
        header: "Name",
        label: "Name",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Name",
        type: "text",
        required: true,
      },

      "email": {
        header: "Email",
        label: "Email",
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Email Address",
        type: "email",
        required: true,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },

      "password": {
        label: "Password",
        placeholder: "Password",
        type: "password",
        newForm: true,
        required: true,
      },

      "role": {
        header: "Role",
        display: true,
        styler: () => {
          return { "text-align": "center" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Role",
        type: "select",
        required: true,
        options: this.getRoleOptions(),
      },

      "practice": {
        header: "Practice",
        display: false,
        fields: ["practice.city"],
        placeholder: "Practice",
      },

      "practice_id": {
        placeholder: "Practice",
        type: "hidden",
        newForm: true,
        updateForm: true,
        required: true,
        value: this.auth.practice_id,
      },

      "connection": {
        type: "hidden",
        updateForm: true,
        newForm: true,
        required: true,
        type: "hidden",
        value: "Username-Password-Authentication",
      },

      "user_id": {
        updateForm: true,
        required: true,
        type: "hidden",
      },

      "last_login": {
        formatter: this.lastLoginFormatter,
        header: "Last Login",
        display: true,
      },

      "sub": {
        required: true,
        type: "hidden",
      },
    }
  }
}
