<template>
  <div class="search-row">
    <div v-if="config.search" class="search-wrap">
      <input
        @input="handleInput"
        type="text"
        placeholder="Search"
        class="search-field"
      />

      <div v-if="config.export" @click="exportCsv" class="export">
        <div class="exportButton" v-html="exportIcon()"></div>
      </div>
    </div>

    <div
      @click="$emit('add-click', $event)"
      v-if="config.newForm"
      class="add-button"
    >
      <div class="add-button-text">
        Add New {{ type.charAt(0).toUpperCase() + type.slice(1) }}
      </div>
      <div class="add-button-icon">+</div>
    </div>
  </div>
</template>

<script>
import { csv } from "@/utils/csv"

import { exportIcon, searchIcon } from "@/utils/svg"

export default {
  name: "TableTools",
  props: ["config", "data", "type"],
  data() {
    return {
      exportIcon: exportIcon,
      searchIcon: searchIcon,
    }
  },

  methods: {
    handleInput: function (e) {
      this.$emit("input", e.target.value)
    },

    exportCsv: function () {
      let dataStr = csv(this.data)
      let dataUri =
        "data:application/json;charset=utf-8," + encodeURIComponent(dataStr)
      let exportFileDefaultName = "export.csv"

      let linkElement = document.createElement("a")
      linkElement.setAttribute("href", dataUri)
      linkElement.setAttribute("download", exportFileDefaultName)
      linkElement.click()
    },
  },
}
</script>

<style>
.search-row {
  width: 100%;
  margin: 0px auto 14px auto;
}

.search-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  margin-bottom: 8px;

  width: 100%;
  height: 56px;
  left: 0px;
  top: 0px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.search-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 16px;

  position: static;
  height: 56px;
  left: 0px;
  top: 0px;

  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 1;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.export {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  cursor: pointer;
  position: static;
  width: 56px;
  height: 56px;

  background: #f1f2f1;

  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 4px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px 0px 8px;
}

.search-icon {
  position: static;
  left: 5.73%;
  right: 88.53%;
  top: 35.71%;
  bottom: 35.71%;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.add-button {
  height: 56px;
  background: #25a3d9;
  border: 1px solid #25a3d9;
  box-sizing: border-box;
  border-radius: 4px;
  align-self: stretch;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;

  cursor: pointer;
}

.add-button:hover {
  background: #2b93bf;
}

.add-button-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.add-button-icon {
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;

  color: #ffffff;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
}

@media (max-width: 800px) {
  .search-row {
    width: 600px;
  }

  .export {
    display: none;
  }
}

@media (max-width: 640px) {
  .search-row {
    width: 360px;
  }
}
</style>
