import axios from "axios"
import Model from "./model.js"
import VisitReason from "./visit_reason.js"

export default class Provider extends Model {
  constructor(props) {
    super(props)

    this.type = "provider"
    this.externalProviders = []
    this.providerRoles = []
    this.visitReasonsData = []
    this.remotePath = props.remotePath || "/api/admin/providers"
    this.toolConfig = props.toolConfig || {
      export: true,
      search: true,
      newForm: true,
      multiUpdate: false,
      updateForm: true,
      formStepCount: 2,
    }

    let defaultSettingsConfig = {
      newForm: true,
      multiUpdate: false,
      pageUpdate: false,
      formStepCount: 2,
    }

    this.settingsConfig = props.settingsConfig
      ? { ...defaultSettingsConfig, ...props.settingsConfig }
      : defaultSettingsConfig
  }

  async fetchData() {
    await super.fetchData()
    this.visitReasonsData = await this.getVisitReasonData()

    this.externalProviders = this.data.externalProviders
    this.providerRoles = this.data.providerRoles
    this.pccProviders = this.data.providers.filter(
      (x) => x.providerRole.name == "patient care coordinator"
    )

    this.data = this.data.providers
  }

  async getNewForm() {
    let newForm = super.getNewForm()
    newForm["externalProviderId"].options = this.getExternalProviderOptions()
    newForm["providerRoleUid"].options = this.getProviderRolesOptions()
    newForm["visitReasonUids"].options = this.getVisitReasonsOptions()
    newForm["pccProviderUid"].options = this.getPccProviderUidOptions()

    return newForm
  }

  async getUpdateForm() {
    let updateForm = super.getUpdateForm()

    updateForm["externalProviderId"].options = this.getExternalProviderOptions()
    updateForm["providerRoleUid"].options = this.getProviderRolesOptions()
    updateForm["visitReasonUids"].options = this.getVisitReasonsOptions()
    updateForm["pccProviderUid"].options = this.getPccProviderUidOptions()

    return updateForm
  }

  createdMsg(resp) {
    let msg = super.createdMsg()
    msg.field = resp.data.data.name

    return msg
  }

  updatedMsg(resp) {
    let msg = super.updatedMsg()
    msg.field = resp.data.data.name

    return msg
  }

  formatPayload(input, type) {
    let keys =
      type == "update"
        ? Object.keys(this.updateFormFields())
        : Object.keys(this.newFormFields())

    let schema = this.schema()
    let data = input

    let visitReasonOverrides = input.visitReasonOverrides
    data.visitReasonOverrides = []

    for (var i in visitReasonOverrides) {
      let reason = i
      let minutes = parseInt(visitReasonOverrides[i])

      data.visitReasonOverrides.push({
        minutes: minutes,
        visitReasonUid: reason,
        providerUid: input.uid,
      })
    }

    console.log(data.visitReasonOverrides)

    let payload = {}
    let formData = new FormData()

    for (var key in keys) {
      let val = data[keys[key]]

      if (schema[keys[key]].type != "file") {
        payload[keys[key]] = ["true", "false"].includes(val) ? eval(val) : val
      }

      if (keys[key] == "uid") {
        formData.append("uid", val)
      }

      if (schema[keys[key]].type == "file") {
        formData.append("file", data[keys[key]].fileObject)
      }
    }

    formData.append("data", JSON.stringify(payload))

    return formData
  }

  async postData(data) {
    if (this.auth) {
      await axios
        .post(`${this.backendUrl}${this.remotePath}`, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${this.auth.accessToken}`,
            Id: this.auth.user.name,
          },
        })
        .then((resp) => {
          this.msg = this.createdMsg(resp)
        })
        .catch((e) => {
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  async putData(data) {
    var id = data.get("uid")

    if (this.auth) {
      await axios
        .put(`${this.backendUrl}${this.remotePath}/${id}`, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${this.auth.accessToken}`,
            Id: this.auth.user.name,
          },
        })
        .then((resp) => {
          this.msg = this.updatedMsg(resp)
        })
        .catch((e) => {
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  async deleteData(data) {
    for (var i in data) {
      await super.deleteData(data[i].uid)
    }
  }

  lastLoginFormatter(row) {
    return new Date(row.last_login).toLocaleString()
  }

  getExternalProviderOptions() {
    let options = {}

    for (var p in this.externalProviders) {
      let o = this.externalProviders[p]

      options[o.name] = o.externalProviderId
    }

    return options
  }

  getProviderRolesOptions() {
    let options = {}

    for (var p in this.providerRoles) {
      let o = this.providerRoles[p]

      options[o.name] = o.uid
    }

    return options
  }

  async getVisitReasonData() {
    if (this.auth) {
      let visitReasons = new VisitReason({
        auth: this.auth,
      })

      await visitReasons.fetchData()

      return visitReasons.data
    }
  }

  getPccProviderUidOptions() {
    let options = { "None": "00000000-0000-0000-0000-000000000000" }

    for (var p in this.pccProviders) {
      let o = this.pccProviders[p]

      options[o.name] = o.uid
    }

    return options
  }

  getVisitReasonsOptions() {
    let options = {}

    for (var p in this.visitReasonsData) {
      let o = this.visitReasonsData[p]

      options[o.name] = o.uid
    }

    return options
  }

  schema() {
    return {
      providerImageSrc: {
        header: "Image",
        label: "Name",
        placeholder: "Provider Image",
        label: "Provider Image",
        styler: (r) => {
          return {
            "margin-left": "16px",
            width: "50px",
            height: "50px",
            "background-size": "120%",
            "border-radius": "4px",
            "background-position": "center center",
            "background-repeat": "no-repeat",
            "background-image": `url(${r.providerImageSrc})`,
          }
        },
        display: true,
        type: "file",
        newForm: true,
        updateForm: true,
        required: false,
        step: 1,
        value: null,
        //value: { name: "" },
      },

      name: {
        header: "Name",
        label: "Name",
        display: true,
        klass: "no-transform",
        styler: () => {
          return { "text-align": "left" }
        },
        newForm: true,
        updateForm: true,
        placeholder: "Name",
        type: "text",
        required: true,
        step: 1,
      },

      enableScheduling: {
        header: "Scheduling",
        placeholder: "Scheduling Enabled",
        label: "Scheduling Enabled",
        display: true,
        formatter: function (r) {
          return r.enableScheduling == true ? "enabled" : "disabled"
        },
        type: "select",
        newForm: true,
        updateForm: true,
        required: true,
        value: null,
        step: 1,
        options: {
          Yes: true,
          No: false,
        },
      },

      //////scheduleIncrementMinutes: {
      //////  header: "Appts Every",
      //////  placeholder: "Appts Every",
      //////  label: "Appts Every",
      //////  formatter: function (r) {
      //////    return `${r.scheduleIncrementMinutes} minutes`
      //////  },
      //////  display: true,
      //////  type: "select",
      //////  newForm: true,
      //////  updateForm: true,
      //////  required: true,
      //////  value: null,
      //////  step: 1,
      //////  options: {
      //////    "10 minutes": "10",
      //////    "15 minutes": "15",
      //////    "20 minutes": "20",
      //////    "30 minutes": "30",
      //////    "60 minutes": "60",
      //////  },
      //////},

      externalProviderId: {
        placeholder: "EMR Provider",
        label: "EMR Provider",
        type: "select",
        newForm: true,
        updateForm: true,
        required: true,
        value: null,
        step: 1,
        options: {},
      },

      providerRoleUid: {
        placeholder: "Provider Role",
        label: "Provider Role",
        type: "select",
        newForm: true,
        updateForm: true,
        required: false,
        value: null,
        step: 1,
        options: {},
      },

      pccProviderUid: {
        placeholder: "Patient Care Coordinator",
        label: "Patient Care Coordinator",
        type: "select",
        newForm: true,
        updateForm: true,
        required: false,
        value: null,
        step: 2,
        options: {},
      },

      visitReasonUids: {
        placeholder: "Visit Reasons",
        label: "Visit Reasons",
        type: "multi-checkbox",
        newForm: true,
        updateForm: true,
        required: false,
        value: [],
        step: 2,
        options: {},
        nested: "visitReasonOverrides",
      },

      visitReasonOverrides: {
        placeholder: "Time Override",
        newForm: false,
        updateForm: true,
        required: false,
        nested: true,
        value: {},
        valueSetter: (x) => {
          let overrides = x
          let vroData = {}

          for (var x in this.visitReasonsData) {
            let reason = this.visitReasonsData[x]
            vroData[reason.uid] = reason.minutes
          }

          for (var v in overrides) {
            let vo = overrides[v]
            vroData[vo.visitReasonUid] = vo.minutes
          }

          return vroData
        },
      },

      background: {
        placeholder: "Background Info",
        label: "Background Info",
        type: "textarea",
        newForm: true,
        updateForm: true,
        required: false,
        step: 2,
      },

      uid: {
        type: "hidden",
        updateForm: true,
        required: true,
        step: 1,
      },
    }
  }
}
