<template>
  <div class="inventory">
    <Loading :transparent="true" v-if="loading" />

    <Pager v-if="models" :pages="models" :config="pagerConfig" @update:page="model = $event" />

    <div v-if="model && model.link" class="pagins">
      <button class="pagin" :disabled="!model.link.filter((x) => x.relation == 'first').length > 0"
        @click.prevent="handlePage('first')"></button>

      <button class="pagin" :disabled="!model.link.filter((x) => x.relation == 'previous').length > 0
        " @click.prevent="handlePage('previous')">
        BACK
      </button>

      <button class="pagin" :disabled="!model.link.filter((x) => x.relation == 'next').length > 0"
        @click.prevent="handlePage('next')">
        NEXT
      </button>

      <button class="pagin" :disabled="!model.link.filter((x) => x.relation == 'last').length > 0"
        @click.prevent="handlePage('last')"></button>
    </div>

    <VueTable v-if="!loading && models" @selected="selected = $event" @on-add="($event, callback) => {
      handleAdd($event, callback);
    }
      " @on-update="($event, callback) => {
        handleUpdate($event, callback);
      }
        " @on-updates="($event, selected, callback) => {
          handleUpdates($event, selected, callback);
        }
          " @on-process="($event, selected, callback) => {
          handleProcess($event, selected, callback);
        }
          " @on-delete="($event, callback) => {
          handleDelete($event, callback);
        }
          " @on-deletes="($event, callback) => {
          handleDeletes($event, callback);
        }
          " :post-loading="postLoading" :model="model" :scanner="scanner" :loading="loading" />

    <transition name="fade">
      <FloatMsg v-if="msg !== ''" :err="err" :msg="msg" :callback="() => {
        msg = '';
        err = false;
      }
        " />
    </transition>
  </div>
</template>

<script>
import Loading from "@/components/Loading"
import FloatMsg from "@/components/FloatMsg"
import Pager from "@/components/Pager"
import VueTable from "@/components/VueTable"
import Scanner from "@/utils/scanner"

export default {
  name: "inventory",
  props: ["models", "pagerConfig"],
  components: {
    Pager,
    Loading,
    FloatMsg,
    VueTable,
  },

  data() {
    return {
      msg: "",
      scanner: new Scanner(),
      err: false,
      selected: [],
      loading: true,
      postLoading: false,
      model: null,
    }
  },

  mounted() {
    window.onkeypress = (e) => {
      this.scanner.handleKeyPress(e)
    }
  },

  methods: {
    handlePage(page) {
      let dest = this.model.link.filter((x) => x.relation == page)
      let query = "?" + dest[0].url.split("?")[1]
      this.model.query = query
    },

    handleAdd: async function (e, callback) {
      await this.postData(e)
      callback()
    },

    handleUpdate: async function (e, callback) {
      await this.putData(e)
      callback()
    },

    handleDelete: async function (e, callback) {
      await this.deleteData(e)
      callback()
    },

    handleDeletes: async function (e, callback) {
      await this.deleteDatas(e)
      callback()
    },

    handleUpdates: async function (e, selected, callback) {
      await this.putDatas(e, selected)
      callback()
    },

    handleProcess: async function (e, selected, callback) {
      await this.processData(e, selected)
      callback()
    },

    reset: async function () {
      const accessToken = await this.$auth.getTokenSilently()
      let auth = {
        accessToken: accessToken,
        user: this.$auth.user,
        role: this.$auth.appMetadata.role,
      }
      this.model.setAuth(auth)

      if (this.models) {
        this.fetchData()
      } else {
        this.loading = false
      }
    },

    fetchData: async function () {
      this.loading = true

      await this.model.fetchData()
      this.loading = false
    },

    postData: async function (data) {
      this.postLoading = true
      await this.model.postData(data)
      this.postLoading = false

      this.msg = this.model.msg
      this.fetchData()
    },

    putData: async function (data) {
      this.postLoading = true
      await this.model.putData(data)
      this.postLoading = false

      this.msg = this.model.msg
      this.fetchData()
    },

    putDatas: async function (data, selected) {
      this.postLoading = true
      await this.model.putDatas(data, selected)
      this.postLoading = false

      this.msg = this.model.msg
      this.fetchData()
    },

    processData: async function (data, selected) {
      this.postLoading = true
      await this.model.processForm.processData(data, selected)
      this.postLoading = false

      this.msg = this.model.processForm.msg
      this.fetchData()
    },

    deleteData: async function (data) {
      this.postLoading = true
      await this.model.deleteData(data)
      this.postLoading = false

      this.msg = this.model.msg
      this.fetchData()
    },

    deleteDatas: async function (data) {
      this.postLoading = true
      await this.model.deleteDatas(data)
      this.postLoading = false

      this.msg = this.model.msg
      this.fetchData()
    },
  },

  watch: {
    model: function () {
      this.loading = true
      this.reset()
    },

    "model.query": function () {
      this.loading = true
      this.reset()
    },

    $route() {
      this.reset()
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style>
input[type="date"].form-field {
  -webkit-min-logical-width: calc(100% - 16px);
  background-image: url("~@/assets/images/cal.png");
  background-position: left 94% bottom 50%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-color: white;
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "";
}

::-webkit-calendar-picker-indicator {
  opacity: 0;
}

select.form-field {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.form-field {
  background-image: url("~@/assets/images/chevron.png");
  background-position: left 94% bottom 50%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-color: white;
}

.add-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

.add-overlay-box {
  position: fixed;
  overflow-y: hidden;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  background: #f1f2f1;
  border-radius: 4px;

  width: 380px;

  z-index: 5500;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;
}

form {
  width: 100%;
}

@media (max-width: 640px) {
  .add-overlay {
    width: 100vw;
    height: 100%;
    overflow-y: auto;
  }

  .add-overlay-box {
    overflow-y: scroll;
    top: 50%;
    left: 50%;

    position: fixed;

    width: 100%;
    height: 100%;
  }

  .modal-header {
    padding: 12px 12px 0px !important;
  }

  form {
    width: 93% !important;
    padding: 12px !important;
    margin: 0 auto;
  }
}

.cancel-button,
.next-button,
.submit-button {
  width: 50%;
  height: 56px;
  border-radius: 4px;
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.cancel-button,
.cancel-button:focus,
.cancel-button:active {
  outline: none;
  background: none;
  margin: 8px 8px 0px 0px;
  color: #8c8c8c;
  outline: unset;
  border: 1px solid #8c8c8c;
}

.submit-button {
  background: #25d97f;
  margin: 8px 0px 8px 0px;
  border: 1px solid #25d97f;
  color: #ffffff;
}

.next-button,
.next-button:focus,
.next-button:active {
  outline: none;
  background: #25a3d9;
  margin: 8px 0px 8px 0px;
  border: 1px solid #25a3d9;
  color: #ffffff;
}

.pagin {
  color: white;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #25a3d9;
  background: #25a3d9;
  display: inline;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  padding: 4px;
}

.pagin:disabled {
  background: #8c8c8c;
  border: 1px solid #8c8c8c;
}

.pagins {
  margin-top: 16px;
}
</style>
