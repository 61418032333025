import axios from "axios"
import Model from "./model.js"

export default class Process extends Model {
  constructor(props) {
    super(props)

    this.type = "process"
    this.remotePath = "/api/consigns"
    this.toolConfig = {
      processForm: true,
      multiUpdate: false,
      formStepCount: 2,
    }
  }

  createdMsg() {
    return { action: "processed", field: "items" }
  }

  async fetchData(query) {
    if (this.auth) {
      try {
        let response = await axios({
          method: "get",
          url: `${this.backendUrl}/api/proxy/nt/Patient${query}`,
          json: true,
          headers: {
            authorization: `bearer ${this.auth.accessToken}`,
          },
        })

        return response.data.entry
      } catch (e) {
        console.log(e)
      }
    }
  }

  schema() {
    return {
      patient: {
        firstName: {
          placeholder: "First Name",
          processForm: false,
          type: "text",
          required: true,
        },

        emrid: {
          processForm: true,
          type: "hidden",
          required: false,
        },

        lastName: {
          placeholder: "Last Name",
          processForm: false,
          type: "text",
          required: true,
        },

        name: {
          placeholder: "Name",
          processForm: true,
          type: "text",
          required: true,
        },

        dateOfBirth: {
          label: "Date of Birth",
          processForm: true,
          type: "date",
          placeholder: "MM/DD/YY",
          required: true,
        },

        streetAddress: {
          placeholder: "Patient Street Address",
          processForm: true,
          type: "text",
          required: true,
        },

        city: {
          placeholder: "City",
          processForm: true,
          type: "text",
          required: true,
        },

        state: {
          placeholder: "State",
          formStyle: () => {
            return {
              margin: "0px",
              width: "48%",
              paddingRight: "6px",
              display: "inline-flex",
            }
          },
          processForm: true,
          type: "select",
          options: this.stateOptions(),
          required: true,
        },

        zipCode: {
          placeholder: "Zip Code",
          formStyle: () => {
            return {
              margin: "0px",
              width: "50%",
              display: "inline-flex",
            }
          },
          processForm: true,
          type: "text",
          required: true,
        },

        phoneNumber: {
          placeholder: "Phone Number",
          processForm: true,
          type: "text",
          required: true,
        },

        email: {
          placeholder: "Email",
          processForm: true,
          type: "email",
          required: true,
          pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        },
      },

      surgery: {
        surgeonName: {
          placeholder: "Surgeon (First and Last Name)",
          processForm: true,
          type: "text",
          pattern: /^[A-Z][a-z]*\s[A-Z][a-z]*$/,
          required: true,
        },

        reasonForSurgery: {
          placeholder: "Select Reason",
          processForm: true,
          type: "select",
          required: true,
          options: {
            Augmentation: "augmentation",
            Reconstruction: "reconstruction",
            Replacement: "replacement",
          },
        },

        item0Side: {
          formStyle: () => {
            return {
              margin: "0px",
              width: "48%",
              paddingRight: "6px",
              display: "inline-flex",
            }
          },
          value: "left",
        },

        item1Side: {
          formStyle: () => {
            return {
              margin: "0px",
              width: "48%",
              paddingLeft: "6px",
              display: "inline-flex",
            }
          },
          value: "right",
        },

        item0Date: {
          formStyle: () => {
            return {
              paddingRight: "6px",
              margin: "0px",
              width: "48%",
              display: "inline-flex",
            }
          },
        },

        item1Date: {
          formStyle: () => {
            return {
              paddingLeft: "6px",
              margin: "0px",
              width: "48%",
              display: "inline-flex",
            }
          },
        },

        item0Uid: {},
        item1Uid: {},

        formAuthor: {
          placeholder: "Form Author",
          label: "Form Author",
          processForm: true,
          disabled: true,
          type: "text",
          value: this.getFormAuthor(),
          required: true,
        },
      },
    }
  }

  getProcessForm(selected) {
    let form = this.processFormFields()

    for (let i in selected) {
      form["surgery"][`item${i}Side`] = {
        ...form["surgery"][`item${i}Side`],
        ...{
          label: `SERIAL #${selected[i].serialNumber}`,
          processForm: true,
          type: "select",
          required: true,
          toggleAlt: () => {
            let alt = i == 0 ? `item1Side` : `item0Side`
            return alt
          },
          options: { Left: "left", Right: "right" },
        },
      }

      form["surgery"][`item${i}Date`] = {
        ...form["surgery"][`item${i}Date`],
        ...{
          label: "Date (MM/DD/YY)",
          placeholder: "MM/DD/YY",
          processForm: true,
          type: "date",
          required: true,
        },
      }

      form["surgery"][`item${i}Uid`] = {
        processForm: true,
        type: "hidden",
        required: true,
        value: selected[i].uid,
      }
    }

    console.log(form)

    return form
  }

  getFormAuthor() {
    return this.auth.user.name
  }

  processFormFields() {
    //  let fields = {}
    //  let schema = this.schema()

    //  for(var i in schema) {
    //      if(schema[i].processForm) {
    //          fields[i] = schema[i]
    //      }
    //  }

    //  console.log(fields)

    //  return fields
    return this.schema()
  }

  async submitPatientNote(id, payload) {
    if (this.auth) {
      console.log(id)
      console.log(payload)
      await axios
        .post(
          `${this.backendUrl}/api/proxy/nt/Patient/${id}/Composition`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `bearer ${this.auth.accessToken}`,
              Id: this.auth.user.name,
            },
          }
        )
        .then((resp) => {
          this.msg = this.updatedMsg(resp)
        })
        .catch((e) => {
          this.msg = { action: "error", err: e.response.data.error }
        })
    }
  }

  async processData(data, selected) {
    let form = {
      patient: data.patient,
      items: [],
      reasonForSurgery: data.surgery.reasonForSurgery,
      surgeonFirstName: data.surgery.surgeonName.split(" ")[0],
      surgeonLastName: data.surgery.surgeonName.split(" ")[1],
      formAuthor: data.surgery.formAuthor,
    }

    form.patient.dateOfBirth = new Date(form.patient.dateOfBirth)

    let note = [
      `Surgery Date: ${new Date(
        data.surgery["item0Date"]
      ).toLocaleDateString()}`,
    ]

    for (var i in selected) {
      form.items[i] = {}
      if (data.surgery[`item${i}Uid`]) {
        form.items[i]["sideOfImplantation"] = data.surgery[`item${i}Side`]
        form.items[i]["uid"] = data.surgery[`item${i}Uid`]
        form.items[i]["status"] = "used"
        form.items[i]["dateOfImplantation"] = new Date(
          data.surgery[`item${i}Date`]
        )
      }
      note.push(
        `${form.items[i].sideOfImplantation}: ${selected[i].referenceNumber} ${selected[i].serialNumber}`
      )
      let empty = form.items.indexOf({})
      if (empty !== -1) {
        array.splice(empty, 1)
      }
    }

    note.push(`Surgeon: ${form.surgeonLastName}`)
    await super.postData(form)

    if (this.msg.action == "processed" && form.patient.emrid) {
      let payload = {
        "resourceType": "Composition",
        "extension": [
          {
            "url": "http://hl7.org/fhir/StructureDefinition/flag-priority",
            "valueCodeableConcept": {
              "coding": [
                {
                  "system": "http://hl7.org/fhir/flag-priority-code",
                  "code": "PL",
                },
              ],
            },
          },
        ],
        "date": new Date(),
        "author": [
          {
            "display": form.formAuthor,
          },
        ],
        "section": [
          {
            "text": {
              "div": note.join("\n"),
            },
          },
        ],
      }

      await this.submitPatientNote(form.patient.emrid, payload)
    }
  }
}
