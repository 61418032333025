<template>
  <div class="nav-container">
    <Menu
      :categories="categories"
      :showMenu="showMenu"
      @onToggle="toggleShowMenu"
    />

    <nav class="navbar">
      <div class="navbar-container">
        <div class="navbar-brand">
          <router-link to="/" class="nav-link">
            <span v-html="logo()"></span>
          </router-link>
        </div>

        <div class="navbar-route">
          {{ $route.name }}
        </div>

        <div class="navbar-menu">
          <span
            v-if="$route.name == 'dashboard'"
            @click="showUserDropdown = !showUserDropdown"
            v-html="profileIcon()"
          >
          </span>

          <span
            v-if="$route.name !== 'dashboard'"
            @click="toggleShowMenu"
            v-html="menuIcon()"
          >
          </span>

          <transition name="fade">
            <div
              v-if="$route.name == 'dashboard'"
              v-show="showUserDropdown"
              class="user-dropdown"
            >
              <!--<div class="router-wrapper"
                            @click="showUserDropdown = !showUserDropdown">
                            <router-link
                                @click="showUserDropdown = !showUserDropdown"
                                to="/profile"
                                class="nav-link">
                                Edit Profile
                            </router-link>
                        </div>-->

              <div
                class="router-wrapper"
                v-if="$auth.appMetadata.role == 'admin'"
                @click="showUserDropdown = !showUserDropdown"
              >
                <router-link
                  @click="showUserDropdown = !showUserDropdown"
                  to="/admin"
                  class="nav-link"
                >
                  Admin
                </router-link>
              </div>

              <a @click.prevent="logout" class="nav-link-last"> Logout </a>
            </div>
          </transition>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import MainNav from "@/components/MainNav"
import Menu from "@/components/Menu"
import axios from "axios"

import { logo, profileIcon, menuIcon } from "@/utils/svg"
import config from "../../config.json"

const backendUrl = config.backend_url[process.env.ENV]

export default {
  name: "NavBar",
  components: { MainNav, Menu },
  data() {
    return {
      showUserDropdown: false,
      showMenu: false,
      categories: {
        //'Dashboard': {},
        "Inventory": {
          subcategories: {
            "Implants": { count: 0 },
            //'Fillers': { count: 0 },
            //'Toxins': { count: 0 },
            //'Skincare': { count: 0 },
          },
        },
        //'Bookkeeping': {
        //    subcategories: {
        //        'Transactions': { count: 0 },
        //        'Invoices': { count: 0 },
        //        'Expenses': { count: 0 },
        //        'Reports': { count: 0 },
        //    },
        //},
        //'Sales': {
        //    subcategories: {
        //        'Revenue': { count: 0 },
        //        'Analytics': { count: 0 },
        //        'Products': { count: 0 },
        //    },
        //},
      },
    }
  },

  async mounted() {
    console.log(this.$router.options.routes)
    const accessToken = await this.$auth.getTokenSilently()

    for (var v in this.categories) {
      if (this.categories[v].subcategories) {
        for (var s in this.categories[v].subcategories) {
          await axios
            .get(`${backendUrl}/api/items?count=true&type=${s.toLowerCase()}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((resp) => {
              this.categories[v].subcategories[s].count = resp.data.data
            })
            .catch((e) => {
              console.log(e)
            })
        }
      }
    }
  },

  methods: {
    logo: logo,
    profileIcon: profileIcon,
    menuIcon: menuIcon,

    toggleShowMenu: function () {
      this.showMenu = !this.showMenu
      this.$emit("input", this.showMenu)
    },

    logout() {
      this.$auth.logout()
      this.$router.push({ path: "/login" })
    },
  },
}
</script>

<style>
.router-wrapper {
  display: contents;
}

.user-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin: 0;

  background: #ffffff;
  /* Primary / Gray */

  border: 1px solid #54575a;
  box-sizing: border-box;
  border-radius: 4px;

  position: absolute;
  right: 0;
  width: 114px;
}

.user-dropdown a {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  padding-bottom: 16px;

  /* Primary / Gray */

  color: #54575a;
}

.navbar-container {
  display: inline-flex;
  width: 100%;
}

.navbar {
  max-height: inherit;
  background: #1f1f1f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  height: 72px;
  left: 0px;
  top: 0px;
  color: #ffffff;
  text-transform: uppercase;
}

.nav-container {
  width: 100%;
  background: #1f1f1f;
  height: 72px;
  color: #ffffff;
  position: fixed;
  z-index: 2;
  text-transform: uppercase;
}

.navbar-brand {
  flex: none;
  order: 0;
  flex-grow: 1;
  text-align: left;
}

.navbar-route {
  line-height: 38px;
  height: 38px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 1;
  text-align: center;

  font-family: "brandon-grotesque", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.24em;
  text-align: center;
}

.navbar-menu {
  line-height: 38px;
  height: 38px;
  flex: none;
  order: 2;
  flex-grow: 1;
  text-align: right;
}

.nav-link {
  cursor: pointer;
}

a.nav-link-last {
  padding-bottom: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
